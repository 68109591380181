import React, {useEffect, useState} from 'react';
import s from './PaginationControl.module.css'
import {faAnglesRight,faAnglesLeft, faAngleLeft,faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const   PaginationControl = (props) => {
    const [linePos,setLinePos] = useState(0)
    useEffect(() => {
        const currPageGroup = (~~((props.currentPage) / 4))
        setLinePos(-152 * currPageGroup)
    },[props.currentPage])

    const rightHandler = () => {
        if(!props.disabled){
            if(props.list.length !== props.currentPage + 1){
                props.changePage('go')
            }
        }
    }
    const leftHandler = () => {
        if(!props.disabled){
            if(props.currentPage > 0){
                props.changePage('back')
            }
        }
    }
    const clickHandler = (id) => {
        props.changePage(id)
    }
    const nextGroup = () => {
        const neededPage = (4-props.currentPage%4)
        if(props.list.length > props.currentPage + neededPage){
            props.changePage(props.currentPage + neededPage)
        }
    }
    return (
        <>
            {props.list.length > 1
                &&
                <div className={s.control}>
                    <div className={s.control__button} onClick={() => props.changePage(0)} >
                        <FontAwesomeIcon icon={faAnglesLeft} size={'x'} color={'#c4c8cc'} />
                    </div>
                    <div className={s.control__button} onClick={leftHandler} >
                        <FontAwesomeIcon icon={faAngleLeft} size={'x'} color={'#c4c8cc'} />
                    </div>
                    <div className={s.control__window}>
                        <div className={s.control__elems} style={{transform:`translateX(${linePos}px)`}}>
                            {props.list.map((elem,id) =>
                                <div key={id} className={props.currentPage === id ? s.control__elemActive : s.control__elem} onClick={() => clickHandler(id)}>
                                    {id+1}
                                </div>
                            )}

                        </div>

                    </div>
                    {props.list.length > props.currentPage+4
                        &&
                        <div style={{width:35,height:35, fontSize:22}} className={s.control__button} onClick={() => nextGroup()}>
                            ...
                        </div>
                    }

                    <div className={s.control__button} onClick={rightHandler}>
                        <FontAwesomeIcon icon={faAngleRight} size={'x'} color={'#c4c8cc'}/>
                    </div>
                    <div className={s.control__button} onClick={() => props.changePage(props.list.length-1)}>
                        <FontAwesomeIcon icon={faAnglesRight} size={'x'} color={'#c4c8cc'}/>
                    </div>
                </div>
            }
        </>

    );
};

export default PaginationControl;