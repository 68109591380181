import React from 'react';
import s from './VisitorsReportsBanner.module.scss'
import {useTranslation} from "react-i18next/hooks";
const VisitorsReportsBanner = () => {
    const [t,i18n] = useTranslation()
    return (
        <div className={s.banner}>
            {t('visitorsReport.dataNotFoundBanner')}
        </div>
    );
};

export default VisitorsReportsBanner;
