import {mainApi} from "../testAPI";
import axios from "axios";
import arraySlicer from "../../../utils/arraySlicer";

const facesSearchApi = mainApi.injectEndpoints({
    endpoints:(build) => ({
        getSearches:build.query({
            queryFn: async () => {
                try{
                    const res = await axios.get(`/api/person_search`, {
                        headers: {
                            "Authorization": localStorage.getItem('token')
                        }
                    })
                    const data = await res.data.data.searches
                    return {data:data}
                }catch (e){
                    return {error:e.response.status}
                }
            },
            providesTags: () => [{type:'facesSearches'}],
        }),
        getOneSearch:build.query({
            queryFn: async ({id}) => {
                try{
                    if(id !== -1){
                        const res = await axios.get(`/api/person_search/${id}`, {
                            headers: {
                                "Authorization": localStorage.getItem('token')
                            }
                        })
                        const data = await res.data.data.search
                        return {data:data}
                    }


                }catch (e){
                    return {error:e}
                }
            },
            providesTags: (result, error, arg) => [{type:'facesSearchess'}],
        }),
        postSearch: build.mutation({
            queryFn:async ({location,ftype,photo}) => {
                try{
                    const res = await axios.post(`/api/person_search`, {
                            location:location,
                            ftype:ftype,
                            b64file:photo,
                        },
                        {
                            headers:{
                                "Authorization": localStorage.getItem('token')
                            }
                        })
                    const data = await res.data.data.searches
                    return {data:data}
                }catch (err){
                    return {error:err.response.status}
                }
            },
            invalidatesTags: () => [{type: 'facesSearches'}]
        }),

    })
})
export const { useGetSearchesQuery, usePostSearchMutation, useGetOneSearchQuery} = facesSearchApi