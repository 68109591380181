import React, {useEffect, useState} from 'react';
import s from './FacesSearchTable.module.scss'
import {useSelector} from "react-redux";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import Modal from "../../../UI/Modal/Modal";
import FacesSearchPersonPopup from "../FacesSearchPersonPopup/FacesSearchPersonPopup";
import FacesSearchAllVisits from "../FacesSearchAllVisits/FacesSearchAllVisits";
import {PROXY} from "../../../../utils/pathss";
import {useGetOneSearchQuery} from "../../../../Redux/APIs/otherAPI/facesSearchApi";
import {useTranslation} from "react-i18next/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareUpRight} from "@fortawesome/free-solid-svg-icons";
const FacesSearchTable = ({searchHistory,processedId,injectFoundedSearch}) => {
    const search = useGetOneSearchQuery({id:processedId},{skip:!processedId,pollingInterval:10000})
    useEffect(() => {
        if(processedId && search.data?.status !== 'processing'){
            injectFoundedSearch({list:search.data,id:processedId})
        }
    },[search])

    const [showPersonModal,setShowPersonModal] = useState(false)
    const [showPersonAllVisits, setShowPersonAllVisits] = useState(false)
    const [person,setPerson] = useState('')
    const [personId,setPersonId] = useState(null)
    const currentPage = useSelector(state => state.facesSearch.currentPage)
    const openPersonModal = (id) => {
        if(id){
            setPersonId(id)
            setShowPersonModal(!showPersonModal)
        }
    }
    const [t,i18n] = useTranslation()
    const getStatusName = (status) => {
        switch (status){
            case 'found':
                return t('search.table.found')
            case 'notfound':
                return t('search.table.notFound')
            case 'processing':
                return t('search.table.processing')
        }
    }
    return (
        <>
            <table className={s.table}>
                    <thead>
                    <tr className={s.table__rowTitle}>
                        <th className={s.table__cellTitle}>
                            {t('search.table.photo')}
                        </th>
                        <th className={s.table__cellTitle}>
                            {t('search.table.status')}
                        </th>
                        <th className={s.table__cellTitle}>
                            {t('search.table.created')}
                        </th>

                        <th className={s.table__cellTitle}>
                            {t('search.table.donned')}
                        </th>
                        <th className={s.table__cellTitle}>
                            {t('search.table.person')}
                        </th>
                        <th className={s.table__cellTitle}>
                            {t('search.table.visits')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {searchHistory[currentPage]?.map((elem,id) =>
                        <tr className={s.table__row}>
                            <td align={"center"} className={s.table__cell}>
                                <img src={`${PROXY}api/${elem?.thumbnail}`} alt=""/>
                            </td>
                            <td className={s.table__cellStatus} style={{color:elem.status === 'notfound' ? 'red' : elem.status === 'found' ? 'green' : 'blue'}}>
                                <div className={s.table__mobileDescr}>
                                    {t('search.table.status')}:
                                </div>
                                {getStatusName(elem.status)}
                            </td>
                            <td className={s.table__cell}>
                                <div className={s.table__mobileDescr}>
                                    {t('search.table.created')}:
                                </div>
                                <div className={s.table__time}>
                                    <div>{getTimeAndDate('date',elem.dt)}</div>
                                    <div>{getTimeAndDate('time',elem.dt)}</div>
                                </div>
                            </td>
                            <td className={s.table__cell}>
                                <div className={s.table__mobileDescr}>
                                    {t('search.table.donned')}:
                                </div>
                                {elem.dt2 !== elem.dt
                                    &&
                                    <div className={s.table__time}>
                                        <div>{getTimeAndDate('date',elem.dt2)}</div>
                                        <div>{getTimeAndDate('time',elem.dt2)}</div>
                                    </div>
                                }
                            </td>
                            <td className={elem.status === 'found' ? s.table__cellVisits : s.table__cell} onClick={() => openPersonModal(elem.person)} >
                                <div className={s.table__mobileDescr}>
                                    {t('search.table.person')}
                                </div>
                                {elem.status === 'found'
                                    &&
                                    <>
                                        <button className={s.table__visitsButton}>{t('search.table.open')}</button>
                                        <FontAwesomeIcon className={s.table__visitsButtonMobile} icon={faSquareUpRight}/>
                                    </>
                                }

                            </td>
                            {elem.status === 'found'
                                ?
                                <td className={s.table__cellVisits} onClick={() => {
                                    setPerson(elem.person)
                                    setShowPersonAllVisits(!showPersonAllVisits)
                                }}>
                                    <div className={s.table__mobileDescr}>
                                        {t('search.table.visits')}
                                    </div>
                                    <button className={s.table__visitsButton}>
                                        {t('search.table.view')}
                                    </button>
                                    <FontAwesomeIcon className={s.table__visitsButtonMobile} icon={faSquareUpRight}/>
                                </td>
                                :
                                <td className={s.table__cell}></td>
                            }

                        </tr>
                    )

                    }

                    </tbody>
                </table>
            {showPersonModal
                &&
                <Modal setShowModal={setShowPersonModal} showModal={showPersonModal}>
                    <FacesSearchPersonPopup id={personId}/>
                </Modal>

            }
            {showPersonAllVisits
                &&
                <Modal setShowModal={setShowPersonAllVisits} showModal={showPersonAllVisits}>
                    <FacesSearchAllVisits person={person}/>
                </Modal>
            }
        </>

    );
};

export default FacesSearchTable;