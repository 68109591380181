import {mainApi} from "../testAPI";
import axios from "axios";
import arraySlicer from "../../../utils/arraySlicer";
import {current} from "@reduxjs/toolkit";

export const personApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getPersonsGroup: builder.query({
            queryFn: async ({opt}) => {
                try {
                    const res = await axios.get(`/api/person_group`,{
                        headers:{
                            "Authorization": localStorage.getItem('token')
                        }
                    })
                    const data = await res.data.data.groups
                    if(opt === 'slice'){
                        return {data:arraySlicer(data,10)}
                    }else if(!opt){
                        return {data:data}
                    }
                    return {data:data}
                }catch (e){
                    return {error:e.response.status}
                }
            },
            providesTags: () => [{type:'personGroups'}],
        }),
        postPersonsGroup: builder.mutation({
            queryFn:async ({name,color}) => {
                try{
                    const res = await axios.post('/api/person_group',
                        {
                            name: name,
                            color: color
                        },
                        {
                            headers:{
                                "Authorization": localStorage.getItem('token')
                            }
                        })
                    const data = await res.data.data
                    return {data: data}
                }catch (err){
                    return {error:err.response.status}
                }
            },
            invalidatesTags: () => [{type: 'personGroups'}]
        }),
        getPersons: builder.query({
            query:({path,page,freq}) => ({
                url:`/api/person_group/${path}/persons?pagesize=50&page=${page}&only_frequent=${freq}`,
                headers: {
                    "Authorization":localStorage.getItem('token')
                }
        }),
            transformResponse:(response,meta,arg) => {
                if(arg.freq){
                    return {
                        type:response.data.group.type,
                        persons:response.data.group.persons,
                        pages:response.data.group.persons_totalpages_frequent,
                        lastPath:arg.path,
                        totalNum:response.data.group.persons_totalnum,
                        totalNumFreq:response.data.group.persons_totalnum_freq
                    }
                }else if(!arg.freq){
                    return {type:response.data.group.type,
                        persons:response.data.group.persons,
                        pages:response.data.group.persons_totalpages,
                        lastPath:arg.path,
                        totalNum:response.data.group.persons_totalnum,
                        totalNumFreq:response.data.group.persons_totalnum_freq
                    }
                }
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // merge (currentCacheData,newItems)  {
            //     if(newItems.lastPath !== currentCacheData.lastPath){
            //         return {persons: newItems.persons, pages:newItems.pages,last:newItems.last,lastPath:newItems.lastPath}
            //     }
            //     if(currentCacheData.last === newItems.last){
            //         return  {persons:[...currentCacheData.persons,newItems.persons[newItems.persons.length-1]], pages:newItems.pages,last:newItems.last,lastPath:newItems.lastPath}
            //     }
            //
            //     return {persons: [...currentCacheData.persons, ...newItems.persons], pages:newItems.pages,last:newItems.last,lastPath:newItems.lastPath}
            // },
            forceRefetch({ currentArg, previousArg })    {
                if(previousArg){
                    if(currentArg.path !== previousArg.path || currentArg.freq !== previousArg.freq){
                        return true
                    }
                    return currentArg.page !== previousArg.page
                }else if(!previousArg){
                    return true
                }


            },
            providesTags: () => [{type:'persons'}],

        }),
        postPersons:builder.mutation({
            query:(state) => ({
                url:'/api/person',
                headers: {
                    "Authorization":localStorage.getItem('token')
                },
                method: 'POST',
                body:{
                    description: state.description,
                    type: state.type,
                    group: state.group,
                    fio: state.personData.fio,
                    male: state.personData.male,
                    email: state.personData.email,
                    phone: state.personData.phone,
                    fid: state.fid
                }
            }),
            transformResponse:(response,meta,arg) => {
                return {data:response.data}

            },
            invalidatesTags: () => [{type: 'persons',isP:true}]
        }),
        getPersonsVisits: builder.query({
            queryFn: async ({id}) => {
                try {
                    const res = await axios.get(`/api/person/${id}/visits`, {
                        headers:{
                            "Authorization": localStorage.getItem('token')
                        }
                    })
                    const data = await res.data.data.visits
                    return {data:data}
                }catch (e){
                    return {error:e.response.status}
                }
            },
            providesTags: () => [{type:'personsVisits'}],
        }),
    }),
})
export const { useGetPersonsGroupQuery, usePostPersonsGroupMutation, useGetPersonsQuery, useGetPersonsVisitsQuery, usePostPersonsMutation} = personApi
