const ageChartRefactor = (list) => {
    if(list.length === 0){
        return []
    }
    const latestHist = list[0].history
    const genderObj = {}
    for(let i in latestHist){
        genderObj[latestHist[i].date] = {'0-18':0,'19-30':0,'31-45':0,'46-60':0,'60+':0}
    }
    for(let i in list){
        const listElemHistory = list[i].history
        const age = list[i].personAge
        for(let j in listElemHistory){
            const personIs = listElemHistory[j].history.length > 0 ? 1 : 0
            const middleAge = Math.ceil((+age.split('-')[0] + +age.split('-')[1])/2)
            if(middleAge < 18){
                genderObj[listElemHistory[j].date]['0-18'] += personIs
            }else if(middleAge >= 18 && middleAge <= 30){
                genderObj[listElemHistory[j].date]['19-30'] += personIs
            }
            else if(middleAge > 30 && middleAge <= 45){
                genderObj[listElemHistory[j].date]['31-45'] += personIs
            }
            else if(middleAge > 45 && middleAge <= 60){
                genderObj[listElemHistory[j].date]['46-60'] += personIs
            }else{
                genderObj[listElemHistory[j].date]['60+'] += personIs
            }
        }
    }
    const genderList = []
    for(let i in genderObj){
        const dayObj = {...genderObj[i], date:i}
        genderList.push(dayObj)
    }
    return genderList
}
export default ageChartRefactor;
