import React, {useEffect} from 'react';
import s from './History.module.css'
import Toolbar from "../../../components/toolbar/Toolbar";
import HistoryFaces from "../../../components/Faces/History/HistoryFaces/HistoryFaces";
import {useDispatch, useSelector} from "react-redux";
import {fetchHistory, setCurrentCameraName} from "../../../Redux/Slices/facesHistorySlice";
import HistoryToolbar from "../../../components/Faces/History/HistoryToolbar/HistoryToolbar";
import {logout} from "../../../Redux/Slices/authSlice";
import Loader from "../../../components/UI/Loader/Loader";
import CamsNotFound from "../../../components/Cams/CamsNotFound/CamsNotFound";

const History = () => {
    const isLoading = useSelector(state => state.facesHistory.isLoading)
    const historyDate = useSelector(state => state.facesHistory.historyDate)
    const historyFilterGroup = useSelector(state => state.facesHistory.filterGroup)
    const currentUser = useSelector(state => state.auth.currentUser)
    const isHaveCameras = useSelector(state => state.auth.isHaveCameras)
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const errorCode = useSelector(state => state.facesHistory.errorCode)
    const dispatch = useDispatch()
    useEffect(() => {
        let cam = currentCamera !== '' ? currentCamera : (currentUser.cameras.length !== 0 && currentUser.cameras[0].id)
        if(isHaveCameras){
            dispatch(fetchHistory({date:historyDate,currentCamera:cam}))
            dispatch(setCurrentCameraName(currentUser?.cameras?.filter(elem => elem.id === cam)[0].name))
        }
    },[historyDate,currentCamera])
    if(errorCode === 401){
        dispatch(logout())
    }
    return (
        <div className={s.historybox}>
            <Toolbar title={'История'}>
                <HistoryToolbar/>
            </Toolbar>
            {isLoading === false
                ?
                isHaveCameras
                    ?
                    <HistoryFaces/>
                    :
                    <CamsNotFound/>
                :
                <Loader/>
            }

        </div>
    );
};

export default History;
