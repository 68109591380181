import React, {useState} from 'react';
import s from './Login.module.css'
import AuthForm from "../../components/Login/AuthForm/AuthForm";
const Login = () => {
    return (
        <div className={s.login}>
            <div className={s.bgbox}>
                <div className={s.bg1}>
                </div>
                <div className={s.bg2}>
                </div>
                <div className={s.bg3}>
                </div>
            </div>
            <AuthForm/>
        </div>
    );
};

export default Login;