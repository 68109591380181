import React, {useEffect, useState} from 'react';
import s from './MonthTablePopup.module.scss'
import getMonthName from "../../../../utils/getMonthName";
const MonthTablePopup = ({cell,month}) => {
    return (
        <div className={s.popup}>
            <div className={s.popup__title}>
                Посещения за {getMonthName(month)}
            </div>
            <table className={s.calend}>
                <tr className={s.calend__row}>
                    <th className={s.calend__cellTitle}>
                        Пн
                    </th>
                    <th className={s.calend__cellTitle}>
                        Вт
                    </th>
                    <th className={s.calend__cellTitle}>
                        Ср
                    </th>
                    <th className={s.calend__cellTitle}>
                        Чт
                    </th>
                    <th className={s.calend__cellTitle}>
                        Пт
                    </th>
                    <th className={s.calend__cellTitle}>
                        Сб
                    </th>
                    <th className={s.calend__cellTitle}>
                        Вс
                    </th>
                </tr>
                {cell?.length !== 0
                    &&
                    cell.map((elem,id) =>
                        <tr className={s.calend__row}>
                            {elem.map((day,id) =>
                                <td className={day.actions === 0 ? s.calend__cell : s.calend__cellAct}>
                                    {day.dat}
                                </td>
                            )}
                        </tr>
                    )
                }
            </table>
        </div>
    );
};

export default MonthTablePopup;