import React, {useEffect} from 'react';
import s from './ReportsVisitors.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    fetchVisitors,
    fetchVisitorsMoreDay, setCurrentLocation,
} from "../../../Redux/Slices/VisitorsSlice";
import Toolbar from "../../../components/toolbar/Toolbar";
import VisitorsReportsToolbar from "../../../components/VisitorsReports/VisitorsReportsToolbar";
import {logout} from "../../../Redux/Slices/authSlice";
import ReportsVisitorsContent from "../../../components/VisitorsReports/ReportsVisitorsContent/ReportsVisitorsContent";
import CamsNotFound from "../../../components/Cams/CamsNotFound/CamsNotFound";
const ReportsVisitors = () => {
    const dispatch = useDispatch()
    const reportDateFirst = useSelector(state => state.visitorsReport.reportDateFirst)
    const errorCode = useSelector(state => state.visitorsReport.errorCode)
    const reportDateSecond = useSelector(state => state.visitorsReport.reportDateSecond)
    const currentLocation = useSelector(state => state.visitorsReport.currentLocation)
    const isHaveCameras = useSelector(state => state.auth.isHaveCameras)
    const locations = useSelector(state => state.location.locations)

    useEffect(() => {
        const timeDiff = Math.abs(new Date(reportDateFirst).getTime() - new Date(reportDateSecond).getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const location = currentLocation !== '' ? currentLocation : (locations.length !== 0 ? locations[0].id : '')
        if(location){
            if(diffDays === 1){
                dispatch(fetchVisitors({firstDate: reportDateFirst,secondDate:reportDateSecond,currentLocation:location}))
            } else if(diffDays >= 1){
                dispatch(fetchVisitorsMoreDay({firstDate:reportDateFirst,secondDate:reportDateSecond, currentLocation:location}))
            }
        }
    },[reportDateSecond,reportDateFirst,currentLocation])

    useEffect(() => {
        const location = currentLocation !== '' ? currentLocation : (locations.length !== 0 ? locations[0].id : '')
        if(location){
            dispatch(setCurrentLocation(location))
        }
    },[locations])
    //logout if unathorized
    if(errorCode === 401){

        dispatch(logout())
    }
    return (
        <div className={s.visitorsbox}>
            <Toolbar title={`Посетители`}>
                <VisitorsReportsToolbar/>
            </Toolbar>
            {isHaveCameras
                ?
                <ReportsVisitorsContent/>
                :
                <CamsNotFound/>
            }
        </div>
    );
};

export default ReportsVisitors;
