const genderChartRefactor = (list) => {
    if(list.length === 0){
        return []
    }
    const latestHist = list[0].history
    const genderObj = {}
    for(let i in latestHist){
        genderObj[latestHist[i].date] = {Male:0, Female:0}
    }
    for(let i in list){
        const listElemHistory = list[i].history
       const gender = list[i].personGender
        for(let j in listElemHistory){
            if(gender === 'Male'){
                genderObj[listElemHistory[j].date].Male += listElemHistory[j].history.length > 0 ? 1 : 0
            }else if(gender === 'Female'){
                genderObj[listElemHistory[j].date].Female += listElemHistory[j].history.length > 0 ? 1 : 0
            }

        }
    }
    const genderList = []
    for(let i in genderObj){
        const dayObj = {...genderObj[i], date:i}
        genderList.push(dayObj)
    }

    return genderList
}
export default genderChartRefactor;
