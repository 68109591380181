import React, {useEffect} from 'react';
import s from './HistoryAdminPersonInfo.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {fetchOneFace} from "../../../../Redux/Slices/HistoryOneFaceSlice";
import {PROXY} from "../../../../utils/pathss";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next/hooks";
const HistoryAdminPersonInfo = () => {
    const dispatch = useDispatch()
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const currentUser = useSelector(state => state.auth.currentUser)
    const oneFace = useSelector(state => state.historyOneFace.oneFace)
    const isLoading = useSelector(state => state.historyOneFace.isLoading)
    const currentRid = useSelector(state => state.historyOneFace.currentRid)
    const currentPersonId = useSelector(state => state.historyOneFace.currentPersonId)

    useEffect(() => {
        if(currentRid != null){
            let cam = currentCamera !== '' ? currentCamera : currentUser.cameras[0].id
            dispatch(fetchOneFace({currentCamera:cam, rid:currentRid,personId:currentPersonId}))
        }
    },[currentRid])
    const [t,i18n] = useTranslation()
    return (
        <div className={s.personinfo}>
            {
                !oneFace
                &&
                <div className={s.fullcard__banner}>
                    {t('admin.selectStory')}
                </div>
            }
            {!isLoading
                &&
                oneFace
                &&
                <div className={s.fullcard}>
                    <div className={s.fullcard__imgs}>
                        <>
                            <div className={s.fullcard__img}>
                                <span>imgdata.path</span>
                                <img src={`${PROXY}api/${oneFace?.imgdata?.path}`} alt=""/>
                            </div>
                            <div className={s.fullcard__img}>
                                <span>imgdata.thumbnail</span>
                                <a target={'_blank'} href={`${PROXY}api/${oneFace?.imgdata?.thumbnail}`}>thumbnail img</a>
                            </div>
                        </>
                    </div>
                    <div className={s.fullcard__info}>
                    <pre>
                        {JSON.stringify(oneFace, null, 2)}
                    </pre>
                    </div>
                </div>
            }
        </div>
    );
};

export default HistoryAdminPersonInfo;