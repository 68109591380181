import React, {useState} from 'react';
import s from "../../CamStepsModal/camStep.module.css";
import GreenButton from "../../../../UI/GreenButton/GreenButton";
import JustInput from "../../../../UI/JustInput/JustInput";
import {useTranslation} from "react-i18next/hooks";

const HikcentralStepTimezone = (props) => {
    const [zone,setZone] = useState('')
    const goToNextStep = () => {
        if( !props.last && zone){
            props.setStep(props.step+1)
            props.setTimezone(zone)
        }
    }
    const timezones = [
        {utc:6, title:'Asia/Almaty'},
        {utc:5, title:'Asia/Aqtobe'},
        {utc:5, title:'Asia/Aktau'},
    ]
    const [t,i18n] = useTranslation()
    return (
        <div>
            <div className={s.camstepWrapper}>
                <div className={s.camstep__boldText}>
                    {props.text}
                </div>
                <div className={s.camstep__lightText}>
                    {props.subtext}
                </div>
                <select className={s.locations} onChange={(e) => setZone(e.target.value)}>
                    <option value="" defaultChecked={true}></option>
                    {
                        timezones.map((elem,id) =>
                            <option value={elem.title}>
                                {elem.title} ({elem.utc>0 ? '+' : '-'}{elem.utc})
                            </option>
                        )
                    }
                </select>
                <div>
                    <GreenButton onClick={() => goToNextStep()} >
                        {t('default.continue')}
                    </GreenButton>
                </div>

            </div>
        </div>
    );
};

export default HikcentralStepTimezone;