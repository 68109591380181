import {mainApi} from "../testAPI";
import axios from "axios";

const camerasApi = mainApi.injectEndpoints({
    endpoints:(build) => ({
        getCameras:build.query({
            queryFn: async () => {
                try{
                    const res = await axios.get('/api/camera',{
                        headers:{
                            "Authorization": localStorage.getItem('token')
                        }
                    })
                    const data = await res.data.data.cameras
                    return {data:data}
                }catch (e){
                    return {error:e.response.status}
                }
            },
            providesTags: () => [{type:'Cameras'}],
        }),
        postCamera: build.mutation({
            queryFn:async ({name,location,view,timezone,camId}) => {
                try{
                    const res = await axios.post('/api/camera',
                        {
                            name: name,
                            location: location,
                            type: 2,
                            view: view,
                            timezone:timezone,
                            cdata:{
                                hik_id: camId
                            }
                        },
                        {
                            headers:{
                                "Authorization": localStorage.getItem('token')
                            }
                        })
                    const data = await res.data.data
                    return {data: data}
                }catch (err){
                    return {error:err.response.status}
                }
            },
            invalidatesTags: () => [{type: 'Cameras'}]
        }),

    })
})
export const { useGetCamerasQuery, usePostCameraMutation} = camerasApi