import React, {useEffect} from 'react';
import s from './FacesSearchContent.module.scss'
import {useDispatch, useSelector} from "react-redux";
import PaginationControl from "../../../UI/PaginationControl/PaginationControl";
import FacesSearchTable from "../FacesSearchTable/FacesSearchTable";
import {
    goPagination,
    backPagination,
    setPagination,
    refactorData,
    appendData
} from "../../../../Redux/Slices/facesSearchSlice";
import {useGetSearchesQuery} from "../../../../Redux/APIs/otherAPI/facesSearchApi";
import Loader from "../../../UI/Loader/Loader";
import FacesSearchNotFound from "../FacesSearchNotFound/FacesSearchNotFound";

const   FacesSearchContent = () => {
    const refactorSearchHistory = useSelector(state => state.facesSearch.history)
    const processedId = useSelector(state => state.facesSearch.processedId)
    const {data:searchHistory,isLoading} = useGetSearchesQuery()
    const currentPage = useSelector(state => state.facesSearch.currentPage)
    const dispatch = useDispatch()
    const changePage = (opt) => {
        if(opt === 'go'){
            dispatch(goPagination())
        }else if (opt === 'back'){
            dispatch(backPagination())
        }else if (typeof opt === "number"){
            dispatch(setPagination(opt))
        }
    }
    const injectFoundedSearch = (data) => {
        dispatch(appendData({search:data.list,id:data.id}))
    }

    useEffect(() => {
        if(searchHistory){
            dispatch(refactorData(searchHistory))
        }
    },[searchHistory])
    return (
        <div className={s.facesSearchContentbox}>
            {!isLoading
                ?
                refactorSearchHistory?.length > 0
                        ?
                        <div className={s.facesSearchContentbox__table}>
                            <FacesSearchTable injectFoundedSearch={injectFoundedSearch} processedId={processedId} searchHistory={refactorSearchHistory}/>
                            <div className={s.facesSearchContentbox__pagination}>
                                <PaginationControl list={refactorSearchHistory} currentPage={currentPage} changePage={changePage} />
                            </div>


                        </div>
                        :
                        <FacesSearchNotFound/>

                :
                    <Loader/>
            }

        </div>
    );
};

export default FacesSearchContent;