import React, {useState} from 'react';
import s from "../../CamStepsModal/camStep.module.css";
import JustInput from "../../../../UI/JustInput/JustInput";
import GreenButton from "../../../../UI/GreenButton/GreenButton";
import {useTranslation} from "react-i18next/hooks";

const HikcentralStepName = (props) => {
    const [name,setName] = useState('')

    const goToNextStep = () => {
        if( !props.last && name){
            props.setStep(props.step+1)
            props.setName(name)
        }
    }
    const [t,i18n] = useTranslation()
    return (
        <div>
            <div className={s.camstepWrapper}>
                <div className={s.camstep__boldText}>
                    {props.text}
                </div>
                <div className={s.camstep__lightText}>
                    {props.subtext}
                </div>

                <JustInput value={name} onChange={(e) => setName(e.target.value)} />
                <div>
                    <GreenButton onClick={() => goToNextStep()} >
                        {t('default.continue')}
                    </GreenButton>
                </div>

            </div>
        </div>
    );
};

export default HikcentralStepName;