import React from 'react';
import s from './Reports.module.css'
import {faChartArea, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {REPORT_PERSON_ROUTE, REPORT_VISITORS_ROUTE, REPOST_VISITS_HISTORY_ROUTE} from "../../utils/pathss";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {useTranslation} from "react-i18next/hooks";
import {useSelector} from "react-redux";

const Reports = () => {
    const [t,i18n] = useTranslation()
    const currentUser = useSelector(state => state.auth.currentUser)
    console.log(currentUser)
    return (
        <div className={s.reportsbox}>
            <div className={s.reports}>
                {currentUser?.org?.func?.regular_customers_report
                    &&
                    <NavLink to={REPOST_VISITS_HISTORY_ROUTE} className={s.reports__navlink}>
                        <div className={s.report}>
                            <div className={s.report__icon}>
                                <FontAwesomeIcon icon={faUserGroup} size={"3x"} />
                            </div>
                            <div className={s.report__inform}>
                                <div className={s.report__title}>
                                    {t('reports.visitsHistory')}
                                </div>
                                <div className={s.report__text}>
                                    {t('reports.workingHoursDef')}
                                </div>
                            </div>

                        </div>
                    </NavLink>
                }
                {currentUser?.org?.func?.visitors_report
                    &&
                    <NavLink to={REPORT_VISITORS_ROUTE} className={s.reports__navlink}>
                        <div className={s.report}>
                            <div className={s.report__icon}>
                                <FontAwesomeIcon icon={faChartArea} size={"3x"} />
                            </div>
                            <div className={s.report__inform}>
                                <div className={s.report__title}>
                                    {t('reports.visitors')}
                                </div>
                                <div className={s.report__text}>
                                    {t('reports.visitorsDef')}
                                </div>
                            </div>

                        </div>
                    </NavLink>
                }
                {currentUser?.org?.func?.worktime_report
                    &&
                    <NavLink to={REPORT_PERSON_ROUTE} className={s.reports__navlink}>
                        <div className={s.report}>
                            <div className={s.report__icon}>
                                <FontAwesomeIcon icon={faClock} size={"3x"} />
                            </div>
                            <div className={s.report__inform}>
                                <div className={s.report__title}>
                                    {t('reports.workingHours')}
                                </div>
                                <div className={s.report__text}>
                                    {t('reports.workingHoursDef')}
                                </div>
                            </div>

                        </div>
                    </NavLink>
                }



            </div>
        </div>
    );
};

export default Reports;
