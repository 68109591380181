import React from 'react';
import s from './LocationsList.module.css'
import {useSelector} from "react-redux";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next/hooks";
import {useGetLocationsQuery} from "../../../Redux/APIs/otherAPI/locationsApi";


const LocationsList = () => {
    // const locations = useSelector(state => state.location.locations)
    const {data:locations} = useGetLocationsQuery()
    const [t,i18n] = useTranslation()
    return (
        <div className={s.location}>

            <div className={s.location__elem}>
                <div className={s.location__textboxGray}>
                    {t('locations.deviceName')}
                </div>
                <div className={s.location__textboxGray}>
                    {t('locations.group')}
                </div>
                <div className={s.location__textboxGray}>
                    {t('locations.status')}
                </div>
                <div className={s.location__textboxGray}>
                    {t('locations.location')}
                </div>
                <div className={s.location__textboxGray}>
                    {t('locations.action')}
                </div>
            </div>
            {locations.map((elem,id) =>
                    <div key={id} className={s.location__elem}>
                        <div className={s.location__textboxName}>
                            <div className={s.location__title}>
                                {elem.name}
                            </div>
                            <div className={s.location__settingsMobile}>
                                <FontAwesomeIcon icon={faGear} color={'#bebebe'}/>
                                <FontAwesomeIcon icon={faTrashCan} color={'#eb3e3b'}/>
                            </div>
                        </div>
                        <div className={s.location__textbox}>
                            <div className={s.location__group}>
                                <span className={s.location__span}>Группа</span>
                                {elem.address.city}
                            </div>
                        </div>
                        <div className={s.location__textbox}>
                            <div className={s.location__group}>
                                <span className={s.location__span}>Статус</span>
                                Название устройства
                            </div>
                        </div>
                        <div className={s.location__textbox}>
                            <div className={s.location__place}>
                                <span className={s.location__span}>Адрес</span>
                                г. {elem.address.city}, ул. {elem.address.street} {elem.address.building}
                            </div>
                        </div>
                        <div className={s.location__textbox}>
                            <div className={s.location__settings}>
                                <div className={s.location__edit}>
                                    <FontAwesomeIcon icon={faGear}/>
                                </div>
                                <div className={s.location__delete}>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>

    );
};

export default LocationsList;