import React from 'react';
import s from './AdminRawdataInfo.module.scss'
import {useSelector} from "react-redux";
const AdminRawdataInfo = () => {
    const currentElem = useSelector(state => state.rawdata.currentElem)
    return (
        <div className={s.info}>
            {currentElem
                &&
                <>
                    <div className={s.info__info}>
                        <pre>
                            {JSON.stringify(currentElem, null, 2)}
                        </pre>
                    </div>
                    <div className={s.info__images}>
                        <img className={s.info__image} src={currentElem.url} alt=""/>
                    </div>
                </>
            }
        </div>
    );
};

export default AdminRawdataInfo;