import React, {useState} from 'react';
import {PROXY} from "../../../../../utils/pathss";
import s from "../FacesSearchAllVisits.module.scss";

const AllVisitsImage = ({url,setCurrentImage,setShowFullImage}) => {
    const [show,setShow] = useState(false)
    return (
        <div className={s.popup__imgBox}>
            {!show
                &&
                <div className={s.popup__imgBanner}>

                </div>
            }
            <img
                onLoad={() => setShow(true)}
                onClick={() => {
                    setCurrentImage(`${PROXY}/api/${url}`)
                    setShowFullImage(true)
                }}
                className={s.popup__img}
                src={`${PROXY}/api/${url}`}
                alt="dfv"
            />

        </div>
    );
};

export default AllVisitsImage;