
export const times = []
for(let i = 0; i < 25; i+=4){
    const txt = `${i}:00`.length == 5 ? `${i}:00` : `0${i}:00`
    times.push({text:txt,time:`${i}`})
}


export const hours = []
for(let i = 0; i < 25; i++){
    const txt = `${i}:00`.length == 5 ? `${i}:00` : `0${i}:00`
    hours.push({text:txt,time:`${i}`})
}

export const minutes = []
for(let i = 0; i < 24.5; i+=0.5){
    const hrs = String(Math.floor(i)).length == 1 ? `0${Math.floor(i)}` :  `${Math.floor(i)}`
    const mns = (i*60)%60 == 30 ? (i*60)%60 : '00'
    const txt = `${hrs}:${mns}`
    minutes.push({text:txt,time:`${i}`})
}

export const minutes12 = []
for(let i = 0; i < 242; i+=2.5){
    let hrs = 0
    let mns = 0
    if(!String(i/10).includes('.')){
        hrs = i/10
        mns = '00'

    }
    else if(String(i/10).includes('.')){
        hrs = String(i/10).split('.')[0]
        mns = String((String(i/10).split('.')[1])*6).slice(0,2)

    }
    hrs = String(hrs).length == 1 ? `0${hrs}` : hrs
    minutes12.push({text:`${hrs}:${mns}`, time:i/10})
}

export const minutes6 = []
for(let i = 0; i < 242; i+=1){
    let hrs = 0
    let mns = 0
    if(!String(i/10).includes('.')){
        hrs = i/10
        mns = '00'

    }
    else if(String(i/10).includes('.')){
        hrs = String(i/10).split('.')[0]
        mns = (String(i/10).split('.')[1])*6

    }
    hrs = String(hrs).length == 1 ? `0${hrs}` : hrs
    mns = String(mns).length == 1 ? `0${mns}` : mns
    minutes6.push({text:`${hrs}:${mns}`, time:i/10})
}

export const minutes3 = []
for(let i = 0; i < 242; i+=0.5){
    let hrs = 0
    let mns = 0

    if(!String(i/10).includes('.')){
        hrs = i/10
        mns = '00'

    }
    else if(String(i/10).includes('.')){
        hrs = String(i/10).split('.')[0]
        mns = String((i%10)*6)

    }
    hrs = String(hrs).length == 1 ? `0${hrs}` : hrs
    mns = String(mns).length == 1 ? `0${mns}` : mns

    minutes3.push({text:`${hrs}:${mns}`, time:i/10})
}

