import React, {useState} from 'react';
import s from "../CamControl.module.css";
import {useSelector} from "react-redux";

const CamControlTime = ({timePopUp}) => {
    const mp = useSelector(state => state.camControl.mp)
    const txt = useSelector(state => state.camControl.txt)
    return (
        <>
            {timePopUp === true
                &&
                <div className={s.control__time} style={{left:`${mp.x <=580 ? mp.x + 40 : mp.x - 60}px`}}>
                    {txt}
                </div>
            }
        </>
    );
};

export default CamControlTime;