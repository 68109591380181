import React, {useEffect} from 'react';
import s from '../FullPerson.module.scss'
import {useParams} from "react-router-dom";
import FullPersonPhoto from "../FullPersonPhoto/FullPersonPhoto";
import Loader from "../../../../UI/Loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next/hooks";
import {useGetOnePersonPhotosQuery} from "../../../../../Redux/APIs/otherAPI/onePersonApi";
const FullPersonPhotos = ({id}) => {
    const pId = useParams().pId
    const {data:photos,isLoading,isError,error} = useGetOnePersonPhotosQuery({id:id ? id : pId})
    const [t,i18n] = useTranslation()
    return (
        <div className={s.fullpersonPhotosBox}>
            {isLoading
                ?
                <div className={s.fullperson__loader} >
                    <Loader/>
                </div>
                :
                error !== 400 && photos?.length > 0
                    ?
                    <div  className={s.fullperson__photos}>
                        <FullPersonPhoto elem={photos.find(elem => elem?.type === 'right')} type={'right'}/>
                        <FullPersonPhoto elem={photos.find(elem => elem?.type === 'fright')} type={'fright'}/>
                        <FullPersonPhoto elem={photos.find(elem => elem?.type === 'frontal')} type={'frontal'}/>
                        <FullPersonPhoto elem={photos.find(elem => elem?.type === 'fleft')} type={'fleft'}/>
                        <FullPersonPhoto elem={photos.find(elem => elem?.type === 'left')} type={'left'}/>
                    </div>
                    :
                    <div className={s.fullperson__loader}>
                        <FontAwesomeIcon icon={faImage} color={'#b9b8b8'} size={'3x'}/>
                        {t('default.photosNotFound')}
                    </div>
            }
        </div>
    );
};

export default FullPersonPhotos;