import React, {useState} from 'react';
import s from './FacesSearchFacePopup.module.scss'
import ChangePhotoElem from "../../Persons/ChangePhoto/ChangePhotoElem";
import GreenButton from "../../../UI/GreenButton/GreenButton";
import BlueButtonFull from "../../../UI/BlueButtonFull/BlueButtonFull";
import BlueButton from "../../../UI/BlueButton/BlueButton";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFacesSearchDate,
    postFacesSearches,
    setCurrentLocation,
    setFacePhoto
} from "../../../../Redux/Slices/facesSearchSlice";
import LocationsSelect from "../../../UI/LocationsSelect/LocationsSelect";
import {useGetLocationsQuery} from "../../../../Redux/APIs/otherAPI/locationsApi";
import {useTranslation} from "react-i18next/hooks";
const FacesSearchFacePopup = ({setShow,sendSearch}) => {
    const [photo,setPhoto] = useState('')
    const [loc,setLoc] = useState('')
    const [isPhotoWrong,setIsPhotoWrong] = useState(false)
    const [isLocWrong,setIsLocWrong] = useState(false)
    const [t,i18n] = useTranslation()
    const changePhoto = () => {
        if(photo){
            if(loc){
                sendSearch(photo,loc)
            }else if(!loc){
                setIsLocWrong(true)
            }
        }else if(!photo){
            setIsPhotoWrong(true)
        }
    }

    const changeLocation = (location) => {
        setLoc(location)
    }
    return (
        <div className={s.facePopup}>
            <div className={s.facePopup__title}>
                {t('search.searchButton')}
            </div>
            <ChangePhotoElem setPhotos={setPhoto} title={''}/>
            <span className={s.facePopup__text}>Для ускорения поиска выберите локацию</span>
            <LocationsSelect changeLocation={changeLocation}/>
            {isPhotoWrong
                &&
                <span style={{fontSize:14,color:'red'}}>Фото не добавлено</span>
            }
            {isLocWrong
                &&
                <span style={{fontSize:14,color:'red'}}>Локация не выбрана</span>
            }
            <div style={{width:180}}>
                <BlueButton onClick={changePhoto}>
                    Отправить
                </BlueButton>
            </div>


        </div>
    );
};

export default FacesSearchFacePopup;