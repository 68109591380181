import React, {useState} from 'react';
import s from "../PersonBigTable.module.scss";
import TableCell from "../TableCell/TableCell";
import Modal from "../../../UI/Modal/Modal";
import TablePopup from "../TablePopup/TablePopup";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import createDays from "../funcs/createDays";

const TableRow = ({row, setActiveCellTime,activeCellTime,attendance}) => {
    return (
        <>
            <tr className={s.table__row}>
                <th className={s.table__cellDate}>
                    {row?.date}
                </th>
                <td className={s.table__cellSubdate}>
                    {row.first}
                </td>
                <td className={s.table__cellSubdate}>
                    {row.last}
                </td>
                {createDays({numberOfTimes:24,dayTimes:row.times,attendance:attendance}).map((cell,id) =>
                    <TableCell cell={cell} key={id} activeCellTime={activeCellTime} setActiveCellTime={setActiveCellTime}>
                    </TableCell>
                )}
            </tr>
        </>
    );
};

export default TableRow;
