import {mainApi} from "../testAPI";
import axios from "axios";
import getDayStartTime from "../../../utils/getDayStartTime";
import getDayEndTime from "../../../utils/getDayEndTime";

export const personAllVisitsApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getPersonAllVisits: builder.query({
            queryFn: async ({person}) => {
                try {
                    const datStart = getDayStartTime(new Date(22,0,1))
                    const datEnd = getDayEndTime(new Date())
                    console.log(person)
                    const res = await axios.get(`/api/counter/attendance`, {
                        headers: {
                            "Authorization": localStorage.getItem('token')
                        },
                        params: {
                            dt_start:datStart,
                            dt_end:datEnd,
                            person:person
                        }
                    })
                    const data = await res.data.data
                    return {data:data}
                } catch (e) {
                    return {error:e.response.status}
                }
            },
            providesTags: () => [{type:'personAllVisits'}],
        }),
    }),
})

export const { useGetPersonAllVisitsQuery} = personAllVisitsApi
