import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    dataChanged: false,
    timePopUp:false,
    txt:'',
    mp:{},
}

export const camControlSlice = createSlice({
    name: 'camControl',
    initialState,
    reducers: {
        changeStage:(state) => {
            state.dataChanged = !state.dataChanged
        },
        handleControlMove:(state,action) => {
            let h =  Math.floor(((action.payload.pointerPosX / action.payload.oldScale - action.payload.stageX/action.payload.oldScale)/action.payload.otn)/3600)
            const m = Math.floor(((action.payload.pointerPosX / action.payload.oldScale - action.payload.stageX/action.payload.oldScale)/action.payload.otn) / 60) - (h * 60)
            const s = ((action.payload.pointerPosX / action.payload.oldScale - action.payload.stageX/action.payload.oldScale)/action.payload.otn) % 60;
            if(h >= 24){
                h = h-24
            }
            state.txt = `${h}:${m}:${Math.round(s)}`
            state.mp = {x:action.payload.pointerPosX}
        }

    },
})


export const {changeStage,handleControlMove} = camControlSlice.actions

export default camControlSlice.reducer