import React from 'react';
import s from "../PersonBigTable.module.scss";

const TableCell = ({cell, setActiveCellTime,activeCellTime}) => {
    return (
        <>
            <td className={cell.num > 0 ?
                activeCellTime === cell.time ?
                    s.table__cellHaveAct :
                    s.table__cellHave :
                activeCellTime === cell.time ?
                    s.table__cellAct :
                    +cell.time.slice(0,2) >= cell.first && +cell.time.slice(0,2) <= cell.last ?
                        s.table__cellInRange :
                        s.table__cell
            }

                onMouseEnter={() => setActiveCellTime(cell.time)}
            >
                {cell.num === 0 ? '' : cell.num}
            </td>

        </>
    );
};

export default TableCell;