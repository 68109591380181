import Login from "./pages/authing/Login";
import Main from "./pages/main/Main";
import Test from "./pages/test/test";
import Cams from "./pages/Cams/Cams";
import History from "./pages/Faces/History/History";
import HistoryAdmin from "./pages/Faces/HistoryAdmin/HistoryAdmin";
import Locations from "./pages/Locations/Locations";
import {
    ADMIN_MONITORING_ROUTE, ADMIN_ROUTE,
    CAMS_FULL_ROUTE,
    CAMS_ROUTE, EVENTS_ROUTE, FACES_CURRENTPERSON_ROUTE,
    FACES_HISTORY_ROUTE, FACES_PERSONS_ROUTE, FACES_PERSONSOPEN_ROUTE,
    FACES_ROUTE, FACES_SEARCH_ROUTE,
    LOCATIONS_ROUTE,
    LOGIN_ROUTE,
    MAIN_ROUTE, MAP_ROUTE,
    PEOPLE_ROUTE, RAWDATA_ROUTE, REPORT_PERSON_ROUTE, REPORT_VISITORS_ROUTE, REPORTS_ROUTE, REPOST_VISITS_HISTORY_ROUTE
} from "./utils/pathss";
import Persons from "./pages/Faces/Persons/Persons";
import Events from "./pages/Events/Events";
import PersonsOpen from "./pages/Faces/PersonsOpen/PersonsOpen";
import MyMap from "./pages/Locations/MyMap/MyMap";
import CamView from "./pages/CamView/CamView";
import Reports from "./pages/reports/Reports";
import ReportsVisitors from "./pages/reports/ReportsVisitors/ReportsVisitors";
import PersonInfo from "./pages/Faces/Persons/PersonInfo/PersonInfo";
import AdminRawdata from "./pages/adminRawdata/AdminRawdata";
import ReportsPerson from "./pages/reports/ReportsPerson/ReportsPerson";
import ReportsVisitsHistory from "./pages/reports/ReportsVisitsHistory/ReportsVisitsHistory";
import FacesSearch from "./pages/Faces/FacesSearch/FacesSearch";


export const privateRoutes = [
    {
        path: PEOPLE_ROUTE,
        element:<Test/>

    },
    {
        path: CAMS_ROUTE,
        element:<Cams/>
    },
    {
        path: CAMS_FULL_ROUTE,
        element: <CamView/>
    },

    {
        path: LOCATIONS_ROUTE,
        element: <Locations/>
    },
    {
        path: MAP_ROUTE,
        element: <MyMap/>
    },
    {
        path: FACES_ROUTE,
        element: <History/>
    },
    {
        path: FACES_HISTORY_ROUTE,
        element: <History/>
    },
    {
        path: FACES_PERSONS_ROUTE,
        element: <Persons/>
    },
    {
        path: FACES_PERSONSOPEN_ROUTE,
        element: <PersonsOpen/>
    },
    {
        path: FACES_CURRENTPERSON_ROUTE,
        element: <PersonInfo/>
    },
    {
        path: FACES_SEARCH_ROUTE,
        element: <FacesSearch/>
    },
    {
        path: EVENTS_ROUTE,
        element: <Events/>
    },

    {
        path: REPORTS_ROUTE,
        element: <Reports/>
    },
    {
        path: REPORT_VISITORS_ROUTE,
        element: <ReportsVisitors/>
    },
    {
        path: REPORT_PERSON_ROUTE,
        element: <ReportsPerson/>
    },
    {
        path: REPOST_VISITS_HISTORY_ROUTE,
        element: <ReportsVisitsHistory/>
    },
    {
        path: '/*',
        element: <Cams/>
    },

]

export const adminRoutes = [
    ...privateRoutes,
    {
        path: ADMIN_ROUTE,
        element: <HistoryAdmin/>
    },
    {
        path: ADMIN_MONITORING_ROUTE,
        element: <HistoryAdmin/>
    },
    {
        path: RAWDATA_ROUTE,
        element: <AdminRawdata/>
    },

]

export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        element: <Main/>
    },
    {
        path: LOGIN_ROUTE,
        element: <Login/>
    },
    {
        path: '/*',
        element: <Main/>
    },

]


