import React, {useState} from 'react';
import s from './FullPersonChange.module.scss'
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {fetchAuth} from "../../../../../Redux/Slices/authSlice";
import BlueButtonFull from "../../../../UI/BlueButtonFull/BlueButtonFull";
import JustInput from "../../../../UI/JustInput/JustInput";
import {useTranslation} from "react-i18next/hooks";

const FullPersonChange = () => {

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        console.log(data)
    };
    const [t,i18n] = useTranslation()

    return (
        <div className={s.personChange} >
            <div className={s.personChange__title}>
                {t('persons.changePerson.changePerson')}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={s.form} >
                <div>
                    <input className={s.form__input} placeholder={t('default.name')} name={'email'}  {...register("name",{
                        required:true,
                        minLength:2
                    })}/>
                </div>
                <div>
                    <input className={s.form__input} placeholder={t('default.occupation')} name={'email'}  {...register("pos",{
                        required:true,
                        minLength:2
                    })}/>
                </div>
                <div>
                    <input className={s.form__input} placeholder={t('default.phone')} name={'email'}  {...register("number",{
                        required:true,
                        minLength:5
                    })}/>
                </div>

                <div >
                    <BlueButtonFull padding={13} type={'submit'}>
                        {t('default.send')}
                    </BlueButtonFull>
                </div>
            </form>
        </div>
    );
};

export default FullPersonChange;