import React, {useEffect} from 'react';
import s from "../History/History.module.css";
import Toolbar from "../../../components/toolbar/Toolbar";
import HistoryToolbar from "../../../components/Faces/History/HistoryToolbar/HistoryToolbar";
import {useDispatch, useSelector} from "react-redux";
import {fetchHistory} from "../../../Redux/Slices/facesHistorySlice";
import {logout} from "../../../Redux/Slices/authSlice";
import HistoryAdminFaces from "../../../components/Faces/HistoryAdmin/HistoryAdminFaces/HistoryAdminFaces";
import HistoryAdminPersonInfo
    from "../../../components/Faces/HistoryAdmin/HistoryAdminPersonInfo/HistoryAdminPersonInfo";
import Loader from "../../../components/UI/Loader/Loader";
import CamsNotFound from "../../../components/Cams/CamsNotFound/CamsNotFound";

const HistoryAdmin = () => {
    const isLoading = useSelector(state => state.facesHistory.isLoading)
    const historyDate = useSelector(state => state.facesHistory.historyDate)
    const currentUser = useSelector(state => state.auth.currentUser)
    const isHaveCameras = useSelector(state => state.auth.isHaveCameras)
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const errorCode = useSelector(state => state.facesHistory.errorCode)
    const dispatch = useDispatch()
    useEffect(() => {
        let cam = currentCamera !== '' ? currentCamera : (currentUser.cameras.length !== 0 && currentUser.cameras[0].id)
        if(isHaveCameras){
            dispatch(fetchHistory({date:historyDate,currentCamera:cam}))
        }

    },[historyDate,currentCamera])

    if(errorCode === 401){
        dispatch(logout())
    }
    return (
        <div className={s.historybox}>
            <Toolbar title={'История'}>
                <HistoryToolbar/>
            </Toolbar>
            {isHaveCameras
                ?
                <div className={s.historySplit}>
                    {isLoading === false
                        ?
                        <HistoryAdminFaces/>
                        :
                        <div className={s.history__banner}>
                            <Loader/>
                        </div>
                    }
                    <HistoryAdminPersonInfo/>
                </div>
                :
                <CamsNotFound/>
            }
        </div>
    );
};

export default HistoryAdmin;