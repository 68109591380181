import './App.css';
import {BrowserRouter, useNavigate} from "react-router-dom";
import Header from "./components/header/header";
import AppRouter from "./Router/AppRouter";
import Sidebar from "./components/Sidebar/Sidebar";
import './App.css'
import {useSelector} from "react-redux";
import {useEffect} from "react";

function App() {
    const isAuth = useSelector(state => state.auth.isAuth)

  return (
      <BrowserRouter>
          <div className='headerMarginer'>
              <div className={'cont'}>
                  {isAuth === true
                      ?
                      <>
                          <Header/>
                          <div className='routerContainer'>
                              <Sidebar/>
                              <AppRouter/>
                          </div>
                      </>

                      :
                      <AppRouter/>
                  }
              </div>

          </div>

      </BrowserRouter>
  );
}

export default App;
