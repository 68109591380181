import React,{useState} from 'react';
import s from './PersonsModal.module.css'
import GreenButton from "../../../UI/GreenButton/GreenButton";
import JustInput from "../../../UI/JustInput/JustInput";
import {Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import {postPersonsGroup} from "../../../../Redux/Slices/personSlice";
import ModalBackCross from "../../../UI/Modal/ModalBackCross";
import {useTranslation} from "react-i18next/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {usePostPersonsGroupMutation, usePostPersonsGroupsMutation} from "../../../../Redux/APIs/otherAPI/personApi";


const PersonsModal = (props) => {

    const dispatch = useDispatch()
    const [listName,setListName] = useState('')
    const [selectedColor, setSelectedColor] = useState('#bfbfbf')
    const [selectedColorID, setSelectedColorID] = useState('d1')
    const [sendNewPersonGroup,res] = usePostPersonsGroupMutation()
    const colors = [
        {color:'#FCB900' ,id: 1},
        {color:'#FF6900' ,id: 2},
        {color:'#7BDCB5' ,id: 3},
        {color:'#239288' ,id: 4},
        {color:'#8ED1FC' ,id: 5},
        {color:'#0693E3' ,id: 6},
        {color:'#EB144C' ,id: 8},
    ]

    const addNewPerson = () => {
        if(listName.length > 2){
            sendNewPersonGroup({name:listName,color:selectedColor})
            props.closeModal(false)
        }
        else if(listName.length <= 2){
            setListName('')
        }
    }
    const [t,i18n] = useTranslation()
    return (
        <div className={s.personsModal}>
            <ModalBackCross setShowModal={props.closeModal} />
            <div className={s.personsModal__addList}>
                {t('persons.addListModal.addList')}
            </div>
            <div className={s.personsModal__Color}>
                {t('persons.addListModal.listColor')}
            </div>
            <div className={s.personsModal__ColorRect}>
                {colors.map((elem) =>
                    <div key={elem.id} id={elem.id} className={s.personsModal__ColorRectElem} style={{backgroundColor:`${elem.color}`,border:`${selectedColorID === elem.id ? 2 : 0}px solid black`}} onClick={(e) => {
                        setSelectedColor(elem.color)
                        setSelectedColorID(elem.id)
                    }}>
                    </div>
                )}
                <div className={s.personsModal__colorPickBox}>
                    <label htmlFor="colorPick"><FontAwesomeIcon icon={faPlus}/></label>
                    <input value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} id={'colorPick'} className={s.personsModal__colorPick} type="color"/>
                </div>

            </div>
            <div className={s.personsModal__listName}>
                {t('persons.addListModal.listName')}
            </div>
            <div>
                <JustInput placeholder={t('persons.addListModal.newList')} onChange={(e) => setListName(e.target.value)} value={listName} />
            </div>
            <div className={s.personsModal__buttons}>
                <GreenButton onClick={() => addNewPerson()} >
                    {t('persons.persons.addList')}
                </GreenButton>
                <GreenButton bleach onClick={() => props.closeModal(false)} >
                    {t('default.back')}
                </GreenButton>
                <div>

                </div>
            </div>
        </div>
    );
};

export default PersonsModal;