    import React from 'react';
import s from "../../../../pages/Faces/Persons/Persons.module.css";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PaginationControl from "../../../UI/PaginationControl/PaginationControl";
import {
    backGroupPagination,
    goGroupPagination,
    setGroupPagination,
    setPagination
} from "../../../../Redux/Slices/personSlice";
    import {useTranslation} from "react-i18next/hooks";
    import {useGetPersonsGroupQuery} from "../../../../Redux/APIs/otherAPI/personApi";


const PersonsGroupList = () => {
    const currentGroupPage = useSelector(state => state.persons.currentGroupPage)
    const {data:persons,isLoading} = useGetPersonsGroupQuery({opt:'slice'})
    const dispatch = useDispatch()
    const changePage = (opt) => {
        if(opt === 'go'){
            dispatch(goGroupPagination())
        }else if (opt === 'back'){
            dispatch(backGroupPagination())
        }else if (typeof opt === "number"){
            dispatch(setGroupPagination(opt))
        }
    }
    const [t,i18n] = useTranslation()

    return (
        <div className={s.persons}>
            <div className={s.persons__pagination}>
                {!isLoading
                    &&
                    <PaginationControl list={persons} currentPage={currentGroupPage} changePage={changePage} />
                }
            </div>
            <div className={s.persons__list}>
                <div className={s.persons__elem}>
                    <div>

                    </div>
                    <div className={s.persons__textboxName}>
                        {t('persons.persons.listName')}
                    </div>
                    <div className={s.persons__textboxPersonsCount}>
                        {t('persons.persons.persons')}
                    </div>
                </div>
                {!isLoading
                    &&
                    persons.length > 0
                    &&
                    persons[currentGroupPage].map((elem) =>
                        <NavLink key={elem?.id} to={`/faces/persons/${elem?.id}`} className={s.navlinkno} onClick={() => dispatch(setPagination(0))} >
                            <div className={s.persons__GrayElem}>
                                <div className={s.persons__textboxName}>
                                    <div className={s.persons__listInfo}>
                                        <div className={s.persons__listName}>
                                            {elem?.name}
                                        </div>
                                        <div className={s.persons__listColor} style={{backgroundColor:`${elem?.color}`}}>
                                        </div>
                                    </div>

                                </div>
                                <div className={s.persons__textboxPersonsCount}>
                                    {elem?.num}
                                </div>
                            </div>
                        </NavLink>
                    )
                }
            </div>
        </div>
    );
};

export default PersonsGroupList;
