import React from 'react';
import s from './BlueButton.module.scss'
const BlueButton = (props) => {
    return (
        <button {...props} className={s.button}>
            {props.children}
        </button>
    );
};

export default BlueButton;