import React, {useEffect} from 'react';
import CameraSelect from "../UI/CameraSelect/CameraSelect";
import {useDispatch, useSelector} from "react-redux";
import {
    changeReportDate,
    setCurrentCamera,
    setCurrentCameraName,
    setCurrentLocation
} from "../../Redux/Slices/VisitorsSlice";
import s from './VisitorsReportsToolbar.module.css'
import DateRangeCalend from "../ViewSettings/DateRangeCalend/DateRangeCalend";
import LocationsSelect from "../UI/LocationsSelect/LocationsSelect";
import {fetchLocation} from "../../Redux/Slices/locationSlice";
import {useGetLocationsQuery} from "../../Redux/APIs/testAPI";

const VisitorsReportsToolbar = () => {
    const dispatch = useDispatch()
    const currentCamera = useSelector(state => state.visitorsReport.currentCamera)
    const currentLocation = useSelector(state => state.visitorsReport.currentLocation)
    const camsList = useSelector(state => state.auth.currentUser.cameras)
    const locations = useSelector(state => state.location.locations)
    const changeLocation = (location) => {
        dispatch(setCurrentLocation(location))
    }
    const chDate = (firstDate, secondDate) => {
        dispatch(changeReportDate({firstDate: firstDate, secondDate:secondDate}))
    }
    useEffect(() => {
        if(currentCamera){
            const cam = camsList.find(cam => cam.id === currentCamera).name
            setCurrentCameraName(cam)
        }
    },[currentCamera])
    useEffect(() => {
        if(locations.length === 0){
            dispatch(fetchLocation())
        }
    },[])
    return (
        <div className={s.toolbar}>
            <LocationsSelect currentLocation={currentLocation === '' ? locations[0] : currentLocation} changeLocation={changeLocation}/>
            <DateRangeCalend timeSpan={true} chDate={chDate} />
        </div>
    );
};

export default VisitorsReportsToolbar;
