import React from 'react';
import s from './VisitsHistoryCharts.module.scss'
import genderChartRefactor from "../../../utils/GenderChartRefactor";
import GenderChart from "./genderChart/genderChart";
import ageChartRefactor from "../../../utils/ageChartRefactor";
import AgeChart from "./ageChart/ageChart";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next/hooks";
import ChartNotFound from "./chartNotFound/chartNotFound";
import Loader from "../../UI/Loader/Loader";
import ageChart from "./ageChart/ageChart";
const VisitsHistoryCharts = () => {
    const visitsHistoryCharts = useSelector(state => state.visitsHistoryReport.visitsHistoryCharts)
    const [t,i18n] = useTranslation()
    const isLoading = useSelector(state => state.visitsHistoryReport.isLoading)
    return (
        <div className={s.chartsbox}>
            <div className={s.chart}>
                <div className={s.chart__title}>
                    {t('personReport.charts.chartGender')}
                </div>
                {isLoading
                    ?
                    <Loader/>
                    :
                    visitsHistoryCharts.length > 0
                    ?
                    <GenderChart data={genderChartRefactor(visitsHistoryCharts)} />
                    :
                    <ChartNotFound/>
                }
            </div>
            <div className={s.chart}>
                <div className={s.chart__title}>
                    {t('personReport.charts.chartAge')}
                </div>
                {isLoading
                    ?
                    <Loader/>
                    :
                    visitsHistoryCharts.length > 0
                    ?
                    <AgeChart data={ageChartRefactor(visitsHistoryCharts)} />
                    :
                    <ChartNotFound/>
                }
            </div>
        </div>
    );
};

export default VisitsHistoryCharts;
