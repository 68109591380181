import React, {useEffect} from 'react';
import s from './AdminRawdataList.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {fetchRawdata, setCurrentElem} from "../../../Redux/Slices/rawdataSlice";
import getTimeAndDate from "../../../utils/getTimeAndDate";
const AdminRawdataList = () => {
    const dispatch = useDispatch()
    const id = useSelector(state => state.rawdata.id)
    const rawdata = useSelector(state => state.rawdata.rawdata)
    const currentElem = useSelector(state => state.rawdata.currentElem)
    const isLoading = useSelector(state => state.rawdata.isLoading)
    useEffect(() => {
        dispatch(fetchRawdata({id:id}))
    },[id])

    const dispatchCurrentElemId = (elem) => {
        dispatch(setCurrentElem(elem))
    }

     return (
        <>
            {!isLoading
                ?
                    rawdata.length > 0 ?
                        <ul className={s.list}>
                            {rawdata.map(elem =>
                                <li
                                    className={currentElem?.id === elem.id ? s.list__elemAct : s.list__elem}
                                    onClick={() => dispatchCurrentElemId(elem)}
                                >
                                    <div className={s.list__id}>
                                        id: {elem.id}
                                    </div>
                                    <div className={<s className="list__code"></s>}>
                                        дата: {getTimeAndDate('date', elem.dt)}
                                    </div>
                                    <div className={<s className="list__code"></s>}>
                                        время: {getTimeAndDate('time', elem.dt)}
                                    </div>

                                </li>
                            )}
                        </ul>
                        :
                        <div>нет данных</div>
                :
                    <div>Загрузка...</div>

            }

        </>
    );
};

export default AdminRawdataList;