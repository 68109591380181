import React, {useState} from 'react';
import s from "../../Cams/CamsToolbar/CamsToolbar.module.css";
import GreenButton from "../../UI/GreenButton/GreenButton";
import RoundButton from "../../UI/RoundButton/RoundButton";
import Modal from "../../UI/Modal/Modal";
import AddLocation from "../AddLocation/AddLocation";
import {useTranslation} from "react-i18next/hooks";
import {usePostLocationsMutation} from "../../../Redux/APIs/otherAPI/locationsApi";


const LocationsToolbar = () => {
    const [showModal,setShowModal] = useState(false)
    const [postNewLocation,res] = usePostLocationsMutation()
    const addLocation = (data) => {
        postNewLocation(data)
        setShowModal(false)
    }
    const [t,i18n] = useTranslation()
    return (
        <div className={s.toolbar__toolbox}>
            <div className={s.toolbar__buttons}>
                <GreenButton onClick={() => setShowModal(true)}>
                    {t('locations.addLocation')}
                </GreenButton>
            </div>
            {showModal &&
                <Modal setShowModal={setShowModal} showModal={showModal}>
                    <AddLocation addLocation={addLocation}/>
                </Modal>
            }
            <RoundButton onClick={() => setShowModal(true)} />
        </div>
    );
};

export default LocationsToolbar;