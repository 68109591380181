import React from 'react';
import TableRow from "../tableRow/tableRow";

const TableBody = ({attendance,activeCellTime,setActiveCellTime}) => {
    return (
        <tbody>
        {attendance.map((row,id) =>
            <TableRow attendance={attendance} row={row} id={id} activeCellTime={activeCellTime} setActiveCellTime={setActiveCellTime}>
            </TableRow>
        )}
        </tbody>
    );
};

export default TableBody;