import React, {useEffect, useRef, useState} from 'react';
import s from './PersonBigTable.module.scss'
import PersonTableElem from "./PersonTableElem/PersonTableElem";
import {useSelector} from "react-redux";
import tableRow from "./tableRow/tableRow";
import TableRow from "./tableRow/tableRow";
import PersonReportsNotFound from "../PersonReportsNotFound/PersonReportsNotFound";
import {useDownloadExcel} from "react-export-table-to-excel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next/hooks";
import createDays from "./funcs/createDays";
import TableHead from "./TableHead/TableHead";
import TableBody from "./TableBody/TableBody";
import Loader from "../../UI/Loader/Loader";
import useWindowSize from "../../../Hooks/useWindowSize/useWindowSize";
const PersonBigTable = () => {
    const attendance = useSelector(state => state.personReports.attendance)
    const isLoading = useSelector(state => state.personReports.attendanceIsLoading)
    const [activeCellTime,setActiveCellTime] = useState(null)
    const [tablePos,setTablePos] = useState(0)
    const tableRef = useRef(null);
    const boxRef = useRef(null)
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Таблица рабочего времени',
        sheet: 'person',

    })
    const rangeHandler = (e) => {
        if(+e === 1){
            setTablePos(0)
        }else{
            setTablePos(((tableRef.current.clientWidth - boxRef.current.clientWidth)/10)*e + 40)
        }
    }
    const windowSize = useWindowSize()

    const [t,i18n] = useTranslation()
    return (
        <>

            {!isLoading
                ?
                attendance?.length > 0
                    ?
                    <div className={s.tablebox} ref={boxRef} >
                        <div onClick={onDownload} className={s.excelExport}>
                            <FontAwesomeIcon icon={faFileExcel}/>
                            <span>{t('personReport.exportExcel')}</span>
                        </div>
                        {tableRef
                            &&
                                windowSize.width-80 < tableRef?.current?.offsetWidth
                            &&
                            <input defaultValue={1} min={1} max={10} onChange={(e) => rangeHandler(e.target.value)} type="range"/>

                        }
                        <table style={{marginLeft:`-${tablePos}px`}} ref={tableRef} className={s.table} onMouseLeave={() => setActiveCellTime(null)}>
                            <TableHead attendance={attendance} />
                            <TableBody attendance={attendance} activeCellTime={activeCellTime} setActiveCellTime={setActiveCellTime}/>
                        </table>
                    </div>
                    :
                    <PersonReportsNotFound/>
                :
                <Loader/>
            }

        </>
    );
};

export default PersonBigTable;
