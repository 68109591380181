import React, {useEffect} from 'react';
import s from './PersonInfo.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import FullPerson from "../../../../components/Faces/Persons/FullPerson/FullPerson";
import {useGetOnePersonQuery} from "../../../../Redux/APIs/otherAPI/onePersonApi";

const PersonInfo = () => {
    const pId = useParams().pId
    const {data:person,isLoading} = useGetOnePersonQuery({id:pId},{refetchOnMountOrArgChange:true})
    return (
        <div className={s.personbox}>
            {!isLoading
                &&
                <div className={s.person}>
                    <FullPerson person={person} />
                </div>
            }
        </div>
    );
};

export default PersonInfo;
