import React, {useEffect, useState} from 'react';
import s from './HistoryFaces.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    fetchLatestHistory
} from "../../../../Redux/Slices/facesHistorySlice";
import HistoryFacesList from "../HistoryFacesList/HistoryFacesList";
import HistoryNotFound from "../HistoryNotFound/HistoryNotFound";
import MiniLoader from "../../../UI/MiniLoader/MiniLoader";
import ToTopScroller from "../../../UI/ToTopScroller/toTopScroller";

const HistoryFaces = () => {
    const history = useSelector(state => state.facesHistory.history)
    const dispatch = useDispatch()
    const historyDate = useSelector(state => state.facesHistory.historyDate)
    const currentUser = useSelector(state => state.auth.currentUser)
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const isLatestLoading = useSelector(state => state.facesHistory.isLatestLoading)
    useEffect(() => {
        document.addEventListener('scroll',handleScroll)
        return () => document.removeEventListener('scroll',handleScroll)
    },[history])

    const handleScroll = () => {
        if(document.documentElement.clientHeight + window.scrollY === document.documentElement.offsetHeight){
            let lastPag = history[history.length - 1]
            let cam = currentCamera !== '' ? currentCamera : currentUser.cameras[0].id
            dispatch(fetchLatestHistory({ date:historyDate, currentCamera:cam, last_rid: lastPag.rid,lateHistory:history}))
        }
    }

    return (
        <div className={s.historyFaces} data-testid={'historyFaces'}>
            {history.length > 0
                ?
                <HistoryFacesList/>
                :
                <HistoryNotFound/>
            }
            <div className={s.historyFaces__loaderbox}>
                {isLatestLoading
                    &&
                    <MiniLoader/>
                }
            </div>


            <ToTopScroller/>


        </div>
    );
};

export default HistoryFaces;
