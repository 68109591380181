const createDays = ({numberOfTimes, dayTimes,attendance}) => {
    const times = []
    if(!dayTimes){
        for(let i = attendance[0].minTime; i < numberOfTimes; i++){
            times.push({time:`${i <= 9 ? '0' + i : i}:00`})
        }
    }
    else if(dayTimes){
        const first = dayTimes[0].first
        const last = dayTimes[0].last
        for(let i = attendance[0].minTime; i < numberOfTimes; i++){
            if(dayTimes.filter(elem => +elem.time === i)[0]){
                const t = dayTimes.filter(elem => +elem.time === i)[0].time
                const n = dayTimes.filter(elem => +elem.time === i)[0].num
                times.push({time:`${+t <= 9 ? '0' + t : t}:00`,num:n,first:first,last:last})
            }else if(!dayTimes.filter(elem => +elem.time === i)[0]){
                times.push({time:`${i <= 9 ? '0' + i : i}:00`, num:0,first:first,last:last})
            }
        }
    }
    return times
}
export default createDays;