import React from 'react';
import s from './AuthForm.module.scss'
import logo from '../../../public/Icons/VectorLogo.svg'
import LoginForm from "../LoginForm/LoginForm";
import {useDispatch, useSelector} from "react-redux";
import {setLogMode} from "../../../Redux/Slices/authSlice";
import RegisterForm from "../RegisterForm/RegisterForm";
import langs from "../../../utils/langs";
import {useTranslation} from "react-i18next/hooks";
const AuthForm = () => {
    const logMode = useSelector(state => state.auth.logMode)
    const dispatch = useDispatch()
    const [t,i18n] = useTranslation()
    return (
        <div className={s.authform}>
            <div className={s.authform__logo}>
                <img src={logo} alt=""/>
                <span className={s.authform__title}>Rannesme</span>
            </div>
            <div className={s.authform__auth}>

                {logMode === 'login'
                    ?
                    <>
                        <LoginForm/>
                        <div className={s.authform__text} onClick={() => dispatch(setLogMode('register'))}>
                            {t('auth.dontHaveAcc')}
                        </div>
                    </>

                    :
                    <>
                        <RegisterForm/>
                        <div className={s.authform__text} onClick={() => dispatch(setLogMode('login'))}>
                            {t('auth.haveAcc')}
                        </div>
                    </>

                }

            </div>
            <div className={s.authform__langs}>
                {Object.keys(langs).map((lng) =>
                    <button style={{color: i18n.resolvedLanguage === lng ? '#ffffff' : 'black', backgroundColor: i18n.resolvedLanguage === lng ? '#109CF1' : 'rgba(0,0,0,0)'}} className={s.authform__lang} type={'submit'} onClick={() => i18n.changeLanguage(lng)}>
                        {langs[lng].nativeName}
                    </button>
                )}
            </div>
        </div>
    );
};

export default AuthForm;
