import React, {useState} from 'react';
import s from './FullPerson.module.scss'
import Modal from "../../../UI/Modal/Modal";
import ChangePhoto from "../ChangePhoto/ChangePhoto";
import FullPersonPhotos from "./FullPersonPhotos/FullPersonPhotos";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import FullPersonChange from "./FullPersonChange/FullPersonChange";
import {useTranslation} from "react-i18next/hooks";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import PersonVisitsTable from "../PersonVisitsTable/PersonVisitsTable";
import {useGetPersonsVisitsQuery} from "../../../../Redux/APIs/otherAPI/personApi";
import FacesSearchAllVisits from "../../FacesSearch/FacesSearchAllVisits/FacesSearchAllVisits";

const FullPerson = ({person}) => {
    const [showChangePhoto, setShowChangePhoto] = useState(false)
    const [showChangePerson, setShowChangePerson] = useState(false)
    const [t,i18n] = useTranslation()
    const {data:visits,isLoading} = useGetPersonsVisitsQuery({id:person?.id})
    const [showAllVisits,setShowAllVisits] = useState(false)
    return (
        <div className={s.fullpersonbox}>
            <div className={s.fullperson}>
                <div className={s.fullperson__infobox}>
                    <FullPersonPhotos/>
                    <div className={s.fullperson__settings}>
                        <div className={s.fullperson__changePhoto} onClick={(e) => {
                            e.stopPropagation()
                            setShowChangePhoto(!showChangePhoto)
                        }}>
                            {t('persons.fullPerson.addPhoto')}
                        </div>

                        <div className={s.fullperson__changePerson} onClick={() => setShowChangePerson(true)}>
                            <FontAwesomeIcon icon={faPencil}/>
                        </div>
                    </div>
                    <div className={s.fullperson__changePhoto} onClick={() => setShowAllVisits(true)}>
                        {t('persons.fullPerson.showAllVisits')}
                    </div>
                    <div className={s.fullperson__title}>
                        {t('default.information')}
                    </div>
                    <div className={s.fullperson__info}>
                        <div className={s.fullperson__infoTextBox}> {t('default.personName')}:  <span className={s.fullperson__infoText}> {person?.fio} </span></div>
                        <div className={s.fullperson__infoTextBox}> {t('default.age')}:  <span className={s.fullperson__infoText}> {person?.bd.age} </span></div>
                        <div className={s.fullperson__infoTextBox}> {t('default.gender')}:  <span className={s.fullperson__infoText}> {person?.bd.gender === '-' ? '-': t(`default.${person?.bd.gender.toLowerCase()}`)}</span></div>
                        {person?.create_date
                            &&
                            <div className={s.fullperson__infoTextBox}> {t('persons.persons.table.firstVisit')}:  <span className={s.fullperson__infoText}> {getTimeAndDate('date', person?.create_date)} в {getTimeAndDate('time', person?.create_date)} </span></div>

                        }
                        {person?.lv
                            &&
                            <div className={s.fullperson__infoTextBox}> {t('persons.persons.table.lastVisit')}:  <span className={s.fullperson__infoText}> {getTimeAndDate('date', person?.lv)} в {getTimeAndDate('time', person?.lv)} </span></div>
                        }
                    </div>
                </div>
                {showChangePhoto
                    &&
                    <Modal showModal={showChangePhoto} setShowModal={setShowChangePhoto} >
                        <ChangePhoto setShowModal={setShowChangePhoto} id={person.id}/>
                    </Modal>
                }
                {showChangePerson
                    &&
                    <Modal showModal={showChangePerson} setShowModal={setShowChangePerson} >
                        <FullPersonChange/>
                    </Modal>
                }
            </div>
            {!isLoading
                &&
                <PersonVisitsTable person={person} visits={visits}/>
            }
            {showAllVisits
                &&
                <Modal setShowModal={setShowAllVisits}>
                    <FacesSearchAllVisits person={person?.id}/>
                </Modal>

            }
        </div>

    );
};

export default FullPerson;
