import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import setTimeToZero from "../../utils/timezoning";
import setTimeToUTC from "../../utils/timeUTCer";
import arrayOb from "../../utils/arrayOb";
import visitorsRefactor from "../../utils/VisitorsRefactor";
import VisitorsIsArrayNull from "../../utils/VisitorsIsArrayNull";
import VisitorsMoreDayRefactor from "../../utils/VisitorsMoreDayRefactor";
import getDayStartTime from "../../utils/getDayStartTime";
import getDayEndTime from "../../utils/getDayEndTime";


export const fetchVisitors = createAsyncThunk(
    'reports/fetchVisitors',
    async function(state,{rejectWithValue}){
        console.log(state)
        try{
            const res = await axios.get('/api/counter/visitors',{
                headers:{
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    loc_code: state.currentLocation,
                    dt_start: state.firstDate,
                    dt_end: state.secondDate
                }
            })
            const data = await res.data.data
            const visitors = await visitorsRefactor(state.firstDate, data)
            return visitors

        }catch (err){
            return rejectWithValue(err.response.data.errors.code)
        }

    }
)

export const fetchVisitorsMoreDay = createAsyncThunk(
    'reports/fetchVisitorsMoreDay',
    async function(state,{rejectWithValue}){
        console.log(state)
        try{
            const res = await axios.get('/api/counter/visitors',{
                headers:{
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    loc_code: state.currentLocation,
                    dt_start: state.firstDate,
                    dt_end: state.secondDate,
                }
            })
            const data = await res.data
            return VisitorsMoreDayRefactor(data.data,state.firstDate,state.secondDate)

        }catch (err){
            return rejectWithValue(err.response.data.errors.code)
        }

    }
)


const initialState = {
    visitors:[],
    traffic:[],
    visitorsMini:[],
    visitorsCount:0,
    mostActiveTime:'',
    reportDateFirst:getDayStartTime(Date()),
    reportDateSecond:getDayEndTime(Date()),
    chartStyle:'area',
    currentCamera: localStorage.getItem('visitorsReportCamera') || '',
    currentLocation:localStorage.getItem('visitorsReportLocation') || '',
    currentCameraName:'',
    isLoadingMini:false,
    isLoading:false,
    isError:false,
    errorCode:'',

}

export const visitorsReportSlice = createSlice({
    name: 'visitorsReport',
    initialState,
    reducers: {
        setCurrentCamera: (state,action) => {
            state.currentCamera = action.payload
        },
        changeReportDate : (state,action) => {
            state.reportDateFirst = action.payload.firstDate
            state.reportDateSecond = action.payload.secondDate
        },
        setStyle: (state,action) => {
            state.chartStyle = action.payload

        },
        setCurrentCameraName: (state,action) => {
            state.currentCameraName = action.payload
        },
        setCurrentLocation: (state,action) => {
            state.currentLocation = action.payload
            localStorage.setItem('visitorsReportLocation', action.payload)
        }


    },
    extraReducers: (builder) => {
        builder.addCase(fetchVisitors.pending, (state, action) => {
            state.isLoading = true

        })
        builder.addCase(fetchVisitors.fulfilled, (state, action) => {
            state.visitors = VisitorsIsArrayNull(action.payload)
            if(action.payload.length > 0){
                let maxVisitors = action.payload.reduce((acc, curr) => acc.numVisitors > curr.numVisitors ? acc : curr)
                let maxTraffic = action.payload.reduce((acc, curr) => acc.numTraffic > curr.numTraffic ? acc : curr)
                state.mostActiveTime = {visitors:maxVisitors.time, traffic: maxTraffic.time}
                let sumVisitors = 0
                let sumTraffic = 0
                action.payload.forEach(elem => {
                    sumVisitors += elem.numVisitors
                    sumTraffic += elem.numTraffic
                })
                state.visitorsCount = {visitors:sumVisitors, traffic:sumTraffic}
            }else{
                state.visitorsCount = {visitors:0, traffic:0}
                state.mostActiveTime = {visitors:'-', traffic:'-'}
            }
            state.isLoading = false
            state.isError = false
        })
        builder.addCase(fetchVisitors.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorCode = action.payload
        })

        builder.addCase(fetchVisitorsMoreDay.pending, (state, action) => {
            state.isLoading = true

        })
        builder.addCase(fetchVisitorsMoreDay.fulfilled, (state, action) => {
            state.visitors = action.payload
            console.log(action.payload)
            let maxVisitors = action.payload.reduce((acc, curr) => acc.numVisitors > curr.numVisitors ? acc : curr)
            let maxTraffic = action.payload.reduce((acc, curr) => acc.numTraffic > curr.numTraffic ? acc : curr)
            state.mostActiveTime = {visitors:maxVisitors.time, traffic: maxTraffic.time}
            let sumVisitors = 0
            let sumTraffic = 0
            action.payload.forEach(elem => {
                sumVisitors += elem.numVisitors
                sumTraffic += elem.numTraffic
            })
            if(sumVisitors === 0 && sumTraffic === 0){
                state.mostActiveTime = {visitors:'-', traffic: '-'}
            }
            state.visitorsCount = {visitors:sumVisitors, traffic:sumTraffic}
            state.isLoading = false
            state.isError = false
        })
        builder.addCase(fetchVisitorsMoreDay.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorCode = action.payload
        })
    },
})


export const {setCurrentCamera,changeReportDate,setStyle,setCurrentCameraName,setCurrentLocation} = visitorsReportSlice.actions

export default visitorsReportSlice.reducer
