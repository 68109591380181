import React from 'react';
import s from "../Tooltip/Tooltip.module.scss";

const AgeTooltip = ({ active, payload, label }) => {
    const linesCreate = () => {
        const list = []
        for(let i in payload[0]?.payload){
            if(i !== 'date'){
                list.push({age:i,num:payload[0]?.payload[i]})
            }
        }
        const sortedList = list?.length > 0 ? list.sort((x,y) => (+x.age.split('-')[0]) - (+y.age.split('-')[0])) : list
        return sortedList
    }
    linesCreate()
    return (
        <div className={s.tooltip}>
            <div className={s.tooltip__title}>
                {payload[0]?.payload.date}
            </div>
            {linesCreate().map((elem,id) =>
                <p>{elem.age}: {elem.num}</p>
            )

            }
        </div>
    );
};

export default AgeTooltip;
