import React from 'react';
import s from './GreenButton.module.scss'

const GreenButton = (props) => {
    return (
        <>
            <button
                style={{width:props.width,paddingLeft:props.padding || 40, paddingRight:props.padding || 40}}
                {...props}
                className={props.bleach ? s.button__bleach : props.light ? s.button__light : s.button}>
                {props.children}
            </button>
        </>
    );
};

export default GreenButton;