import React, {useState} from 'react';
import s from './FacesSearchToolbar.module.scss'
import GreenButton from "../../../UI/GreenButton/GreenButton";
import Modal from "../../../UI/Modal/Modal";
import FacesSearchFacePopup from "../FacesSearchFacePopup/FacesSearchFacePopup";
import {usePostSearchMutation} from "../../../../Redux/APIs/otherAPI/facesSearchApi";
import {useTranslation} from "react-i18next/hooks";

const FacesSearchToolbar = () => {
    const [show,setShow] = useState(false)
    const [postSearch,res] = usePostSearchMutation()
    const sendSearch = (photo,loc) => {
        postSearch({location:loc,photo:photo,ftype:'jpg'})
        setShow(false)
    }
    const [t,i18n] = useTranslation()
    return (
        <div className={s.toolbar}>
            <GreenButton onClick={() => setShow(!show)}>
                {t('search.searchButton')}
            </GreenButton>
            {show
                &&
                <Modal setShowModal={setShow} showModal={show}>
                    <FacesSearchFacePopup sendSearch={sendSearch} setShow={setShow}/>
                </Modal>
            }
        </div>
    );
};

export default FacesSearchToolbar;