import React, {useEffect} from 'react';
import s from './HistoryToolbar.module.scss'
import DateCalend from "../../../ViewSettings/DateCalend/DateCalend";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterGroup,
    changeHistoryDate,
    setCurrentCamera,
    setCurrentCameraName
} from "../../../../Redux/Slices/facesHistorySlice";
import CameraSelect from "../../../UI/CameraSelect/CameraSelect";
import HistoryGroupSelect from "../HistoryGroupSelect/HistoryGroupSelect";

const HistoryToolbar = () => {
    const dispatch = useDispatch()
    const historyDate = useSelector(state => state.facesHistory.historyDate)
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const currentCameraName = useSelector(state => state.facesHistory.currentCameraName)
    const camsList = useSelector(state => state.auth.currentUser.cameras)
    const changeDate = (date) => {
        dispatch(changeHistoryDate(date))
    }
    const changeCamera = (camera) => {
        localStorage.setItem('historyCamera', camera)
        dispatch(setCurrentCamera(camera))

    }
    const changeGroup = (group) => {
        dispatch(changeFilterGroup(group))
    }
    useEffect(() => {
        if(currentCamera){
            const name = camsList.find(cam => cam?.id === currentCamera)?.name
            dispatch(setCurrentCameraName(name))
        }
    },[currentCamera])

    return (
        <div className={s.toolbar}>
            {camsList !== null
                &&
                <CameraSelect currentCameraName={currentCameraName} list={camsList} changeCamera={changeCamera} currentCamera={currentCamera}/>
            }
            <DateCalend timeSpan={true} changeDate={changeDate} date={historyDate}/>
            {/*<HistoryGroupSelect changeGroup={changeGroup}/>*/}
        </div>
    );
};

export default HistoryToolbar;