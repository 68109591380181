const createTableBody = (history) => {
    const newHistory = history.map(elem => {
        const newElem = {...elem}
        const historyObj = {}
        for(let i in newElem.history){
            historyObj[`${newElem.history[i].date}`] = newElem.history[i].history.length === 0 ? '' : `${newElem.history[i].history.length}`
        }
        const result = {...newElem,...historyObj}
        delete result.personImg
        delete result.history
        return result
    })
    return newHistory
}
export default createTableBody;