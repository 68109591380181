import React, {useEffect} from 'react';
import s from './Modal.module.css'

const Modal = ({showModal,setShowModal,children, ...props}) => {

    // возможно нужно поменять
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
    }, [showModal]);

    return (
        <>
                <div className={s.modal} {...props} onClick={() => setShowModal(false)} >
                    <div className={s.modal__content} onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </div>

        </>

    );
};

export default Modal;
