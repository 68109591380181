import React, {useState} from 'react';
import s from "../CameraPlayer/CameraPlayer.module.css";
import {clearSlider, setFullscreen, setSliderMode, stopSlider} from "../../../Redux/Slices/camEventsSlidesSlide";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faMaximize, faPause, faPlay, faRepeat, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";

const CamSliderSettings = (props) => {
    const dispatch = useDispatch()
    const isSliderPlay = useSelector(state => state.camSlides.isSliderPlay)
    const speed = useSelector(state => state.camSlides.speed)
    const {fetchNewDate,setSpeedd,fullscreen,nextSlide} = {...props}
    const camSlides = useSelector(state => state.camSlides.camSlides)
    const sliderMode = useSelector(state => state.camSlides.sliderMode)
    const sliderPos = useSelector(state => state.camSlides.controlSliderPos)
    return (
        <div style={{bottom:`${fullscreen ? 20 : 10}px`}} className={fullscreen === true ? s.mainframe__controlsFS : s.mainframe__controls}>
            <div className={s.mainframe__controlsControls}>

                <div className={fullscreen === true ? s.cameraplayer__playButtonFS : s.cameraplayer__playButton}
                     onClick={() => {
                     fetchNewDate()
                }} >
                    <span style={{color:'#c4c8cc', fontSize:'11px'}}>
                        <FontAwesomeIcon icon={faRotateRight} size={'lg'}/>
                    </span>
                </div>

                <div className={fullscreen === true ? s.cameraplayer__playButtonFS : s.cameraplayer__playButton}
                     onClick={() => {
                    setSpeedd()
                }} >
                    <span>x{speed}</span>
                </div>

                <div style={{width:'40px',height:'40px'}}
                     className={fullscreen === true ? s.cameraplayer__playButtonFS : s.cameraplayer__playButton}
                     onClick={() => dispatch(stopSlider(!isSliderPlay))} >
                    {isSliderPlay === true
                        ?
                        <FontAwesomeIcon icon={faPause}/>
                        :
                        <FontAwesomeIcon icon={faPlay}/>
                    }

                </div>

                <div className={fullscreen === true ? s.cameraplayer__playButtonFS : s.cameraplayer__playButton}
                    onClick={() => {
                    nextSlide(camSlides.slice(-1)[0].id + 1)
                }}>
                    <FontAwesomeIcon icon={faPlay} width={8} />
                    <FontAwesomeIcon icon={faPlay} width={8} />
                </div>

                <div className={fullscreen === true ? s.cameraplayer__playButtonFS : s.cameraplayer__playButton}
                    onClick={() => {
                    dispatch(setFullscreen({
                        value:!fullscreen,
                        sliderPos:sliderPos
                    }))
                    dispatch(stopSlider(false))
                    dispatch(clearSlider())
                }} >
                    <FontAwesomeIcon icon={faMaximize} width={15} />
                </div>
                <div className={fullscreen === true ? s.cameraplayer__playButtonFS : s.cameraplayer__playButton}
                     onClick={() => {
                        dispatch(setSliderMode(sliderMode === 'repeat' ? 'next' : 'repeat'))
                     }} >
                    <FontAwesomeIcon icon={sliderMode === 'repeat' ? faRepeat : faArrowRight} width={15} />
                </div>
            </div>
        </div>

    );
};

export default CamSliderSettings;