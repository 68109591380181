import React, { useState} from 'react';
import s from './PersonsToolbar.module.css'
import GreenButton from "../../../UI/GreenButton/GreenButton";
import RoundButton from "../../../UI/RoundButton/RoundButton";
import JustInput from "../../../UI/JustInput/JustInput";
import {useTranslation} from "react-i18next/hooks";
const PersonsToolbar = (props) => {
    const [value,setValue] = useState('')
    const [t,i18n] = useTranslation()

    return (
        <div className={s.toolbar__toolbox}>
            {/*<div className={s.toolbar__filters}>*/}
            {/*    <JustInput placeholder={t('default.name')} value={value} onChange={(e) => {*/}
            {/*        setValue(e.target.value)*/}
            {/*    }} width={200} />*/}
            {/*</div>*/}
            <div className={s.toolbar__buttons}>
                <GreenButton onClick={() => props.setModalList(!props.modalList)}>
                    {t('persons.persons.addList')}
                </GreenButton>
            </div>
            <RoundButton onClick={() => props.setModalList(!props.modalList)} />
        </div>
    );
};

export default PersonsToolbar;
