import React from 'react';
import s from './MonthTableNotFound.module.scss'
const MonthTableNotFound = () => {
    return (
        <div className={s.banner}>
            Нет данных для построения таблицы
        </div>
    );
};

export default MonthTableNotFound;