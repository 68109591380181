import React from 'react';
import s from './Tooltip.module.scss'
import {useTranslation} from "react-i18next/hooks";
const MyTooltip = ({ active, payload, label }) => {
    const [t,i18n] = useTranslation()
    return (
        <div className={s.tooltip}>
            <div className={s.tooltip__title}>
                {payload[0]?.payload.date}
            </div>
            <p>{t('personReport.charts.male')}: {payload[0]?.payload.Male}</p>
            <p>{t('personReport.charts.female')}: {payload[0]?.payload.Female}</p>
        </div>
    );
};

export default MyTooltip;
