import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import {useNavigate} from "react-router-dom";


export const fetchAuth = createAsyncThunk(
    'users/fetchByIdStatus',
    async function(state){
        const res = await axios.post('/api/auth', {
            login: state.email,
            password: state.password
        })
        const data = await res.data
        return {access_token: data.data.access_token, token_type:data.data.token_type}
    }
)
export const fetchUser = createAsyncThunk(
    'users/fetchUser',
    async function(state){
        const res = await axios.get('/api/account',{
            headers:{
                "Authorization": localStorage.getItem('token')
            }
        })
        const data = await res.data.data
        return data
    }
)


const initialState = {
    value: 0,
    isAuth:false,
    currentUser:null,
    isHaveCameras:false,
    isInitialLoading:true,
    isPasswordWrong:false,
    logMode:'login',
    isLoading:false,
    isError:false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout:(state) => {
            localStorage.removeItem('token')
            localStorage.clear()
            state.isAuth = false
            state.currentUser = null
        },
        rejectWrongMessage:(state) => {
            state.isPasswordWrong = false
        },
        setLogMode: (state,action) => {
            state.logMode = action.payload
        }



    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuth.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchAuth.fulfilled, (state, action) => {
            localStorage.setItem('token', action.payload.token_type + ' ' + action.payload.access_token)
            state.isLoading = false
            state.isAuth = true
        })
        builder.addCase(fetchAuth.rejected, (state, action) => {
            state.isPasswordWrong = true
            state.isLoading = false
        })

        builder.addCase(fetchUser.pending, (state, action) => {
            state.isLoading = true
            state.isInitialLoading = true
        })
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            const user = action.payload.user
            console.log(user)
            state.currentUser = user
            if(user.cameras.length !== 0){
                state.isHaveCameras = true
            }else if(user.cameras.length === 0){
                state.isHaveCameras = false
            }
            state.isAuth = true
            state.isLoading = false
            state.isInitialLoading = false
        })
        builder.addCase(fetchUser.rejected, (state,action) => {
            localStorage.removeItem('token')
            state.isAuth = false
            state.currentUser = null
            state.isHaveCameras = false
            state.isLoading = false
            state.isInitialLoading = false
        })



    },
})


export const {logout,rejectWrongMessage,setLogMode} = authSlice.actions

export default authSlice.reducer
