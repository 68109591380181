import React, {useEffect} from 'react';
import CameraSelect from "../../UI/CameraSelect/CameraSelect";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCamera} from "../../../Redux/Slices/camSlice";
import {useNavigate, useParams} from "react-router-dom";
import DateCalend from "../../ViewSettings/DateCalend/DateCalend";
import {
    changeDate,
    fetchCamSecondEvents,
    setCurrentCameraName,
    setProgressReverted
} from "../../../Redux/Slices/camEventsSlice";
import s from './CamViewToolbar.module.css'
import cams from "../../../pages/Cams/Cams";
import getDayStartTime from "../../../utils/getDayStartTime";

const CamViewToolbar = () => {
    const camsList = useSelector(state => state.auth.currentUser.cameras)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentCamera = useParams().id
    const reverted = useSelector(state => state.camEvents.reverted)
    const currentCameraName = useSelector(state => state.camEvents.currentCameraName)
    const currentDate = useSelector(state => state.camEvents.date)
    const changeCameraa = (camera) => {
        dispatch(setCurrentCamera(camera))
        navigate(`/cams/${camera}`)
    }
    const fetchDate = (datt) => {
        const date = new Date(datt.datStart)
        const day = reverted === false ? 1 : -1
        date.setDate(date.getDate() + day)
        dispatch(changeDate(datt.datStart))
        dispatch(fetchCamSecondEvents({currentCamera:currentCamera, neededDate:getDayStartTime(date)}))
        dispatch(setProgressReverted([]))
    }
    useEffect(() => {
        if(currentCamera && camsList.length > 0){
            const name = camsList.find(cam => cam.id === currentCamera)
            if(name){
                dispatch(setCurrentCameraName(name.name))
            }
        }
    },[currentCamera])

    return (
        <div className={s.toolbar}>
            <CameraSelect currentCameraName={currentCameraName} changeCamera={changeCameraa} currentCamera={currentCamera} />
            <DateCalend date={{datStart:currentDate,datEnd:null}} changeDate={fetchDate} />
        </div>
    );
};

export default CamViewToolbar;