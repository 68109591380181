const visitorsIsArrayNull = (arr) => {
    if(arr.length > 0){
        return arr

    }else if(arr.length === 0) {
        return []
    }
}
export default visitorsIsArrayNull;
// {num: 0, time: '0:00'},
// {num: 0, time: '4:00'},
// {num: 0, time: '8:00'},
// {num: 0, time: '12:00'},
// {num: 0, time: '16:00'},
// {num: 0, time: '20:00'},
