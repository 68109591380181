import React from 'react';
import s from './HistoryFacesList.module.scss'
import HistoryFaceCard from "../HistoryFaceCard/HistoryFaceCard";
import {useSelector} from "react-redux";
const HistoryFacesList = () => {
    const history = useSelector(state => state.facesHistory.filteredHistory)
    return (
        <div className={s.listbox}>
            <div className={s.list}>
                {history.map((elem,id) =>
                    <div key={id} className={s.list}>
                        <div className={s.listbox__time}>
                            <span>
                                {elem.time}
                            </span>
                        </div>
                        <div className={s.list__list}>
                            {elem.history.map((hist,id) =>
                                <HistoryFaceCard key={id} elem={hist}/>
                            )}
                        </div>
                    </div>

                )}
            </div>
        </div>

    );
};

export default HistoryFacesList;