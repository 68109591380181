import React from 'react';
import s from './ReportsPerson.module.scss'
import Toolbar from "../../../components/toolbar/Toolbar";
import PersonReports from "../../../components/PersonReports/PersonReports";
import PersonReportsToolbar from "../../../components/PersonReports/PersonReportsToolbar/PersonReportsToolbar";
const ReportsPerson = () => {

    return (
        <div className={s.reportspersonbox}>
            <Toolbar>
                <PersonReportsToolbar/>
            </Toolbar>
            <PersonReports/>
        </div>
    );
};

export default ReportsPerson;
