import getTimeAndDate from "./getTimeAndDate";

const historyForDateSlicer = (list) => {
    if(list.length <= 0){
        return []
    }
    let prewVal = list[0].dt
    const sortedHistoryTimes = [
        {time:`${getTimeAndDate('date',list[0].dt)}` }
    ]
    for(let i = 0; i < list.length; i++){
        if(getTimeAndDate('date', list[i].dt) !== getTimeAndDate('date',prewVal)){
            sortedHistoryTimes.push({time:`${getTimeAndDate('date',list[i].dt)}` })
            prewVal = list[i].dt
        }
    }
    for(let i = 0; i < sortedHistoryTimes.length; i++){
        sortedHistoryTimes[i] = {...sortedHistoryTimes[i], history: list.filter(elem => `${getTimeAndDate('date',elem.dt)}` === sortedHistoryTimes[i].time)}
    }
    return sortedHistoryTimes
}
export default historyForDateSlicer;