import {configureStore,combineReducers} from "@reduxjs/toolkit";
import authReducer from "./Slices/authSlice";
import sidebarReducer from "./Slices/sidebarSlice";
import locationReducer from "./Slices/locationSlice"
import camReducer from "./Slices/camSlice"
import personReducer from "./Slices/personSlice";
import eventsReducer from "./Slices/eventsSlice";
import eventsSlidesReducer from "./Slices/eventsSlidesSlice";
import camEventsReducer from "./Slices/camEventsSlice";
import camEventsSlidesReducer from './Slices/camEventsSlidesSlide'
import camControlReducer from "./Slices/camControlSlice";
import visitorsReportReducer from "./Slices/VisitorsSlice";
import onePersonReducer from "./Slices/onePersonSlice";
import facesHistoryReducer from "./Slices/facesHistorySlice";
import historyOneFaceReducer from "./Slices/HistoryOneFaceSlice";
import rawdataReducer from './Slices/rawdataSlice'
import personReportsReducer from "./Slices/personReportSlice";
import visitsHistoryReportReducer from "./Slices/visitsHistoryReportSlice";
import facesSearchReducer from "./Slices/facesSearchSlice";
import {mainApi} from "./APIs/testAPI";

const combinedReducer = combineReducers({
    auth: authReducer,
    sidebar:sidebarReducer,
    location:locationReducer,
    camera:camReducer,
    persons:personReducer,
    events:eventsReducer,
    camEvents:camEventsReducer,
    eventsSlides:eventsSlidesReducer,
    camSlides:camEventsSlidesReducer,
    camControl:camControlReducer,
    visitorsReport:visitorsReportReducer,
    onePerson:onePersonReducer,
    facesHistory:facesHistoryReducer,
    historyOneFace:historyOneFaceReducer,
    personReports:personReportsReducer,
    visitsHistoryReport:visitsHistoryReportReducer,
    facesSearch:facesSearchReducer,
    rawdata:rawdataReducer,
    [mainApi.reducerPath]: mainApi.reducer,
});
const rootReducer = (state, action) => {
    if (action.type === 'auth/logout') {
        state = undefined;
    }
    return combinedReducer(state, action);
};
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: {
            ignoredPaths: ['visitsHistoryReport']
        },
        serializableCheck: { ignoredPaths: ['visitsHistoryReport'] }
    }).concat(mainApi.middleware)
})
