import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import getDayEndTime from "../../utils/getDayEndTime";
import getDayStartTime from "../../utils/getDayStartTime";
import objectTimesToArrayOfObjects from "../../utils/objectTimesToArrayOfObjects";

export const fetchPersonAttendance = createAsyncThunk(
    'personReports/fetchPersonAttendance',
    async function(state,{rejectWithValue}) {
        try {
            const res = await axios.get(`/api/counter/attendance`, {
                headers: {
                    "Authorization": localStorage.getItem('token')
                },
                params: {
                    person:state.person,
                    dt_start:state.datStart,
                    dt_end:state.datEnd
                }
            })
            const data = await res.data.data
            return data
        } catch (err) {
            return rejectWithValue(err.response.data.errors.status)
        }
    }
)
const initialState = {
    attendance:[],
    firstVisit:0,
    secondVisit:0,
    attendanceIsLoading:false,
    attendanceIsError:false,
    attendanceDate:{datStart:getDayStartTime(Date()), datEnd:getDayEndTime(Date())},
    isError:false,
    errorCode:null,
}

export const personReportsSlice = createSlice({
    name: 'personReports',
    initialState,
    reducers: {
        changeAttendanceDate : (state,action) => {
            state.attendanceDate = {datStart: action.payload.datStart,datEnd:action.payload.datEnd}
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPersonAttendance.pending, (state, action) => {
            state.attendanceIsLoading = true
        })
        builder.addCase(fetchPersonAttendance.fulfilled, (state, action) => {
            state.attendance = objectTimesToArrayOfObjects(action.payload.hourly_sum, action.payload.attendance)
            state.attendanceIsLoading = false
        })
        builder.addCase(fetchPersonAttendance.rejected, (state, action) => {
            state.attendanceIsLoading = false
            state.attendanceIsError = true
        })
    },

})


export const {changeAttendanceDate} = personReportsSlice.actions

export default personReportsSlice.reducer