import React, {useEffect, useState} from 'react';
import s from "../VisitsHistoryMonthTable.module.scss";
import {PROXY} from "../../../../utils/pathss";
import MonthTableCell from "../MonthTableCell/MonthTableCell";
import {useSelector} from "react-redux";
import Modal from "../../../UI/Modal/Modal";
import FacesSearchAllVisits from "../../../Faces/FacesSearch/FacesSearchAllVisits/FacesSearchAllVisits";

const MonthTableBody = () => {
    const visitsHistory = useSelector(state => state.visitsHistoryReport.visitsHistorySorted)
    const [pagination,setPagination] = useState(1)
    const [popupPersonId, setPopupPerosnId] = useState(null)
    const [showPersonPopup,setShowPersonPopup] = useState(false)
    useEffect(() => {
        document.addEventListener('scroll',handleScroll)
        return () => document.removeEventListener('scroll',handleScroll)
    },[])

    const handleScroll = () => {
        if(document.documentElement.clientHeight + window.scrollY >= document.documentElement.offsetHeight){
            setPagination(pagination => pagination+1)

        }
    }
    const openPersonPopup = (id) => {
        setPopupPerosnId(id)
        setShowPersonPopup(true)
    }
    return (
        <tbody>
            {visitsHistory.slice(0,(10*pagination)).map(row =>
                <tr key={row.person_id} className={s.table__row}>
                    <td className={s.table__cellImg} onClick={() => openPersonPopup(row?.person_id)}>
                        <img className={s.table__image} src={`${PROXY}api/${row.personImg}`} alt=""/>
                    </td>
                    <td className={s.table__cellID}>
                        {row.person_id}
                    </td>
                    <td className={s.table__cell}>
                        {row.personGender}
                    </td>
                    <td className={s.table__cell}>
                        {row.personAge}
                    </td>
                    <td className={s.table__cellTotal}>
                        {row.totalVisits}
                    </td>
                    {row.history.map((cell,id) =>
                        <MonthTableCell cell={cell} key={id} />
                    )}
                </tr>
            )}
            {showPersonPopup
                &&
                <Modal setShowModal={setShowPersonPopup} showModal={showPersonPopup}>
                    <FacesSearchAllVisits person={popupPersonId}/>
                </Modal>
            }
        </tbody>
    );
};

export default MonthTableBody;