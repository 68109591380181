import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";


export const fetchRawdata = createAsyncThunk(
    'rawdata/fetchRawdata',
    async function(state,{rejectWithValue}){

        try{
            const res = await axios.get(`/api/rawdata/list/${state.id}`,{
                headers:{
                    "Authorization": localStorage.getItem('token')
                }
            })
            const data = await res.data.data
            return data
        }catch (err){
            return rejectWithValue(err.response.data)
        }

    }
)



const initialState = {
    rawdata:[],
    id:1,
    currentElem:null,
    isLoading:false,
    isError:false,
    errorCode:'',

}

export const rawdataSlice = createSlice({
    name: 'rawdata',
    initialState,
    reducers: {
        setCurrentElem: (state,action) => {
            state.currentElem = action.payload
        },
        setId:(state,action) => {
          state.id = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRawdata.pending, (state, action) => {
            state.isLoading = true

        })
        builder.addCase(fetchRawdata.fulfilled, (state, action) => {
            state.rawdata = action.payload
            state.isLoading = false
            state.isError = false
        })
        builder.addCase(fetchRawdata.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
        })
    },
})


export const {setCurrentElem,setId} = rawdataSlice.actions

export default rawdataSlice.reducer