import React, {useState} from 'react';
import s from './PersonsGroupInfo.module.scss'
import JustCheckbox from "../../../UI/JustCheckbox/JustCheckbox";
import {useDispatch, useSelector} from "react-redux";
import {setIsFreq} from "../../../../Redux/Slices/personSlice";
import {accentColor} from "../../../../utils/styles";
import { Tooltip } from 'react-tooltip'
import JustTooltip from "../../../UI/JustTooltip/JustTooltip";
const PersonsGroupInfo = ({totalNum,totalNumFreq}) => {
    const dispatch = useDispatch()
    const isFreq = useSelector(state => state.persons.isFreq)
    const onChangeCheckBox = (val) => {
        dispatch(setIsFreq(val))

    }
    return (
        <div className={s.personsGroupInfo}>
            <JustTooltip id={'my-tooltip'}/>
            {totalNumFreq
                &&
                <div className={s.personsGroupInfo__text}>Постоянных посетителей: <span style={{color:accentColor}}>{totalNumFreq}</span></div>
            }
            <div className={s.personsGroupInfo__text}>Всего персон: <span style={{color:accentColor}}>{totalNum}</span></div>
            <div
                data-tooltip-id="my-tooltip" data-tooltip-content={isFreq ? "Более 2 посещений в разные дни" : 'Все посетители'}
                className={isFreq ? s.personsGroupInfo__button : s.personsGroupInfo__buttonActive}
                onClick={() => onChangeCheckBox(!isFreq)}>
                {!isFreq ? 'Все' : 'Постоянные'

                }
            </div>
        </div>
    );
};

export default PersonsGroupInfo;