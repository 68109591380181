import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import arraySlicer from "../../utils/arraySlicer";
import getDayStartTime from "../../utils/getDayStartTime";

export const fetchEventsPaginationForDate = createAsyncThunk(
    'users/fetchEventsPaginationForDate',
    async function(state,{rejectWithValue}){
        try{
            const res = await axios.get('/api/sequences',{
                headers:{
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    cam_code: state.currentCamera,
                    dt_start: state.neededDate.datStart,
                    ts_len: 86400,
                }
            })
            const array = await res.data.data;
            return arraySlicer(array,15)
        }catch (err){
            return rejectWithValue(err.response.data.errors.code)
        }

    }
)



const initialState = {
    eventsPagination:[],
    currentPage:0,
    currentCamera: localStorage.getItem('eventsCamera') || '',
    currentCameraName:'',
    eventsDate:{datStart: getDayStartTime(Date()), datEnd:null},
    isLoading:true,
    isError:false,
    errorCode:''
}

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        changeEventsDate : (state,action) => {
            state.eventsDate = action.payload
            state.currentPage = 0
        },
        goPagination: (state,action) => {
            state.currentPage = state.currentPage + 1
        },
        backPagination: (state,action) => {
            state.currentPage -= 1
        },
        setPagination: (state,action) => {
            state.currentPage = action.payload
        },
        setCurrentCamera: (state,action) => {
            state.currentCamera = action.payload
        },
        setCurrentCameraName: (state, action) => {
            state.currentCameraName = action.payload
        }


    },
    extraReducers: (builder) => {

        builder.addCase(fetchEventsPaginationForDate.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
        })
        builder.addCase(fetchEventsPaginationForDate.fulfilled, (state, action) => {
            state.eventsPagination = action.payload
            state.isError = false
            state.isLoading = false

        })
        builder.addCase(fetchEventsPaginationForDate.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorCode = action.payload
        })

    },
})

export const {goPagination,backPagination,setPagination,changeEventsDate,setCurrentCamera,setCurrentCameraName} = eventsSlice.actions
export default eventsSlice.reducer