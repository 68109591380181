import React, {useEffect, useRef, useState} from 'react';
import s from './VisitsHistoryMonthTable.module.scss'
import getMonthName from "../../../utils/getMonthName";
import {PROXY} from "../../../utils/pathss";
import MonthTableCell from "./MonthTableCell/MonthTableCell";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next/hooks";
import MonthTableGenderPopup from "./MonthTableGenderPopup/MonthTableGenderPopup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import {
    fetchVisitsHistory, sortVisitsHistoryDay, sortVisitsHistoryGender, sortVisitsHistoryMinVisits,
    sortVisitsHistoryMonth,
    sortVisitsHistorySum
} from "../../../Redux/Slices/visitsHistoryReportSlice";
import MonthTableHead from "./MonthTableHead/MonthTableHead";
import MonthTableBody from "./MonthTableBody/MonthTableBody";
const VisitsHistoryMonthTable = ({boxRef}) => {
    const [tablePos,setTablePos] = useState(0)

    const tableRef = useRef(null)
    const rangeHandler = (e) => {
        if(+e === 1){
            setTablePos(0)
        }else{
            setTablePos(((tableRef.current.clientWidth - boxRef.current.clientWidth)/10)*e + 40)
        }
    }

    return (
        <>
            <input defaultValue={1} min={1} max={10} onChange={(e) => rangeHandler(e.target.value)} type="range"/>
            <div style={{marginLeft:`-${tablePos}px`}}>
                <table className={s.table} ref={tableRef}>
                    <MonthTableHead/>
                    <MonthTableBody/>
                </table>
            </div>
        </>
    );
};

export default VisitsHistoryMonthTable;
