const getTimeAndDate = (opt,dt) => {
    if(opt === 'time'){
        const date = new Date(dt)
        return `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`
    }else if(opt === 'date'){
        const date = new Date(dt)
        const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
        const month = (date.getMonth()+1) > 9 ? (date.getMonth()+1) : `0${date.getMonth()+1}`
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
    }
}
export default getTimeAndDate;