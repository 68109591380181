import React, {useState} from 'react';
import s from './ChangePhoto.module.css'
import ChangePhotoElem from "./ChangePhotoElem";
import GreenButton from "../../../UI/GreenButton/GreenButton";
import ModalBackCross from "../../../UI/Modal/ModalBackCross";
import InputFile from "../../../UI/InputFile/InputFile";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next/hooks";
import {usePostPersonPhotosMutation} from "../../../../Redux/APIs/otherAPI/onePersonApi";
import {changePhotos} from "../../../../Redux/Slices/onePersonSlice";
const ChangePhoto = (props) => {
    const [postPersonPhotos,res] = usePostPersonPhotosMutation()
    const dispatch = useDispatch()
    const sendPhotos = () => {
        postPersonPhotos({id:props.id,photos:photos,type:1})
        props.setShowModal(false)
        dispatch(changePhotos({opt:-1,photo:''}))
    }
    const photos = useSelector(state => state.onePerson.photos)

    const setPhotoss = (photo,opt) => {
        switch (opt){
            case t('persons.changePhoto.left'):
                dispatch(changePhotos({opt:4,photo:photo}))
                break
            case t('persons.changePhoto.fright'):
                dispatch(changePhotos({opt:3,photo:photo}))
                break
            case t('persons.changePhoto.fleft'):
                dispatch(changePhotos({opt:2,photo:photo}))
                break
            case t('persons.changePhoto.right'):
                dispatch(changePhotos({opt:5,photo:photo}))
                // setPhotos({...photos,right:photo})
                break
            case t('persons.changePhoto.profile'):
                dispatch(changePhotos({opt:1,photo:photo}))
                break
        }
    }
    const [t,i18n] = useTranslation()
    return (
        <div className={s.changePhoto}>
            <ModalBackCross setShowModal={props.setShowModal}/>
            <div className={s.changePhoto__title}>
                {t('persons.changePhoto.changePhoto')}
            </div>
            <div className={s.changePhoto__text}>
                {t('persons.changePhoto.photoRequirements')}
            </div>
            <div className={s.changePhoto__list}>
                <ChangePhotoElem setPhotos={setPhotoss} type={'right'} title={t('persons.changePhoto.right')}/>
                <ChangePhotoElem setPhotos={setPhotoss} type={'fright'} title={t('persons.changePhoto.fright')}/>

                <ChangePhotoElem setPhotos={setPhotoss} type={'profile'} title={t('persons.changePhoto.profile')}/>
                <ChangePhotoElem setPhotos={setPhotoss} type={'fleft'} title={t('persons.changePhoto.fleft')}/>

                <ChangePhotoElem setPhotos={setPhotoss} type={'left'} title={t('persons.changePhoto.left')}/>
            </div>
            <div className={s.changePhoto__buttons}>
                <GreenButton onClick={() => sendPhotos()}>
                    {t('default.send')}
                </GreenButton>
            </div>
        </div>
    );
};

export default ChangePhoto;
