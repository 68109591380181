import React, {useEffect, useState} from 'react';
import s from './CamControl.module.css'
import MiniButton from "../../UI/MiniButton/MiniButton";
import {useDispatch, useSelector} from "react-redux";
import CamControlLine from "./CamControlLine/CamControlLine";
import dayjs from "dayjs";
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CamControlSlider from "./CamControlWindow/CamControlSlider";
import {handleScrollButtons} from "../CameraPlayer/HandleScrollButtons/HandleScrollButtons";
import {setControlSliderPos, setStagePos} from "../../../Redux/Slices/camEventsSlidesSlide";

const CamControl = (props) => {

    const [stage, setStage] = useState({
        scale: 1,
        x: 0,
        y: 0
    });
    const neededDate = useSelector(state => state.camEvents.date)
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.camEvents.isLoading)
    const [timePopUp,setTimePopUp] = useState(false)
    const activeSequence = useSelector(state => state.camSlides.activeSequence)
    const reverted = useSelector(state => state.camEvents.reverted)
    const [scrollDepth,setScrollDepth] = useState(1)
    const sliderPos = useSelector(state => state.camSlides.controlSliderPos)
    const otn = props.width/86400
    const timeGap = props.width/12
    const bitTimeGap = props.width/6
    const controlDayWidth = props.width

    
    useEffect(() => {
        if(props.fullscreen){
            setStage({...stage,x:stage.x*2})
        }else if(!props.fullscreen){
            setStage({...stage,x:stage.x/2})
        }
        // setStage({...stage,x:0})

    },[props.fullscreen])

    const setSliderPos = (pos) => {
        dispatch(setControlSliderPos(pos))
    }

    const changeStage = (stage) => {
        dispatch(setStagePos(stage))
        setStage(stage)
    }

    const handleScrollButtonss = (opt) => {
        handleScrollButtons(opt,scrollDepth,bitTimeGap,timeGap,controlDayWidth,props,sliderPos,reverted,setSliderPos,neededDate,dispatch,stage,setStage)
    }
    
    const timeTextColor = '#8C8C8C'
    const timelineColot = '#FFFFFF'

    const getDate = (d,isSub) => {
        if(isSub === false){
            const date = new Date(neededDate == null ? dayjs() : neededDate)
            return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
        }else if(isSub === true){
            const date = new Date(neededDate == null ? dayjs() : neededDate)
            date.setDate(reverted === false ? date.getDate()+1 : date.getDate()-1)
            return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
        }

    }

    return (
        <div className={s.control} >
            <MiniButton pos={'left'} onClick={() => handleScrollButtonss('left')}> <FontAwesomeIcon icon={faArrowLeft} color={'#ffffff'} size={'1x'} /> </MiniButton>

            <div className={props.fullscreen ? s.control__windowFS : s.control__window} style={{width:`${props.width}px`}}>

                <div className={s.control__slider} style={{transform:`translateX(${sliderPos}px)`}}>
                    <CamControlSlider
                        stage={stage}
                        scrollDepth={scrollDepth}
                        reverted={reverted}
                        getDate={getDate}
                        neededDate={neededDate}
                        width={props.width}
                        sliderPos={sliderPos}
                    />
                    <CamControlLine
                                    setStage={setStage}
                                    setScrollDepth={setScrollDepth}
                                    otn={otn}
                                    stage={stage}
                                    setTimePopUp={setTimePopUp}
                                    activeSequence={activeSequence}
                                    frames={props.frames}
                                    secondFrames={props.secondFrames}
                                    scrollDepth={scrollDepth}
                                    timelineColot={timelineColot}
                                    timeTextColor={timeTextColor}
                                    timePopUp={timePopUp}
                                    fetchSlides={props.fetchSlides}
                                    isLoading={isLoading}
                                    reverted={reverted}
                                    neededDate={neededDate}
                                    width={props.width}
                                    hei={props.hei}
                    />
                </div>

            </div>
            <MiniButton pos={'right'} onClick={() => handleScrollButtonss('right')}>
                <FontAwesomeIcon icon={faArrowRight} color={'#FFFFFF'} size={'1x'} />
            </MiniButton>

        </div>
    );
};

export default CamControl;