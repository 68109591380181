import React, {useRef, useState} from 'react';
import s from './CameraSelect.module.css'
import {faVideoCamera} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "../Modal/Modal";
import {useSelector} from "react-redux";
import JustInput from "../JustInput/JustInput";
import {useTranslation} from "react-i18next/hooks";

const CameraSelect = (props) => {
    const [showModal,setShowModal] = useState(false)
    const [value,setValue] = useState('')
    const [t,i18n] = useTranslation()
    const camsList = useSelector(state => state.auth.currentUser.cameras).filter((elem) => elem.name.toLowerCase().includes(value.toLowerCase()))
    return (

        <div className={s.cameraselect}>
            <div
                className={s.cameraselect__select}
                onClick={() => setShowModal(true)}
            >
                <FontAwesomeIcon icon={faVideoCamera} alt="" className={s.cameraselect__icon}  />
                <span>
                    {props.currentCameraName || t('UI.cameraSelect.cameraSelect')}
                </span>
            </div>
            {showModal
                &&
                <Modal setShowModal={setShowModal}>
                    <div className={s.cameraselect__listbox}>
                        <div className={s.cameraselect__title}>
                            {t('UI.cameraSelect.cameraSelect')}
                        </div>
                        <JustInput placeholder={t('default.name')} value={value} onChange={(e) => setValue(e.target.value)} width={200} />
                        <div className={s.cameraselect__list}>
                            {camsList.map((elem,id) =>
                                <div className={s.cameraselect__elem} onClick={() => {
                                    props.changeCamera(elem.id)
                                    setShowModal(false)
                                }}>{elem.name}</div>
                            )

                            }
                        </div>
                    </div>
                </Modal>
            }


        </div>
    );
};

export default CameraSelect;
