import React, {useEffect} from 'react';
import s from './Events.module.css'
import Toolbar from "../../components/toolbar/Toolbar";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchEventsPaginationForDate, setCurrentCameraName
} from "../../Redux/Slices/eventsSlice";
import EventsToolbar from "./EventsToolbar/EventsToolbar";
import {logout} from "../../Redux/Slices/authSlice";
import EventsContent from "../../components/Events/EventsContent/EventsContent";
import CamsNotFound from "../../components/Cams/CamsNotFound/CamsNotFound";



const Events = () => {
    const dispatch = useDispatch()
    const eventsDate = useSelector(state => state.events.eventsDate)
    const errorCode = useSelector(state => state.events.errorCode)
    const currentUser = useSelector(state => state.auth.currentUser)
    const isHaveCameras = useSelector(state => state.auth.isHaveCameras)
    const currentCamera = useSelector(state => state.events.currentCamera)

    useEffect(() => {
        let cam = currentCamera !== '' ? currentCamera : (currentUser.cameras.length !== 0 && currentUser.cameras[0].id)
        if(isHaveCameras){
            dispatch(fetchEventsPaginationForDate({currentCamera: cam , neededDate: eventsDate}))
            dispatch(setCurrentCameraName( currentUser?.cameras?.filter(elem => elem.id === cam)[0]?.name ))
        }
    },[eventsDate,currentCamera])

    // релоад при ошибке
    if(errorCode === 401){
        dispatch(logout())
    }

    return (
        <div className={s.eventsbox}>
            <Toolbar title={'События'}>
                <EventsToolbar/>
            </Toolbar>

            <div className={s.events}>
                {isHaveCameras
                    ?
                    <EventsContent/>
                    :
                    <CamsNotFound/>
                }

            </div>
        </div>
    );
};

export default Events;