import React from 'react';
import s from './MiniLoader.module.scss'
const MiniLoader = () => {
    return (
        <div className={s.loader}>
            <div className={s.loader__loader}></div>
        </div>
    );
};

export default MiniLoader;