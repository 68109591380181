import React, {useState} from 'react';
import s from './PersonsOpen.module.css'
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../../components/UI/Modal/Modal";
import AddPerson from "../../../components/Faces/Persons/AddPerson/AddPerson";
import {logout} from "../../../Redux/Slices/authSlice";
import PersonsContent from "../../../components/Faces/Persons/PersonsContent/PersonsContent";
import {useGetPersonsQuery, usePostPersonsMutation} from "../../../Redux/APIs/otherAPI/personApi";

const PersonsOpen = () => {
    const [modalPerson, setModalPerson] = useState(false)
    const dispatch = useDispatch()
    const errorCode = useSelector(state => state.persons.errorCode)
    const path = useParams().id.split('_')[0]
    const currentPage = useSelector(state => state.persons.currentPage)
    const isFreq = useSelector(state => state.persons.isFreq)
    const {data,isFetching,...f} = useGetPersonsQuery({path:path,page:currentPage+1, freq:isFreq},{refetchOnMountOrArgChange:currentPage})
    const [posttPerson] = usePostPersonsMutation()
    const postPerson = (state) => {
        const group = path
        posttPerson({...state,group:group})
        setModalPerson(false)
    }
    if(errorCode === 401){
        dispatch(logout())
    }
    return (
        <div className={s.personsbox}>
            {data && data?.persons
                &&
                <PersonsContent setModalPerson={setModalPerson} listType={data?.type} isLoading={isFetching} persons={data?.persons} totalPages={data?.pages} totalNum={data?.totalNum} totalNumFreq={data?.totalNumFreq}/>
            }

            {
                modalPerson && <Modal setShowModal={setModalPerson}>
                    <AddPerson closeModal={setModalPerson} postPerson={postPerson} />
                </Modal>
            }

        </div>
    );
};

export default PersonsOpen;
