import React from 'react';
import s from './CameraSettings.module.css'
import {useTranslation} from "react-i18next/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
const CameraSettings = (props) => {

    const openModal = (val) => {
        props.setSetting(val)
        props.setShowModal(true)
    }
    const [t,i18n] = useTranslation()
    return (
        <div className={s.cameraSettings} ref={props.refTo} >
            <div onClick={() => props.setSettingsOpen(false)} className={s.cameraSettings__close} >x</div>
            <div className={s.cameraSettings__title}>
                {props.title}
            </div  >
            <div className={s.cameraSettings__elem} onClick={() => openModal('rename')}>
                <div className={s.cameraSettings__img}>
                    <FontAwesomeIcon icon={faPencil} color={"#109CF1"}/>
                </div>
                <div className={s.cameraSettings__text}>
                    {t('camerasPage.camsEdit.rename')}
                </div>
            </div>
        </div>
    );
};

export default CameraSettings;