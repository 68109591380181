import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import allPersonVisitsCounter from "../../utils/allPersonVisitsCounter";
import arrayOb from "../../utils/arrayOb";

export const fetchOneFace = createAsyncThunk(
    'onePerson/fetchOneFace',
    async function(state,{rejectWithValue}) {
        try {
            console.log(state)
            const res = await axios.get(`/api/face_events`, {
                headers: {
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    cam_code:state.currentCamera,
                    rid:state.rid
                }
            })
            const array = await res.data.data
            return array.filter(elem => elem.person_id === state.personId)
        } catch (err) {
            return rejectWithValue(err.response.data.errors.code)
        }
    }
)

const initialState = {
    oneFace:[],
    personAllVisits:0,
    currentRid:null,
    currentPersonId:null,
    isLoading:true,
    isError:false,
    errorCode:'',
}

export const historyOneFaceSlice = createSlice({
    name: 'facesHistory',
    initialState,
    reducers: {
        setCurrentRid : (state,action) => {
            state.currentRid = action.payload.rid
            state.currentPersonId = action.payload.person_id
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOneFace.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
        })
        builder.addCase(fetchOneFace.fulfilled, (state, action) => {
            // тут пока был баг на серве из массива 0 элемент брал, если потом появятся баги, то проблема в массиве
            state.oneFace = action.payload[0]
            state.isError = false
            state.isLoading = false
        })
        builder.addCase(fetchOneFace.rejected, (state, action) => {
            state.isError = true
            state.errorCode = action.payload
        })
    },
})
export const {setCurrentRid} = historyOneFaceSlice.actions

export default historyOneFaceSlice.reducer
