import React, {useState, useCallback} from 'react';
import s from './ChangePhoto.module.css'
import {useDropzone} from "react-dropzone";
import convertToBase64 from "../../../../utils/toBase64Converter";
import {useTranslation} from "react-i18next/hooks";
import frontalHead from "../../../../public/headIcons/frontalHead.svg";
import rightHead from "../../../../public/headIcons/rightHead.svg";
import leftHead from "../../../../public/headIcons/leftHead.svg";
import fleftHead from "../../../../public/headIcons/left45Head.svg";
import frightHead from "../../../../public/headIcons/right45Head.svg";

const ChangePhotoElem = (props) => {
    const [imag,setImag] = useState('')
    const [t,i18n] = useTranslation()
    const onDrop = useCallback((acceptedFiles) => {
        const getBase = async (file) => {
            const based64file = await convertToBase64(file)
            const url = await based64file.toString()
            setImag(url)
            props.setPhotos(based64file.split(',')[1],props.title)
        }
        acceptedFiles.forEach((file) => {
            getBase(file)
        })
    },[])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const images = {
        'profile':frontalHead,
        'right':rightHead,
        'left':leftHead,
        'fleft':fleftHead,
        'fright':frightHead
    }
    console.log(props.type)
    return (
        <div className={s.changePhoto__imgbox} {...getRootProps()}>

            <input className={s.changePhotoInput} {...getInputProps()}/>
            {
                isDragActive ?
                    <div className={s.changePhoto__elemDrop}>
                        Отпустите фотографию
                    </div>
                    :
                    <div className={s.changePhoto__elemDrag}>
                        {props.type
                            ?
                            <img src={images[props.type]} alt=""/>
                            :
                            imag
                                ?
                                <img style={{width:'100%',height:'100%'}} src={imag} alt=""/>
                                :
                                <div>
                                    Выберите фотографию
                                </div>
                        }


                    </div>
            }
            {props.title}
        </div>
    );
};

export default ChangePhotoElem;