import React from 'react';
import s from './RoundButton.module.css'
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const RoundButton = (props) => {
    return (
        <button className={s.roundbutton} {...props} >
            <div className={s.roundbutton__text}>
                <FontAwesomeIcon icon={faPlus} size={'2xs'} />
            </div>
        </button>
    );
};

export default RoundButton;