import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    sortedCams:[],
    currentCamera:'',
    currentCameraName:'',
}

export const camSlice = createSlice({
    name: 'cams',
    initialState,
    reducers: {
        setCurrentCamera: (state,action) => {
            state.currentCamera = action.payload
        },
        setCurrentCameraName: (state,action) => {
            state.currentCameraName = action.payload
        },
        sortCams: (state,action) => {
            state.sortedCams = action.payload.cams.filter(elem => elem.name.toLowerCase().includes(action.payload.value.toLowerCase()))
        },


    }
})


export const {setCurrentCamera,sortCams,setCurrentCameraName} = camSlice.actions

export default camSlice.reducer