import React from 'react';
import s from './ReportsVisitsHistory.module.scss'
import Toolbar from "../../../components/toolbar/Toolbar";
import VisitsHistoryToolbar from "../../../components/VisitsHistoryReport/VisitsHistoryToolbar/VisitsHistoryToolbar";
import VisitsHistoryContent from "../../../components/VisitsHistoryReport/VisitsHistoryContent/VisitsHistoryContent";
const ReportsVisitsHistory = () => {
    return (
        <div className={s.reportsVisitsHistorybox}>
            <Toolbar>
                <VisitsHistoryToolbar/>
            </Toolbar>
            <VisitsHistoryContent/>
        </div>
    );
};

export default ReportsVisitsHistory;
