import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    photos:[],
    photoFas:'',
    photoLeft:'',
    photoRight:'',
    photoFLeft:'',
    photoFRight:'',
}

export const onePersonSlice = createSlice({
    name: 'onePerson',
    initialState,
    reducers: {
        changePhotos:(state, action) => {
            switch (action.payload.opt){
                case 1:
                    state.photos[1] = action.payload.photo
                    break
                case 2:
                    state.photos[2] = action.payload.photo
                    break
                case 3:
                    state.photos[3] = action.payload.photo
                    break
                case 4:
                    state.photos[4] = action.payload.photo
                    break
                case 5:
                    state.photos[5] = action.payload.photo
                    break
                case -1:
                    state.photos = []
                    break
            }
        }
    },
})


export const {changePhotos} = onePersonSlice.actions

export default onePersonSlice.reducer