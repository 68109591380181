import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SidebarItem from "./SidebarItem";
import s from './Sidebar.module.css'
import '../../index.scss'
import {useLocation} from "react-router-dom";
import {closeSideBar, refactSidebarList} from "../../Redux/Slices/sidebarSlice";
import avatar from '../../public/Icons/Setting.png'
import {logout} from "../../Redux/Slices/authSlice";

const Sidebar = () => {
    const sidebarItems = useSelector(state => state.sidebar.sidebarItems)
    const dispatch = useDispatch()
    const isAuth = useSelector(state => state.auth.isAuth)
    const sidebarIsOpen = useSelector(state => state.sidebar.sidebarIsOpen)
    const path = useLocation().pathname
    const currentUser = useSelector(state => state.auth.currentUser)

    useEffect(()=>{
        dispatch(closeSideBar())
    },[path])

    useEffect(() => {
        if(currentUser){
            dispatch(refactSidebarList({user:currentUser}))
        }
    },[currentUser])


    if(isAuth === false){
        return (
            <></>
        )
    }
    return (
        <div className={sidebarIsOpen ? s.sidebarboxOpen : s.sidebarbox}>
            <div className={s.sidebar}>

                <div className={s.sidebar__account}>
                    <div><img src={avatar} alt=""/></div>
                    <div>{currentUser?.name}</div>
                    <div style={{color:'red'}} onClick={() => dispatch(logout())}>
                        Выйти
                    </div>
                </div>


                {currentUser !== null
                    &&
                    sidebarItems.map((elem,id) =>
                    <SidebarItem key={id} subItems={elem.subItems} imag={elem.imag} name={elem.name} path={elem.path}></SidebarItem>
                )}

            </div>
        </div>

    );
};

export default Sidebar;
