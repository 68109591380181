import React from 'react';
import s from "../HistoryAdminFaces/HistoryAdminFaces.module.scss";
import {PROXY} from "../../../../utils/pathss";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentRid} from "../../../../Redux/Slices/HistoryOneFaceSlice";
import getTimeAndDate from "../../../../utils/getTimeAndDate";



const HistoryAdminCard = ({elem,id}) => {
    const dispatch = useDispatch()
    const currentRid = useSelector(state => state.historyOneFace.currentRid)
    return (
        <>
            <div style={{backgroundColor: currentRid === elem.rid && 'rgba(0,0,0,0.1)'}} key={id} className={s.adminfaces__card} onClick={() => {
                dispatch(setCurrentRid({rid:elem.rid,person_id:elem.person_id}))
            }}>
                <div>
                    <img src={`${PROXY}api/${elem?.imgdata?.path}`} alt="" className={s.adminfaces__img} />
                </div>
                <div>
                    <div>
                        rid: {elem.rid}
                    </div>
                    <div>
                        date: {getTimeAndDate('date',elem.dt)}
                    </div>
                    <div>
                        time: {getTimeAndDate('time',elem.dt)}
                    </div>
                </div>
            </div>
        </>

    );
};

export default HistoryAdminCard;