import React, {useEffect, useRef, useState} from 'react';
import s from './CamSlider.module.css'
import {useDispatch, useSelector} from "react-redux";
import {autoMoveSlider, clearSlider, stopSlider} from "../../../Redux/Slices/camEventsSlidesSlide";
import CamSliderImg from "../CamSliderImg/CamSliderImg";
import {logout} from "../../../Redux/Slices/authSlice";
import SlideshowLoader from "../SlideshowLoader/SlideshowLoader";


const CamSlider = (props) => {
    const dispatch = useDispatch()
    const isSliderPlay = useSelector(state => state.camSlides.isSliderPlay)
    const speed = useSelector(state => state.camSlides.speed)
    const canShow = useSelector(state => state.camSlides.canShow)
    const camSlides = useSelector(state => state.camSlides.camSlides)
    const sliderPos = useSelector(state => state.camSlides.sliderPos)
    const errorCode = useSelector(state => state.camSlides.errorCode)
    const sliderMode = useSelector(state => state.camSlides.sliderMode)

    useEffect(() => {
        const onKeypress = e => {
            if(e.code === 'Space'){
                dispatch(stopSlider(!isSliderPlay))
            }
        };
        document.addEventListener('keydown', onKeypress);
        return () => {
            document.removeEventListener('keydown', onKeypress);
        };
    }, [isSliderPlay]);


    useEffect(() => {
        if(isSliderPlay && canShow){
            var slideshow = setInterval(() =>{
                if(sliderMode === 'next'){
                    if(camSlides.length === sliderPos+1){
                        clearInterval()
                        dispatch(clearSlider())
                        props.nextSlide(camSlides[camSlides.length-1].id + 1)
                    }else if(camSlides.length !== sliderPos+1){
                        dispatch(autoMoveSlider({maxValue: camSlides.length}))
                    }
                }else if(sliderMode === 'repeat'){
                    dispatch(autoMoveSlider({maxValue: camSlides.length}))
                }

            }, 1000/(speed*2))

        } else if(isSliderPlay === false){
            return () => clearInterval(slideshow)
        }
        return () => clearInterval(slideshow)
    },[camSlides, isSliderPlay,canShow,sliderMode,sliderPos])

    const windowRef = useRef()

    // logout if unathorized
    if(errorCode === 401){
        dispatch(logout())
    }

    return (
        <div className={s.camslider}>
            <div className={s.camslider__window} ref={windowRef}>
                {!canShow && sliderPos === 0
                    &&
                    <SlideshowLoader/>
                }
                <div className={s.camslider__slider}>
                    {camSlides.length > 0
                        &&
                        <CamSliderImg camSlides={camSlides} fullscreen={props.fullscreen} width={props.width} listLength={camSlides?.length}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default CamSlider;
