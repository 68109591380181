import React from 'react';
import s from './JustInputFull.module.scss'
const JustInputFull = (props) => {
    return (
        <div>
            <input {...props} className={s.input} style={{width:`${props.width}px`}} />
        </div>
    );
};

export default JustInputFull;