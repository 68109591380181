import React from 'react';
import './menu-burger.css'
import {useDispatch, useSelector} from "react-redux";
import {openSideBar} from "../../../Redux/Slices/sidebarSlice";
const MenuBurger = () => {

    const sidebarIsOpen = useSelector(state => state.sidebar.sidebarIsOpen)
    const dispatch = useDispatch()
    const goOpenSidebar = () => {

    }

    return (
        <div className={'burger'} onClick={() => dispatch(openSideBar())}>
            <div className={`burger__line ${sidebarIsOpen == true && 'burger__lineOpenTop'}`}></div>
            <div className={`burger__line ${sidebarIsOpen == true && 'burger__lineHidden'}`}></div>
            <div className={`burger__line ${sidebarIsOpen == true && 'burger__lineOpenBottom'}`}></div>
        </div>
    );
};

export default MenuBurger;