import i18n from 'i18next';
import { initReactI18next } from "react-i18next/hooks";
import translationEN from './public/locale/en/translation.json';
import translationRU from './public/locale/ru/translation.json';
import translationKZ from './public/locale/kz/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'ru',
        interpolation: {
        },
        resources: {
            en: {
                translation: translationEN
            },
            ru:{
                translation: translationRU
            },
            kk:{
                translation: translationKZ
            }
        }
    });

export default i18n;