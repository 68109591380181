import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {logout} from "./authSlice";
import setTimeToZero from "../../utils/timezoning";
import getTimeAndDate from "../../utils/getTimeAndDate";
import getDayStartTime from "../../utils/getDayStartTime";

export const fetchCamEventsForDate = createAsyncThunk(
    'camEvents/fetchCamEventsForDate',

    async function(state){
        const res = await axios.get('/api/sequences',{
            headers:{
                "Authorization": localStorage.getItem('token')
            },
            params:{
                cam_code: state.currentCamera,
                dt_start: state.neededDate,
                ts_len: 86400,
            }
        })
        const data = await res.data.data
        return data
    }
)
export const fetchCamSecondEventsForDate = createAsyncThunk(
    'camEvents/fetchCamSecondEventsForDate',
    async function(state){
        const res = await axios.get('/api/sequences',{
            headers:{
                "Authorization": localStorage.getItem('token')
            },
            params:{
                cam_code: state.currentCamera,
                dt_start: state.neededDate,
                ts_end:86400,
            }
        })
        const data = await res.data.data
        return data
    }
)
export const fetchCamSecondEvents = createAsyncThunk(
    'camEvents/fetchCamSecondEvents',
    async function(state){
        const res = await axios.get('/api/sequences',{
            headers:{
                "Authorization": localStorage.getItem('token')
            },
            params:{
                cam_code: state.currentCamera,
                dt_start: state.neededDate,
            }
        })
        const data = await res.data
        const newFrames = data.data.map(elem => {
            const date = new Date(elem.dt)
            const inSec = (date.getHours()*3600) + (date.getMinutes()*60) + date.getSeconds()
            const fullDate = `${date.getMonth()-1}/${date.getDate()}/${(date.getFullYear())}`
            return {...elem, dt: inSec, fullDate:fullDate}
        })
        return newFrames
    }
)


const initialState = {
    camEvents:[],
    camSecondEvents:[],
    currentPage:0,
    currentSlideId:0,
    currentCamera:null,
    currentCameraName:'',
    date:getDayStartTime(Date()),
    isLoading:true,
    isError:false,
    reverted:false,
    isFirstFetchSlides:false,
}

export const camEventsSlice = createSlice({
    name: 'camEvents',
    initialState,
    reducers: {
        setCurrentCameraName : (state,action) => {
            state.currentCameraName = action.payload
        },
        changeDate : (state,action) => {
            state.date = action.payload
        },
        saveEvents : (state,action) => {
            state.camSecondEvents = action.payload
        },
        revertEvents : (state,action) => {
            state.camEvents = action.payload
        },
        setReverted : (state,action) => {
            state.reverted = action.payload
        },
        setProgressReverted : (state,action) => {
            state.camSecondEvents = action.payload
        },
        changeCamera : (state,action) => {
            state.reverted = false
        },
        changeFirstFetchSlide: (state,action) => {
            state.isFirstFetchSlides = true
        },


    },
    extraReducers: (builder) => {
        builder.addCase(fetchCamEventsForDate.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
            state.isFirstFetchSlides = false
        })
        builder.addCase(fetchCamEventsForDate.fulfilled, (state, action) => {
            const newFrames = action.payload.map(elem => {
                const date = new Date(elem.dt)
                const inSec = (date.getHours()*3600) + (date.getMinutes()*60) + date.getSeconds()
                const fullDate = `${date.getMonth()-1}/${date.getDate()}/${(date.getFullYear())}`
                return {...elem, dt: inSec, fullDate:fullDate}
            })
            state.camEvents = newFrames
            state.isError = false
            state.isLoading = false

        })
        builder.addCase(fetchCamEventsForDate.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
        })
        builder.addCase(fetchCamSecondEventsForDate.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
            state.isFirstFetchSlides = false
        })
        builder.addCase(fetchCamSecondEventsForDate.fulfilled, (state, action) => {
            state.camSecondEvents = action.payload
            state.isError = false
            state.isLoading = false

        })
        builder.addCase(fetchCamSecondEventsForDate.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
        })
        builder.addCase(fetchCamSecondEvents.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
            state.isFirstFetchSlides = false
        })
        builder.addCase(fetchCamSecondEvents.fulfilled, (state, action) => {
            state.camSecondEvents = action.payload
            state.isError = false
            state.isLoading = false

        })
        builder.addCase(fetchCamSecondEvents.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
        })

    },
})



export const {changeDate,saveEvents,setReverted,revertEvents,setProgressReverted,changeCamera,changeFirstFetchSlide,setCurrentCameraName} = camEventsSlice.actions
export default camEventsSlice.reducer