import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";


export const fetchLocation = createAsyncThunk(
    'users/fetchLocation',
    async function(state,{rejectWithValue}){
        try {
            const res = await axios.get('/api/location',{
                headers:{
                    "Authorization": localStorage.getItem('token')
                }
            })
            const data = await res.data
            return data.data.locations
        } catch (err) {
            return rejectWithValue(err.response.data.errors.code)
        }
    }
)
export const postLocation = createAsyncThunk(
    'users/postLocation',
    async function(state,{rejectWithValue}){
        try{
            const res = await axios.post('/api/location',{
                    name:state.name,
                    description:null,
                    address: {
                        building:state.address.building,
                        city:state.address.city,
                        street:state.address.street
                    }
                },
                {
                    headers:{
                        "Authorization": localStorage.getItem('token')
                    }
                })
            const data = await res.data
            return data.data
        }catch (err){
            return rejectWithValue(err.response.data.errors.code)
        }

    }
)


const initialState = {
    locations:[],
    isLoading:false,
    isError:false,
    errorCode:'',
    locationsUpdate:false,

}

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        tokenCheck: (state) => {

        },


    },
    extraReducers: (builder) => {
        builder.addCase(fetchLocation.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchLocation.fulfilled, (state, action) => {
            state.locations = action.payload
            state.isLoading = false
        })
        builder.addCase(fetchLocation.rejected, (state, action) => {
            state.isLoading = false
            state.errorCode = action.payload
        })
        builder.addCase(postLocation.pending, (state, action) => {
            state.locationsUpdate = false
        })
        builder.addCase(postLocation.fulfilled, (state, action) => {
            state.locationsUpdate = true
        })
        builder.addCase(postLocation.rejected, (state, action) => {
            state.errorCode = action.payload
        })
    },
})


export const {tokenCheck} = locationSlice.actions

export default locationSlice.reducer