import React, {useState} from 'react';
import s from "../VisitsHistoryMonthTable.module.scss";
import MonthTablePopup from "../MonthTablePopup/MonthTablePopup";
import Modal from "../../../UI/Modal/Modal";
import getPersonHistoryCalendar from "../../../../utils/getPersonHistoryCalendar";

const MonthTableCell = ({cell}) => {
    const [open,setOpen] = useState(false)
    console.log(cell)
    return (
        <>
            <td className={s.table__cellMonth} onClick={() => cell.history.length>0 && setOpen(true)}>
                {cell.history.length === 0 ? '' : cell.history.length}
            </td>
            {open
                &&
                <Modal showModal={open} setShowModal={setOpen}>
                    <MonthTablePopup month={cell.month} cell={getPersonHistoryCalendar(cell)}/>
                </Modal>

            }
        </>
    );
};

export default MonthTableCell;