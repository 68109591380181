import React from 'react';
import s from "../../../FacesSearch/FacesSearchTable/FacesSearchTable.module.scss";
import {PROXY} from "../../../../../utils/pathss";
import getTimeAndDate from "../../../../../utils/getTimeAndDate";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";

const TablePerson = ({elem,setFullImage}) => {
    const navigate = useNavigate()
    const redirectToPerson = (person) => {
        navigate(`${person}`)
    }
    const calcVisits = (option) => {

        if(option === 'month'){
            const date = new Date()
            const month = date.getMonth()+1
            const year = date.getFullYear()
            return elem?.visits[year][month] || 0
        }else if( option === 'all'){
            let allVisits = 0
            for(let i in elem?.visits){
                const thisYear = elem?.visits[i]
                for(let ii in thisYear){
                    allVisits += thisYear[ii]
                }
            }
            return allVisits
        }
    }

    return (
        <tr className={s.table__row} onClick={() => redirectToPerson(elem?.id)}>
            <td align={"center"} className={s.table__cell}>
                {elem?.image
                    ?
                    <img onClick={() => setFullImage(`${PROXY}api/${elem?.image}`)} src={`${PROXY}api/${elem?.image}`} alt=""/>
                    :
                    <div className={s.table__imgBanner}>
                        <FontAwesomeIcon icon={faImage}/>
                    </div>
                }
            </td>
            <td align={"center"} className={s.table__cell}>
                {elem?.fio}
            </td>
            <td align={"center"} className={s.table__cell}>
                {getTimeAndDate('date',elem?.create_date)}
            </td>
            <td align={"center"} className={s.table__cell}>
                {elem?.lv ? getTimeAndDate('date',elem?.lv) : ''}
            </td>
            <td align={"center"} className={s.table__cell}>
                {Object.keys(elem?.visits).length && calcVisits('all')}
            </td>
            <td align={"center"} className={s.table__cell}>
                {Object.keys(elem?.visits).length && calcVisits('month')}
            </td>
        </tr>
    );
};

export default TablePerson;