import React, {useEffect, useRef, useState} from 'react';
import s from "./CameraELem.module.css";
import image from '../../../public/images/Rectangle 17.png'
import Modal from "../../UI/Modal/Modal";
import CameraSettings from "../CameraSettigns/CameraSettings";
import './CameraElemAnim.css'
import CamsRename from "../CamsRename/CamsRename";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setCurrentCamera} from "../../../Redux/Slices/camSlice";
import {setCurrentCameraName} from "../../../Redux/Slices/camEventsSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlug} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next/hooks";


const CameraElem = (props) => {

    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)
    const [setting,setSetting] = useState('')
    const modalManager = {
        'menu' : <CameraSettings title={props.title} setSetting={setSetting} setShowModal={setShowModal}/>,
        'rename':<CamsRename setShowModal={setShowModal}/>
    }
    const [t,i18n] = useTranslation()
    return (
        <>
            <div className={s.cameras__elem}>
                <div className={s.cameras__video} onClick={() => dispatch(setCurrentCamera(props.id))}>
                    <NavLink className={s.cameras__navlink} to={`/cams/${props.id}`} onClick={() => dispatch(setCurrentCameraName(props.title))}>
                        {props.path
                            ?
                            <img className={s.cameras__image} src={image} alt=""/>
                            :
                            <div className={s.cameras__banner}>
                                <FontAwesomeIcon icon={faPlug} color={'#bfbfbf'} size={'2x'}/>
                                <span>{t('camerasPage.noConnection')}</span>
                            </div>
                        }

                    </NavLink>

                </div>

                <div className={s.cameras__info}>
                    <div className={s.cameras__textinfo}>
                        <div className={s.cameras__title}>
                            {props.title}
                        </div>
                        <div className={s.cameras__status}>
                            <div style={{backgroundColor: props.status === 'active' ? 'green' : 'red'}} className={s.cameras__statusIcon}>
                            </div>
                            {props.status === 'active' ? 'Online' : 'Offline'}
                        </div>
                    </div>

                    <div className={s.cameras__camMenu} onClick={() => {
                        setSetting('menu')
                        setShowModal(true)
                    }}>
                        <div className={s.cameras__camMenuDot}></div>
                        <div className={s.cameras__camMenuDot}></div>
                        <div className={s.cameras__camMenuDot}></div>
                    </div>
                </div>
            </div>

            {showModal
                &&
                <Modal setShowModal={setShowModal}>
                    {modalManager[setting]}
                </Modal>
            }

        </>

    );
};

export default CameraElem;