import React from 'react';
import s from "../VisitsHistoryMonthTable/VisitsHistoryMonthTable.module.scss";
import {downloadExcel} from "react-export-table-to-excel";
import {useTranslation} from "react-i18next/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import createTableHeaders from "./assets/createTableHeaders";
import createTableBody from "./assets/createTableBody";
import getTimeAndDate from "../../../utils/getTimeAndDate";

const VisitsHistoryDownloadExcel = () => {
    const visitsHistory= useSelector(state => state.visitsHistoryReport.visitsHistorySorted)
    const [t,i18n] = useTranslation()

    const handleDownloadExcel = () => {
        const date = getTimeAndDate('date', Date())
        downloadExcel({
            fileName: `Постоянные клиенты`,
            sheet: "react-export-table-to-excel",
            tablePayload: {
                header:createTableHeaders(visitsHistory[0].history, ['ID клиента','Возраст','Пол','Сумма']),
                body: createTableBody(visitsHistory),
            },
        });
    }
    return (
        <>
            <button onClick={handleDownloadExcel} className={s.button}>
                <FontAwesomeIcon icon={faFileExcel}/>
                <span>{t('personReport.exportExcel')}</span>
            </button>
        </>
    );
};

export default VisitsHistoryDownloadExcel;
