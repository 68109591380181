export const MAIN_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const PEOPLE_ROUTE = '/peoples'


export const CAMS_ROUTE = '/cams'
export const CAMS_FULL_ROUTE = '/cams/:id'

export const LOCATIONS_ROUTE = '/locations'
export const MAP_ROUTE = '/locations/map'


export const FACES_ROUTE = '/faces'
export const FACES_HISTORY_ROUTE = '/faces/history'
export const FACES_PERSONS_ROUTE = '/faces/persons'
export const FACES_PERSONSOPEN_ROUTE = '/faces/persons/:id'
export const FACES_CURRENTPERSON_ROUTE = '/faces/persons/:id/:pId'
export const FACES_SEARCH_ROUTE = '/faces/search'

export const ADMIN_ROUTE = '/admin'
export const ADMIN_MONITORING_ROUTE = '/admin/monitoring'

export const EVENTS_ROUTE = '/events'

export const RAWDATA_ROUTE = '/admin/rawdata'

export const REPORTS_ROUTE = '/reports'
export const REPORT_VISITORS_ROUTE = '/reports/visitors'
export const REPORT_PERSON_ROUTE = '/reports/person/:id'

export const REPOST_VISITS_HISTORY_ROUTE = '/reports/visitshistory'




export const PROXY = 'https://nsight.kz:8443/'
