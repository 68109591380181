import React, {useState} from 'react';
import s from '../CamControl.module.css'
import {Layer, Rect, Stage, Text} from "react-konva";
import {hours, minutes, minutes12, times} from "../timeStamps";
import CamControlTime from "../CamControlTime/CamControlTime";
import {useDispatch, useSelector} from "react-redux";
import {handleControlMove} from "../../../../Redux/Slices/camControlSlice";

const CamControlLine = (props) => {
    const {hei,otn,stage, setStage,setScrollDepth,scrollDepth,timelineColot,timeTextColor,isLoading,reverted} = {...props}
    const activeSequence = useSelector(state => state.camSlides.activeSequence)
    const dispatch = useDispatch()
    const [timePopUp, setTimePopUp] = useState(false)
    const [mousePosX,setMousePosX] = useState({})
    const [mousePosY,setMousePosY] = useState({})
    const [block,setBlock] = useState(false)
    const getTimes = (scrollD) => {
        if(scrollD <= 1){
            return times
        }else if(scrollDepth === 2){
            return hours
        }else if(scrollDepth === 4){
            return minutes
        }else if(scrollDepth === 8){
            return minutes12
        }
    }

    const handleWheel = (e) => {

        if(!block){

            e.evt.preventDefault();
            const scaleBy = 2;
            const stage = e.target.getStage();
            const oldScale = stage.scaleX();
            const mousePointTo = {
                x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
                y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
            };
            const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
            // прошлый скейл был <= 4
            if(e.evt.deltaY < 0){
                setMousePosX({...mousePosX, [newScale]: [stage.getPointerPosition().x ,mousePointTo.x] })
                setMousePosY({...mousePosY, [newScale]: [stage.getPointerPosition().y ,mousePointTo.y] })

            }

            if (newScale >= 1 && newScale <= 8){
                setScrollDepth(newScale)

                const newPosX = {
                    px1: stage.getPointerPosition().x,
                    px2: mousePointTo.x
                }
                const newPosY = {
                    py1: stage.getPointerPosition().y,
                    py2: mousePointTo.y
                }

                if(e.evt.deltaY > 0 && mousePosX[oldScale] !== undefined){
                    newPosX.px1 = mousePosX[oldScale][0]
                    newPosX.px2 = mousePosX[oldScale][1]
                    mousePosX[oldScale] = undefined
                    // new values if this newScale has been used
                    newPosY.py1 = mousePosY[oldScale][0]
                    newPosY.py2 = mousePosY[oldScale][1]
                    mousePosY[oldScale] = undefined
                }
                setStage({
                    scale: newScale,
                    x: Math.round(((newPosX.px1 / newScale - newPosX.px2) * newScale) / 100)*100,
                    y: (newPosY.py1 / newScale - newPosY.py2) * newScale
                });

            }
            setBlock(true)
            setBlockTimeout()
        }else if(block){
        }

    };
    const setBlockTimeout = () => {
        const timeout = setTimeout(() => {
            setBlock(false)
        },100)
    }


    const handleMove = (e) => {
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const pointerPosX = stage.getPointerPosition().x
        const stageX = stage.x()
        dispatch(handleControlMove({oldScale:oldScale, pointerPosX:pointerPosX, stageX:stageX, otn:otn}))

    }

    return (
        <div className={s.control__control} style={{width:`${props.width}px`}} >
            <Stage
                width={props.width*2}
                height={hei}
                onWheel={handleWheel}
                scaleX={stage.scale}
                scaleY={stage.scale}
                x={stage.x}
                y={0}
                onMouseOver={() => setTimePopUp(true)}
                onMouseOut={() => setTimePopUp(false)}
                onMouseMove={(e) => handleMove(e)}
            >
                <Layer>
                    {/*отрисовка ключевых кадров на видео------------------------------------------------------------------------------------------------------*/}

                    <Rect
                        x={0}
                        y={0}
                        fill={'#f2f2f2'}
                        width={props.width*2}
                        height={400}
                    />
                    {props.frames.length > 0
                        &&
                        isLoading === false
                        &&
                        props.frames.map((elem,id) =>
                            <Rect
                                key={id}
                                x={(elem.dt*otn) + (reverted === true ? props.width : 0)}
                                y={0}
                                width={elem.duration*otn+5}
                                height={hei}
                                onClick={(e) => {
                                    if(activeSequence !== elem.id){
                                        props.fetchSlides(elem.id)
                                    }
                                }}
                            />
                        )}

                    {   props.frames.length > 0
                        &&
                        isLoading === false
                        &&
                        props.frames.map((elem,id) =>
                            <Rect
                                key={id}
                                x={(elem.dt*otn) + (reverted === true ? props.width : 0)}
                                y={0}
                                fill={activeSequence === elem.id ? '#109CF1' : '#BFBFBF'}
                                width={elem.duration*otn + 0.3}

                                height={hei}
                                onClick={(e) => {
                                    if(activeSequence !== elem.id){
                                        props.fetchSlides(elem.id)
                                    }
                                }}
                            />
                        )}


                    {props.secondFrames.length > 0
                        &&
                        isLoading === false
                        &&
                        props.secondFrames.map((elem,id) =>
                            <Rect
                                key={id}
                                x={(elem.dt*otn)+ (reverted === true ? 0 : props.width)}
                                y={0}
                                fill={activeSequence === elem.id ? '#109CF1' : '#BFBFBF'}
                                width={elem.duration*otn+1}
                                height={hei}
                                onClick={(e) => {
                                    if(activeSequence !== elem.id){
                                        props.fetchSlides(elem.id)
                                    }
                                }}
                            />
                        )}
                    {isLoading === true
                        &&
                        <Rect
                            x={0}
                            y={0}
                            width={props.width*2}
                            height={200}
                            fill={'#BFBFBF'}
                            opacity={.15}
                        />
                    }

                            <Rect
                                y={0}
                                x={0}
                                height={10/scrollDepth}
                                fill={timelineColot}
                                width={props.width}
                            />

                            {getTimes(scrollDepth).map((elem,id) =>
                                <Rect
                                    key={id}
                                    width={1/scrollDepth}
                                    height={300}
                                    fill={'#BFBFBF'}
                                    x={3600*elem.time*otn}
                                />
                            )}

                            {getTimes(scrollDepth).map((elem,id) =>
                                <Text
                                    key={id}
                                    fontSize={10/scrollDepth}
                                    y={0}
                                    text={elem.text}
                                    wrap="char"
                                    x={3600*elem.time*(otn)+4/scrollDepth}
                                    align="center"
                                    fill={timeTextColor}
                                />
                            )}

                            {/* граница снизу */}
                            <Rect
                                width={props.width*2}
                                height={2}
                                fill={'#BFBFBF'}
                                x={0}
                                y={hei/scrollDepth-(1/scrollDepth)}
                            />
                            {/*-------------вторая полоска---------------------------------------------------------------------------------------------------------*/}
                            <Rect
                                y={0}
                                x={props.width}
                                height={10/scrollDepth}
                                fill={timelineColot}
                                width={props.width}
                            />
                            {getTimes(scrollDepth).map((elem,id) =>
                                <Rect
                                    key={id}
                                    width={1/scrollDepth}
                                    height={300}
                                    fill={'#BFBFBF'}
                                    x={(3600*elem.time*otn)+props.width}
                                />
                            )}

                            {getTimes(scrollDepth).map((elem,id) =>
                                <Text
                                    key={id}
                                    fontSize={10/scrollDepth}
                                    y={0}
                                    text={elem.text}
                                    wrap="char"
                                    x={(3600*elem.time*otn+4/scrollDepth)+props.width}
                                    align="center"
                                    fill={timeTextColor}
                                />
                            )}
                </Layer>
            </Stage>
            <CamControlTime
                timePopUp={timePopUp}

            />
        </div>
    );
};

export default CamControlLine;
