import React from 'react';
import s from './SlideshowLoader.module.css'
import Loader from "../../UI/Loader/Loader";
import {useTranslation} from "react-i18next/hooks";
const SlideshowLoader = (props) => {
    const [t,i18n] = useTranslation()
    return (
        <div className={s.loader}>
            <div className={s.loader__spinner}></div>
            {t('default.loading')}
        </div>
    );
};

export default SlideshowLoader;