import React from 'react';
import s from './PersonReportsNotFound.module.scss'
import {useTranslation} from "react-i18next/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTable} from "@fortawesome/free-solid-svg-icons";
const PersonReportsNotFound = () => {
    const [t,i18n] = useTranslation()
    return (
        <div className={s.notFound}>
            <FontAwesomeIcon icon={faTable} className={s.notFound__img}/>
            <div className={s.notFound__span}>
                Информации не найдено
            </div>
        </div>
    );
};

export default PersonReportsNotFound;