import React, {useEffect} from 'react';
import s from './PersonReportsPhotos.module.scss'
import PersonReportPhoto from "../PersonReportPhoto/PersonReportPhoto";
import {useTranslation} from "react-i18next/hooks";
import {useGetOnePersonPhotosQuery} from "../../../Redux/APIs/otherAPI/onePersonApi";

const PersonReportsPhotos = ({id,name}) => {
    const [t,i18n] = useTranslation()
    const {data:photos,isLoading,isError} = useGetOnePersonPhotosQuery({id},{skip:id === ':id'})
    return (
        <div className={s.personPhotos}>
            {/*<div className={s.personPhotos__title}>*/}
            {/*    {name*/}
            {/*        ?*/}
            {/*        name*/}
            {/*        :*/}
            {/*        t('personReports.personSelected')*/}
            {/*    }*/}
            {/*</div>*/}
            {!isLoading
                &&
                photos?.length > 0
                ?
                <div className={s.personPhotos__list}>
                    {photos.slice(0, 3).map(elem =>
                        <PersonReportPhoto path={elem.path}/>
                    )}
                </div>
                :
                <div className={s.personPhotos__notFound}>
                    {t('personReports.photoNotFound')}
                </div>
            }
        </div>
    );
};

export default PersonReportsPhotos;