const getUniqPersonPhotos = (photos) =>{
        const newList = []
        const blackList = []
        for(let i in photos){
            if(blackList.includes(photos[i].type)){
                continue
            }else{
                blackList.push(photos[i].type)
                newList.push(photos[i])
            }
        }
        return newList
}
export default getUniqPersonPhotos;