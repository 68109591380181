import React from 'react';
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis,Tooltip} from "recharts";
import MyTooltip from "../Tooltip/MyTooltip";
import {useTranslation} from "react-i18next/hooks";


const GenderChart = ({data}) => {
    const [t,i18n] = useTranslation()
    return (
        <ResponsiveContainer>
            <AreaChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} data={data}>
                <CartesianGrid  strokeDasharray="3 3" />
                <>
                    <Area name={t('personReport.charts.male')} legendType={'square'} type="monotone" dataKey="Male" stroke='#19AADE' fill={'#19AADE'}  />
                    <Area name={t('personReport.charts.female')} legendType={'square'} type="monotone" dataKey="Female" stroke="#EB548C" fill={"#EB548C"} />
                </>
                <Legend />
                <Tooltip content={<MyTooltip/>} />
                <XAxis tickSize={1} stroke={`rgba(0,0,0,0.3)`}  dataKey='date' tickMargin={10} />
                <YAxis  tickSize={1} allowDecimals={false}  stroke={`rgba(0,0,0,0.3)`}  axisLine={true}  tickMargin={10} tickLine={false} tickCount={8}/>
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default GenderChart;
