import React, {useEffect} from 'react';
import s from './Locations.module.css'
import Toolbar from "../../components/toolbar/Toolbar";
import LocationsList from "../../components/Locations/LocationsList/LocationsList";
import LocationsToolbar from "../../components/Locations/LocationsToolbar/LocationsToolbar";
import Loader from "../../components/UI/Loader/Loader";
import {useGetLocationsQuery} from "../../Redux/APIs/otherAPI/locationsApi";

const Locations = () => {
    const {isLoading,isError,error} = useGetLocationsQuery()
    return (
        <div className={s.locationbox}>
            <Toolbar title={'Локации'}>
                <LocationsToolbar/>
            </Toolbar>

                {!isLoading
                    ?
                    !isError
                        ?
                        <LocationsList />
                        :
                        <div>error</div>
                    :
                    <Loader/>
                }
        </div>
    );
};

export default Locations;