import {setProgressReverted, setReverted} from "../../../../Redux/Slices/camEventsSlice";
import setTimeToZero from "../../../../utils/timezoning";

export const handleScrollButtons = (opt,scrollDepth,bitTimeGap,timeGap,controlDayWidth,props,sliderPos,reverted,setSliderPos,neededDate,dispatch,stage,setStage) => {
    const sbv = scrollDepth <= 1 ? bitTimeGap : timeGap
    const maxW = scrollDepth <= 1? controlDayWidth : controlDayWidth*2
    if(scrollDepth <= 1){
        if(opt === 'left'){
            if(sliderPos < 0){
                setSliderPos(sliderPos+sbv)
            }else if (sliderPos === 0){
                if(neededDate == null){
                    const dat = new Date()
                    dat.setDate(dat.getDate() - 1)
                    props.saveEventss(props.frames)
                    props.dispatchDate(dat)
                }else if (neededDate !== null){
                    if(reverted === false){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() - 1 )
                        props.saveEventss(props.frames)
                        props.dispatchDate(setTimeToZero(dat))
                    }
                    else if(reverted === true){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() - 2 )
                        dispatch(setReverted(false))
                        props.dispatchDate(setTimeToZero(dat))

                    }
                }

                setSliderPos(-props.width+sbv)
            }

        }
        if(opt === 'right'){
            if(sliderPos > -props.width){
                setSliderPos(sliderPos-sbv)

            }else if (sliderPos === -props.width){
                setSliderPos(0-sbv)
                if(neededDate !== null){
                    if(reverted === false){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() + 2 )
                        dispatch(setReverted(true))
                        props.dispatchDate(dat)

                    } else if(reverted === true){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() + 1 )
                        dispatch(setProgressReverted(props.frames))
                        props.dispatchDate(dat)
                    }
                }
            }
        }
    }else if(scrollDepth === 2){
        const sumPos = (stage.x + sliderPos)
        if(opt === 'left'){
            if(sumPos < 0){
                setStage({...stage, x: stage.x+(timeGap)})
            }else if (sumPos >= 0){
                if(neededDate == null){
                    const dat = new Date()
                    dat.setDate(dat.getDate() - 1)
                    props.saveEventss(props.frames)
                    props.dispatchDate(dat)
                }else if (neededDate != null){
                    if(reverted === false){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() - 1 )
                        props.saveEventss(props.frames)
                        props.dispatchDate(dat)
                    }
                    else if(reverted === true){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() - 2 )
                        dispatch(setReverted(false))
                        props.dispatchDate(dat)

                    }
                }
                setStage({...stage, x: stage.x = -(controlDayWidth * scrollDepth)-sliderPos})
                setStage({...stage, x: stage.x+(timeGap)})
            }

        }
        if(opt === 'right'){
            if(sumPos > -props.width*3){
                setStage({...stage, x: stage.x-(timeGap)})

            }else if (sumPos <= -props.width*3){
                if(neededDate != null){
                    if(reverted === false){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() + 2 )
                        dispatch(setReverted(true))
                        props.dispatchDate(dat)

                    } else if(reverted === true){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() + 1 )
                        dispatch(setProgressReverted(props.frames))
                        props.dispatchDate(dat)
                    }

                }
                setStage({...stage, x: -(controlDayWidth+(timeGap)+sliderPos)})

            }
        }
    }
    else if(scrollDepth === 4){
        const sumPos = (stage.x + sliderPos)
        if(opt === 'left'){
            if(sumPos < 0){
                setStage({...stage, x: stage.x+(timeGap)})
            }else if (sumPos >= 0){
                if(neededDate == null){
                    const dat = new Date()
                    dat.setDate(dat.getDate() - 1)
                    props.saveEventss(props.frames)
                    props.dispatchDate(dat)
                }else if (neededDate !== null){
                    if(reverted === false){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() - 1 )
                        props.saveEventss(props.frames)
                        props.dispatchDate(dat)
                    }
                    else if(reverted === true){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() - 2 )
                        dispatch(setReverted(false))
                        props.dispatchDate(dat)

                    }
                }
                setStage({...stage, x: stage.x = -(controlDayWidth * scrollDepth)-sliderPos})
                setStage({...stage, x: stage.x+(timeGap)})
            }

        }
        if(opt === 'right'){
            if(sumPos > -props.width*7){
                setStage({...stage, x: stage.x-(timeGap)})

            }else if (sumPos <= -props.width*7){
                if(neededDate != null){

                    if(reverted === false){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() + 2 )
                        dispatch(setReverted(true))
                        props.dispatchDate(dat)

                    } else if(reverted === true){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() + 1 )
                        dispatch(setProgressReverted(props.frames))
                        props.dispatchDate(dat)
                    }

                }
                setStage({...stage, x: -((props.width*7)/2 - props.width - (timeGap)) })

            }
        }
    }
    else if(scrollDepth === 8){
        const sumPos = (stage.x + sliderPos)
        if(opt === 'left'){
            if(sumPos < 0){
                setStage({...stage, x: stage.x+(timeGap)})
            }else if (sumPos >= 0){
                if(neededDate == null){
                    const dat = new Date()
                    dat.setDate(dat.getDate() - 1)
                    props.saveEventss(props.frames)
                    props.dispatchDate(dat)
                }else if (neededDate !== null){
                    if(reverted === false){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() - 1 )
                        props.saveEventss(props.frames)
                        props.dispatchDate(dat)
                    }
                    else if(reverted === true){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() - 2 )
                        dispatch(setReverted(false))
                        props.dispatchDate(dat)

                    }
                }
                setStage({...stage, x: stage.x = -(controlDayWidth * scrollDepth)-sliderPos})
                setStage({...stage, x: stage.x+(timeGap)})
            }

        }
        if(opt === 'right'){
            if(sumPos > -props.width*15){
                setStage({...stage, x: stage.x-(timeGap)})

            }else if (sumPos <= -props.width*15){
                if(neededDate != null){

                    if(reverted === false){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() + 2 )
                        dispatch(setReverted(true))
                        props.dispatchDate(dat)

                    } else if(reverted === true){
                        const dat = new Date(neededDate)
                        dat.setDate(dat.getDate() + 1 )
                        dispatch(setProgressReverted(props.frames))
                        props.dispatchDate(dat)
                    }

                }
                setStage({...stage, x: -((props.width*15)/2 - props.width - (timeGap)) })

            }
        }
    }
}