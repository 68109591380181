import React from 'react';
import logo from '../../../public/Icons/VectorLogo.svg'
import s from './Logo.module.css'



const Logo = () => {
    return (
        <div className={s.logobox}>
            <img src={logo} alt="" className={s.logo}/>
            <span className={s.logoTxt}>Insight</span>
        </div>
    );
};

export default Logo;
