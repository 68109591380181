import React from 'react';
import s from './MonthTableGenderPopup.module.scss'
const MonthTableGenderPopup = ({setSortVisitsHistoryGender}) => {
    return (
        <div className={s.popup}>
            <div onClick={() => setSortVisitsHistoryGender(0)}>
                Все
            </div>
            <div onClick={() => setSortVisitsHistoryGender(1)}>
                Муж
            </div>
            <div onClick={() => setSortVisitsHistoryGender(2)}>
                Жен
            </div>
        </div>
    );
};

export default MonthTableGenderPopup;
