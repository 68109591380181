import React from 'react';
import s from '../PersonReportsPhotos/PersonReportsPhotos.module.scss'
import {PROXY} from "../../../utils/pathss";
const PersonReportPhoto = ({path}) => {
    return (
        <div className={s.personPhotos__elem}>
            <img src={`${PROXY}api/${path}`} alt=""/>
        </div>
    );
};

export default PersonReportPhoto;