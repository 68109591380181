import React, {useEffect, useRef, useState} from 'react';
import s from './CamsToolbar.module.css'
import GreenButton from "../../UI/GreenButton/GreenButton";
import './camsTransition.css'
import CamsAddCamera from "../../../pages/Cams/CamsAddCamera/CamsAddCamera";
import RoundButton from "../../UI/RoundButton/RoundButton";
import MyInput from "../../UI/MyInput/MyInput";
import {useDispatch, useSelector} from "react-redux";
import {sortCams} from "../../../Redux/Slices/camSlice";
import JustInput from "../../UI/JustInput/JustInput";
import {Trans, useTranslation} from "react-i18next/hooks";
import {useGetCamerasQuery} from "../../../Redux/APIs/otherAPI/camerasApi";

const CamsToolbar = (props) => {
    const [showModal,setShowModal] = useState(false)
    const [value,setValue] = useState('')
    const dispatch = useDispatch()
    const {data:cams, isLoading,isError,error} = useGetCamerasQuery()

    useEffect(() => {
        if(!isLoading && !isError){
            dispatch(sortCams({cams,value}))
        }
    },[value,cams])


    const [t,i18n] = useTranslation()
    return (
        <div className={s.toolbar__toolbox}>
            <div className={s.toolbar__filters}>
                <JustInput placeholder={t('camerasPage.cameraName')} value={value} onChange={(e) => setValue(e.target.value)} width={200} />
            </div>
            <div className={s.toolbar__buttons}>
                <GreenButton onClick={() => setShowModal(true)}>
                    <Trans i18nKey="camerasPage.addCamera">
                    </Trans>
                </GreenButton>
            </div>

            {showModal && <CamsAddCamera setShowModal={setShowModal} /> }

            <RoundButton onClick={() => setShowModal(true)} />
        </div>
    );
};

export default CamsToolbar;