import React, {useState} from 'react';
import s from './PersonVisitsTable.module.scss'
import getMonthName from "../../../../utils/getMonthName";
import {useGetPersonAllVisitsQuery} from "../../../../Redux/APIs/otherAPI/personAllVisitsApi";
import Modal from "../../../UI/Modal/Modal";
import {PROXY} from "../../../../utils/pathss";
const PersonVisitsTable = ({person,visits}) => {
    const {data,isLoading} = useGetPersonAllVisitsQuery({person:person?.id})
    const [showDayImage,setShowDayImage] = useState(false)
    const [fullDayImage,setFullDayImage] = useState('')
    const findDayImage = (list,day,date) => {

        const year = date.slice(0,4)
        const month = `${date.slice(5,10).length === 1 ? '0' : ''}${date.slice(5,10)}`
        const cday = `${`${day}`.length === 1 ? '0' : ''}${day}`
        const fullDate = `${year}${month}${cday}`
        if(list[fullDate]?.first_url){
            return  list[fullDate]?.first_url
        }
        return -1
    }
    const refacVisits = () => {
        const calend = []
        for(let i in visits){
            const date = new Date(visits[i].dt)
            const month = date.getMonth()+1
            const year = date.getFullYear()
            const thisDay = new Date(visits[i].dt).getDate()
            if( calend.find(elem => elem.date === `${year}-${month}`)){
                const currWeek = Math.ceil(thisDay/7)-1
                const currDay = thisDay%7 === 0 ? calend[calend.findIndex(elem => elem.date === `${year}-${month}`)].weeks[currWeek].days.length-1 : thisDay%7-1
                calend[calend.findIndex(elem => elem.date === `${year}-${month}`)].weeks[currWeek].days[currDay].isVisit = true
            }else{
                const daysInMonth = new Date(year,month,0).getDate()
                const days = []
                const weeks = Math.ceil(daysInMonth/7)
                const week = []
                for(let iii = 0; iii < weeks+1;iii++){
                    const dayss = []
                    for(let wek = 1; wek < 8;wek++){
                        const day = (iii*7) + wek
                        if(day > daysInMonth){
                            break
                        }
                        dayss.push({day:day,isVisit:day === thisDay ? true : false})
                    }
                    if(dayss.length >0){
                        week.push({week:iii, days:dayss})
                    }
                }
                for(let ii = 1;ii<daysInMonth+1;ii++){
                    days.push({day:ii,isVisit:ii === thisDay ? true : false})
                }
                calend.push({date:`${year}-${month}`,weeks:week})
            }

        }
        return calend

    }
    const getTableTitle = (dateStr) => {
        return `${dateStr.slice(0,4)} ${getMonthName(dateStr.slice(5,7))}`
    }
    return (
        <div className={s.tablebox}>
            <div className={s.tables}>
                {refacVisits().map((table,id) =>
                    <table className={s.table}>
                        <caption className={s.table__title}>{getTableTitle(table.date)}</caption>
                        <thead>
                        <tr>

                        </tr>
                        </thead>
                        <tbody>
                        {table.weeks.map((row,id) =>
                            <tr>
                                {row.days.map((elem,id) =>
                                    <td
                                        onClick={() => {
                                            setFullDayImage(findDayImage(data.attendance, elem.day, table.date))
                                            setShowDayImage(true)
                                        }}
                                        className={elem.isVisit ? s.table__cellActive : s.table__cell}>
                                        {elem.day}
                                    </td>
                                )}
                            </tr>
                        )}
                        </tbody>
                    </table>
                )
                }
            </div>

            {showDayImage
                &&
                <Modal showModal={showDayImage} setShowModal={setShowDayImage}>
                    {fullDayImage === -1
                        ?
                        <div style={{padding:20}}>Не найдено</div>
                        :
                        <img onClick={() => setShowDayImage(false)} src={`${PROXY}/api/${fullDayImage}`} alt=""/>
                    }

                </Modal>
            }
        </div>
    );
};

export default PersonVisitsTable;
