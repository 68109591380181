import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import arraySlicer from "../../utils/arraySlicer";


export const fetchEventsSlides = createAsyncThunk(
    'users/fetchEventsSlides',
    async function(state,{rejectWithValue}){
        try{
            console.log(state.id)
            const res = await axios.get(`/api/sequences/${state.id}`,{
                headers:{
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    cam_code: state.currentCamera,
                }
            })
            const data = await res.data
            console.log(data)
            return data.data
        } catch (err){
            return rejectWithValue(err.response.data.errors.code)
        }

    }
)


const initialState = {
    eventSlides:[],
    eventsCurrentPreviewSlides:[],
    sliderPos:0,
    isSliderPlay:true,
    canShow:false,
    isLoading:false,
    isError:false,
    errorCode:'',
}

export const eventsSlidesSlice = createSlice({
    name: 'cams',
    initialState,
    reducers: {
        autoMoveSlider: (state,action) => {
            if(action.payload.maxValue > state.sliderPos+1){
                state.sliderPos += 1
            }else{
                state.sliderPos = 0
            }
        },
        setSliderPos: (state,action) => {
            state.sliderPos = action.payload
        },
        stopSlider: (state,action) => {
            state.isSliderPlay = !state.isSliderPlay
        },
        clearSlider: (state,action) => {
            state.sliderPos = 0
        },
        setCanShow: (state,action) => {
            state.canShow = action.payload
        },
        addPreviewSlides: (state,action) => {
            state.eventsCurrentPreviewSlides = arraySlicer(action.payload.slides,action.payload.pos)[0]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEventsSlides.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchEventsSlides.fulfilled, (state, action) => {
            state.eventSlides = action.payload
            state.eventsCurrentPreviewSlides = arraySlicer(action.payload,20)[0]
            state.isError = false
            state.isLoading = false

        })
        builder.addCase(fetchEventsSlides.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorCode = action.payload
        })
    },
})


export const {autoMoveSlider,setSliderPos,clearSlider,stopSlider,setCanShow,addPreviewSlides} = eventsSlidesSlice.actions

export default eventsSlidesSlice.reducer
