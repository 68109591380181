import React, {useState} from 'react';
import s from "../../FacesSearch/FacesSearchTable/FacesSearchTable.module.scss";

import {useTranslation} from "react-i18next/hooks";
import {useSelector} from "react-redux";
import {PROXY} from "../../../../utils/pathss";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import Modal from "../../../UI/Modal/Modal";
import TablePerson from "./tablePerson/TablePerson";
import {set} from "react-hook-form";

const PersonsTable = ({persons}) => {
    // const personsList = useSelector(state => state.persons.persons)

    const [isShowImage,setIsShowImage] = useState(false)
    const [image,setImage] = useState('')
    const [t,i18n] = useTranslation()
    const setFullImage = (path) => {
        setImage(path)
        setIsShowImage(true)
    }
    return (
        <table className={s.table}>
            <thead>
            <tr className={s.table__rowTitle}>
                <th className={s.table__cellTitle}>
                    {t('persons.persons.table.photo')}
                </th>
                <th className={s.table__cellTitle}>
                    {t('persons.persons.table.name')}
                </th>
                <th className={s.table__cellTitle}>
                    {t('persons.persons.table.firstVisit')}
                </th>

                <th className={s.table__cellTitle}>
                    {t('persons.persons.table.lastVisit')}
                </th>
                <th className={s.table__cellTitle}>
                    {t('persons.persons.table.allVisit')}
                </th>
                <th className={s.table__cellTitle}>
                    {t('persons.persons.table.thisMonth')}
                </th>
            </tr>
            </thead>
            <tbody>
            {persons?.map((elem,id) =>
                <TablePerson elem={elem} setFullImage={setFullImage} key={id}/>
            )

            }

            </tbody>
            {isShowImage
                &&
                <Modal setShowModal={setIsShowImage}>
                    <img onClick={() => setIsShowImage(false)} src={image} alt=""/>
                </Modal>
            }
        </table>
    );
};

export default PersonsTable;
