import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import historyForDateSlicer from "../../utils/historyForDateSlicer";
import historyUniqueSort from "../../utils/historyUniqueSort";
import getDayEndTime from "../../utils/getDayEndTime";

export const fetchHistory = createAsyncThunk(
    'onePerson/fetchHistory',
    async function(state,{rejectWithValue}) {
        try {
            const res = await axios.get(`/api/face_events`, {
                headers: {
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    cam_code:state.currentCamera,
                    dt_start:state.date.datStart,
                    dt_end:state.date.datEnd
                }
            })
            const array = await res.data.data
            return historyUniqueSort(array)
        } catch (err) {
            return rejectWithValue(err.response.data.errors.code)
        }
    }
)
export const fetchLatestHistory = createAsyncThunk(
    'onePerson/fetchLatestHistory',
    async function(state,{rejectWithValue}) {
        try {
            const res = await axios.get(`/api/face_events`, {
                headers: {
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    cam_code:state.currentCamera,
                    dt_end:state.date.datEnd,
                    dt_start:state.date.datStart,
                    last_rid:state.last_rid
                }
            })
            const array = await res.data.data
            console.log(array,state.lateHistory)
            return historyUniqueSort(state.lateHistory.concat(array))
        } catch (err) {
            return rejectWithValue(err.response.data.errors.code)
        }
    }
)

const initialState = {
    history:[],
    filteredHistory:[],
    historyDate:{datStart:null, datEnd:getDayEndTime(Date())},
    currentCamera:localStorage.getItem('historyCamera') || '' ,
    currentCameraName:'',
    filterGroup:2,
    isLoading:false,
    isLatestLoading:false,
    isError:false,
    errorCode:'',
}

export const facesHistorySlice = createSlice({
    name: 'facesHistory',
    initialState,
    reducers: {
        changeHistoryDate : (state,action) => {
            state.historyDate = action.payload
        },
        setCurrentCamera: (state,action) => {
            state.currentCamera = action.payload
        },
        setCurrentCameraName: (state,action) => {
            state.currentCameraName = action.payload
        },
        changeFilterGroup:(state,action) => {
            state.filterGroup = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHistory.pending, (state, action) => {
            state.currentPage = 0
            state.isLoading = true
            state.isError = false
        })
        builder.addCase(fetchHistory.fulfilled, (state, action) => {
            state.history = action.payload
            state.isError = false
            state.filteredHistory = historyForDateSlicer(action.payload)
            state.isLoading = false
        })
        builder.addCase(fetchHistory.rejected, (state, action) => {
            state.isError = true
            state.errorCode = action.payload
        })
        builder.addCase(fetchLatestHistory.pending, (state, action) => {
            state.isError = false
            state.isLatestLoading = true
        })
        builder.addCase(fetchLatestHistory.fulfilled, (state, action) => {
            state.history = action.payload
            state.filteredHistory = historyForDateSlicer(action.payload)
            state.isError = false
            state.isLatestLoading = false

        })
        builder.addCase(fetchLatestHistory.rejected, (state, action) => {
            state.isError = true
            state.errorCode = action.payload
            state.isLatestLoading = false
        })
    },
})
export const {changeHistoryDate,changeFilterGroup,setCurrentCamera,setCurrentCameraName} = facesHistorySlice.actions

export default facesHistorySlice.reducer
