import React, {useEffect, useState} from 'react';
import s from './AdminRawdataSettings.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useDebounce} from "../../../Hooks/useDebounce/useDebounce";
import {setId} from "../../../Redux/Slices/rawdataSlice";
const AdminRawdataSettings = () => {
    const [value,setValue] = useState(1)
    const debounced = useDebounce(value,800)
    const rawdata = useSelector(state => state.rawdata.rawdata)
    const dispatch = useDispatch()

    useEffect(() => {
        if(debounced){
            dispatch(setId(debounced))
        }
    },[debounced])

    const fetchNextRawdata = () => {
        setValue(rawdata[rawdata.length-1].id + 1)

    }

    return (
        <div className={s.settings}>
            <input value={value} onChange={(e) => setValue(e.target.value)} className={s.settings__input}  type="text"/>
            {rawdata.length>0
                &&
                <div className={s.settings__value}>
                    загружено {rawdata[0].id} - {rawdata[rawdata.length-1].id} (вобщем {rawdata.length})
                </div>
            }
            <button onClick={fetchNextRawdata} className={s.settings__button}>
                Загрузить еще
            </button>

        </div>
    );
};

export default AdminRawdataSettings;