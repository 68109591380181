import {mainApi} from "../testAPI";
import axios from "axios";

export const locationApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getLocations: builder.query({
            queryFn: async (arg) => {
                try {
                    const res = await axios.get('/api/location',{
                        headers:{
                            "Authorization": localStorage.getItem('token')
                        }
                    })
                    const data = await res.data.data.locations
                    return {data:data}
                }catch (e){
                    return {error:e.message}
                }
            },
            providesTags: () => [{type:'Location'}],
        }),
        postLocations: builder.mutation({
            queryFn:async ({name,address}) => {
                try{
                    const res = await axios.post('/api/location',{
                            name:name,
                            description:null,
                            address: {
                                building:address.building,
                                city:address.city,
                                street:address.street
                            }
                        },
                        {
                            headers:{
                                "Authorization": localStorage.getItem('token')
                            }
                        })
                    const data = await res.data
                    return data.data
                }catch (e){
                    return {error:e.message}
                }
            },
            invalidatesTags: () => [{type: 'Location'}]
        })
    }),
})

export const { useGetLocationsQuery, usePostLocationsMutation} = locationApi