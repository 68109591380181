import React, {useRef} from 'react';
import s from "../../../pages/reports/ReportsVisitors/ReportsVisitors.module.scss";
import {accentColor} from "../../../utils/styles";
import {changeReportDate, setStyle} from "../../../Redux/Slices/VisitorsSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartArea, faChartColumn} from "@fortawesome/free-solid-svg-icons";
import VisitorsChart from "../VisitorsChart";
import VisitorsInformation from "../visitorsInformation/VisitorsInformation";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next/hooks";
import VisitorsReportsBanner from "../VisitorsReportsBanner/VisitorsReportsBanner";
import {useGetLocationsQuery} from "../../../Redux/APIs/testAPI";
import calcDateDiff from "../../../utils/calcDateDiff";
import getDayStartTime from "../../../utils/getDayStartTime";
import getDayEndTime from "../../../utils/getDayEndTime";

const ReportsVisitorsContent = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.visitorsReport.isLoading)
    const visitors = useSelector(state => state.visitorsReport.visitors)
    const reportDateFirst = useSelector(state => state.visitorsReport.reportDateFirst)
    const reportDateSecond = useSelector(state => state.visitorsReport.reportDateSecond)
    const currentCamera = useSelector(state => state.visitorsReport.currentCamera)
    const currentCameraName = useSelector(state => state.visitorsReport.currentCameraName)
    const mostActiveTime = useSelector(state => state.visitorsReport.mostActiveTime)
    const chartStyle = useSelector(state => state.visitorsReport.chartStyle)
    const camsList = useSelector(state => state.auth.currentUser.cameras)
    const boxRef = useRef()
    const visitorsCount = useSelector(state => state.visitorsReport.visitorsCount)
    const [t,i18n] = useTranslation()

    const fetchDay = (e) => {
        const diff = calcDateDiff(reportDateFirst,reportDateSecond)
        console.log(e)
        if(diff > 1){
            const dateStart =  getDayStartTime(new Date(`${e.activePayload[0].payload.time}.${e.activePayload[0].payload.year}`).toISOString())
            const dateEnd = getDayEndTime(new Date(`${e.activePayload[0].payload.time}.${e.activePayload[0].payload.year}`).toISOString())
            dispatch(changeReportDate({firstDate: dateStart, secondDate:dateEnd}))
        }
    }
    const getDate = (date,opt) => {
        if(opt === 1){
            const dat = new Date(date)
            dat.setDate(dat.getDate())
            return `${dat.getDate()} ${dat.toLocaleString('ru', { month: 'long' }).replace('ь','я')}`
        }else if(opt){
            const dat = new Date(date)
            dat.setDate(dat.getDate())
            return `${reportDateSecond} ${dat.toLocaleString('ru', { month: 'long' }).replace('ь','я')}`
        }
    }
    const ggetDate = (date) => {
        const datt = new Date(date)
        return `${datt.getDate()} ${datt.toLocaleString(i18n.resolvedLanguage, { month: 'long' }).replace('ь','я')}`

    }
    return (
        <div className={s.visitors} ref={boxRef}>
                <div className={s.visitors__title}>
                        {reportDateSecond === reportDateFirst
                            ?
                            <div>
                                {t('visitorsReport.visitorsFor')} <span style={{color:accentColor}}>{ggetDate(reportDateFirst)}</span>
                                {'\n'} {t('visitorsReport.fromThe')} <span style={{color:accentColor}}>{currentCameraName}</span>
                            </div>
                            :
                            <div>
                                {t('visitorsReport.visitorsPeriod')} <span style={{color:accentColor}}>{ggetDate(reportDateFirst)}</span> {t('visitorsReport.to')} <span style={{color:accentColor}}>{ggetDate(reportDateSecond)}</span>
                                {'\n'}{t('visitorsReport.fromThe')} <span style={{color:accentColor}}>{currentCameraName}</span>
                            </div>
                        }
                    </div>
                <VisitorsInformation
                    mostActiveTime={mostActiveTime}
                    getDate={getDate}
                    currentCamera={currentCamera}
                    camsList={camsList}
                    isLoading={isLoading}
                    reportDateSecond={reportDateSecond}
                    reportDateFirst={reportDateFirst}
                />
            <div className={s.visitors__chart}>
                {visitors.length === 0 || (visitorsCount.visitors === 0 && visitorsCount.traffic === 0)
                    ?
                    <></>
                    :
                    <div className={s.visitors__chartStyle} onClick={() => dispatch(setStyle(chartStyle === 'area' ? 'column' : 'area'))}>
                        <div className={s.visitors__chartStyleIcon}>
                            {chartStyle === 'column'
                                ?
                                <FontAwesomeIcon icon={faChartArea}/>
                                :
                                <FontAwesomeIcon icon={faChartColumn}/>
                            }
                        </div>
                        <span>
                            {t('visitorsReport.changeChartView')}
                        </span>
                    </div>
                }
                {visitors.length === 0 || (visitorsCount.visitors === 0 && visitorsCount.traffic === 0)
                    ?
                        <VisitorsReportsBanner/>
                    :
                        <div className={s.visitors__chartBox}>
                            <VisitorsChart visitors={visitors} chartStyle={chartStyle} fetchDay={fetchDay}/>
                        </div>
                }

            </div>


        </div>
    );
};

export default ReportsVisitorsContent;
