import React, {useRef, useState} from 'react';
import {Calendar} from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import s from "../DateRangeCalend/DateRangeCalend.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay, faX} from "@fortawesome/free-solid-svg-icons";
import GreenButton from "../../UI/GreenButton/GreenButton";
import {useClickOutside} from "../../../Hooks/useClickOutside/useClickOutside";
import TimeInput from "../../UI/TimeInput/TimeInput";
import {useTranslation} from "react-i18next/hooks";
import getTimeAndDate from "../../../utils/getTimeAndDate";
import getDayEndTime from "../../../utils/getDayEndTime";
import getDayStartTime from "../../../utils/getDayStartTime";
import TimeRangeInput from "../../UI/TimeRangeInput/TimeRangeInput";

const DateCalend = (props) => {
    const [date, setDate] = useState(null);
    const [open,setOpen] = useState(false)
    const [time,setTime] = useState('')

    const dispatchDateWithoutTimeSpan = (newValue) => {
        const datStart = new Date(newValue)
        if(time !== '' && time !== '00:00'){
            datStart.setHours(time.slice(0,2))
            datStart.setMinutes(time.slice(3,5))
            props.changeDate({datStart:datStart.toISOString(),datEnd:null})
        }else if(time === '' || time === '00:00'){
            props.changeDate({datEnd:null,datStart:getDayStartTime(newValue)})
        }
    }
    const dispatchDate = (newValue) => {
        const vTime = time.replace(/\D/g, '')
        const timeStart = vTime.slice(0,4)
        const timeEnd = vTime.slice(4,8)
        const datStart = new Date(newValue)
        const datEnd = new Date(newValue)
        if(vTime !== '' && vTime !== '00000000'){
            datStart.setHours(timeStart.slice(0,2))
            datStart.setMinutes(timeStart.slice(2,4))
            datEnd.setHours(timeEnd.slice(0,2))
            datEnd.setMinutes(timeEnd.slice(2,4))
            props.changeDate({datStart:datStart.toISOString(),datEnd:datEnd.toISOString()})
        }else if(vTime === '' || vTime === '00000000'){
            props.changeDate({datStart:null,datEnd:getDayEndTime(newValue)})
        }
        setOpen(false)
    }

    const handleOutsideClick = () => {
        setOpen(false)
    }
    const calendRef = useRef()
    const [t,i18n] = useTranslation()
    useClickOutside(calendRef,handleOutsideClick)
    return (
        <div>
            <div ref={calendRef}>

                <div className={s.datarange__input}>
                    <div className={s.datarange__icon} onClick={() => setOpen(!open)}>
                        <FontAwesomeIcon icon={faCalendarDay} width={14}/>
                        <span>

                            {props.date
                                ?
                                props.date.datEnd
                                    ?
                                    getTimeAndDate('date',props.date.datEnd)
                                    :
                                    getTimeAndDate('date',props.date.datStart)
                                :
                                t('UI.dateSelect.dateSelect')
                            }
                        </span>
                    </div>
                </div>

                {open
                    &&
                    <div className={s.datarange__calend}>
                        <Calendar onChange={item => {
                            setDate(item)

                        }}
                                  locale={locales[i18n.resolvedLanguage]}
                                  date={date} />
                        {props.timeSpan
                            ?
                                <div className={s.datarange__bottomPanel}>
                                    {/*{t('UI.dateSelect.startTime')}:*/}
                                    <TimeRangeInput time={time} setTime={setTime}/>
                                    <div className={s.datarange__clear} onClick={() =>  {
                                        setTime('')
                                    } }>
                                        <FontAwesomeIcon icon={faX}/>
                                    </div>
                                </div>
                            :
                                <div className={s.datarange__bottomPanel}>
                                    <TimeInput time={time} setTime={setTime}/>
                                    <div className={s.datarange__clear} onClick={() =>  {
                                        setTime('')
                                    } }>
                                        <FontAwesomeIcon icon={faX}/>
                                    </div>
                                </div>
                        }
                        <GreenButton light={true} onClick={() => {
                            if(date !== null){
                                if(props.timeSpan){
                                    dispatchDate(date)
                                }else if(!props.timeSpan){
                                    dispatchDateWithoutTimeSpan(date)
                                }

                            }else{
                                const dat = getDayStartTime(new Date())
                                if(props.timeSpan){
                                    dispatchDate(dat)
                                }else if(!props.timeSpan){
                                    dispatchDateWithoutTimeSpan(dat)
                                }
                            }
                        }
                        }
                        >
                            {t('UI.dateSelect.apply')}
                        </GreenButton>

                    </div>

                }

            </div>

        </div>
    );
};

export default DateCalend;
