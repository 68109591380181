import React, {useState} from 'react';
import s from '../camStep.module.css'
import JustInput from "../../../../UI/JustInput/JustInput";
import GreenButton from "../../../../UI/GreenButton/GreenButton";
import {useTranslation} from "react-i18next/hooks";

const CamStep1 = (props) => {
    const [id,setId] = useState('')
    const goToNextStep = () => {
        if( !props.last && id){
            props.setStep(props.step+1)
            props.setCamId(id)
        }

    }
    const [t,i18n] = useTranslation()
    return (
        <div>
            <div className={s.camstepWrapper}>
                <div className={s.camstep__boldText}>
                    {props.text}
                </div>
                <div className={s.camstep__lightText}>
                    {props.subtext}
                </div>
                <JustInput value={id} onChange={(e) => setId(e.target.value)} />
                <div>
                    <GreenButton onClick={() => goToNextStep()} >
                        {t('default.continue')}
                    </GreenButton>
                </div>

            </div>
        </div>
    );
};

export default CamStep1;