import React, {useState} from 'react';
import s from './VisitorsChart.module.css'
import {
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Area,
    BarChart,
    Bar,
    ResponsiveContainer, Legend,
} from 'recharts';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next/hooks";
const VisitorsChart = (props) => {
    const isLoading = useSelector(state => state.visitorsReport.isLoading)
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={s.tooltip} >
                    <p >{`Трафик : ${payload[0].value}`}</p>
                    <p >{`Посетителей : ${payload[1].value}  (${payload[1].value === 0 ? '100' : Math.round(payload[1].value/payload[0].value *100)}%)`}</p>
                </div>
            );
        }

        return null;
    };
    const [t,i18n] = useTranslation()
    return (
        <>
            {props.chartStyle === 'area'
                ?
                    <ResponsiveContainer>
                        <AreaChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} data={props.visitors} onClick={(e) => {
                            props.fetchDay(e)
                        }}>
                            <CartesianGrid  strokeDasharray="3 3" color={'red'}/>
                            {isLoading === false && props.visitors.length > 0
                                &&
                                <>
                                    <Area onClick={(e) => props.fetchDay(e)} legendType={'square'} name={t('visitorsReport.traffic')} type="monotone" dataKey="numTraffic" stroke="#F0A58F" fill={"#F0A58F"} />
                                    <Area legendType={'square'} name={t('visitorsReport.visitors')} type="monotone" dataKey="numVisitors" stroke="#DB4CB2" fill={'#DB4CB2'}  />
                                </>


                            }
                            <XAxis stroke={'gray'} dataKey='time' tickMargin={10} />
                            <YAxis tickSize={1} stroke={'gray'} axisLine={true}  tickMargin={10} tickLine={false} tickCount={8}/>
                            <Tooltip content={<CustomTooltip/>}/>
                            <Legend></Legend>
                        </AreaChart>
                    </ResponsiveContainer>

                :
                    <ResponsiveContainer>
                    <BarChart
                        onClick={(e) => props.fetchDay(e)}
                        data={props.visitors}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <Bar legendType={'square'} name={t('visitorsReport.traffic')} dataKey="numTraffic" fill={"#F0A58F"} stroke={"#F0A58F"} />
                        <Bar legendType={'square'} name={t('visitorsReport.visitors')} dataKey="numVisitors" fill={'#DB4CB2'} stroke={'#DB4CB2'} />
                        <XAxis stroke={'gray'} dataKey='time' tickMargin={10} />
                        <YAxis tickSize={1} stroke={'gray'} axisLine={true}  tickMargin={10} tickLine={false} tickCount={8}/>
                        <Tooltip cursor={false} content={<CustomTooltip/>}/>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Legend></Legend>
                    </BarChart>
                </ResponsiveContainer>
            }



        </>
    );
};

export default VisitorsChart;
