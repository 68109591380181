import React from 'react';
import s from './PersonReportsToolbar.module.scss'
import PersonReportsSelect from "../PersonReportsSelect/PersonReportsSelect";
import DateCalend from "../../ViewSettings/DateCalend/DateCalend";
import {changeHistoryDate} from "../../../Redux/Slices/facesHistorySlice";
import {useDispatch, useSelector} from "react-redux";
import DateRangeCalend from "../../ViewSettings/DateRangeCalend/DateRangeCalend";
import {changeReportDate} from "../../../Redux/Slices/VisitorsSlice";
import {changeAttendanceDate} from "../../../Redux/Slices/personReportSlice";
const PersonReportsToolbar = () => {
    const dispatch = useDispatch()
    const changeDate = (firstDate,secondDate) => {
        dispatch(changeAttendanceDate({datStart:firstDate,datEnd:secondDate}))
    }

    return (
        <div className={s.toolbar}>
            <PersonReportsSelect/>
            <DateRangeCalend chDate={changeDate} />
        </div>
    );
};

export default PersonReportsToolbar;