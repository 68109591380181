import {createSlice, createAsyncThunk, current} from '@reduxjs/toolkit'
import axios from "axios";
import getDayStartTime from "../../utils/getDayStartTime";
import getDayEndTime from "../../utils/getDayEndTime";
import visitsHistoryConvert from "../../utils/visitsHistoryConvert";
import calcDateDiff from "../../utils/calcDateDiff";
import visitsHistoryConvertDays from "../../utils/visitsHistoryConvertDays";


export const fetchVisitsHistory = createAsyncThunk(
    'visitsHistoryReport/fetchVisitsHistory',
    async function(state,{rejectWithValue}){
        const dataStart = new Date(state.datStart)
        const dataEnd = new Date(state.datEnd)
        try{
            const res = await axios.get(`/api/counter/visitors/history`,{
                headers:{
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    loc_code:state.currentLocation,
                    dt_start:state.datStart,
                    dt_end:state.datEnd,
                }
            })
            const data = await res.data.data.visitors_history
            return {history:data,dateStart:dataStart,dateEnd:dataEnd}
        }catch (err){
            return rejectWithValue(err.response.data)
        }

    }
)



const initialState = {
    visitsHistory:[],
    visitsHistorySorted:[],
    visitsHistoryCharts:[],
    currentLocation:localStorage.getItem('visitsHistoryReportLocation') || '',
    visitsHistoryReportDate:{datStart:getDayStartTime(Date()), datEnd:getDayEndTime(Date())},
    sort:'',
    minVisitsNum:0,
    ageSort:'',
    genderSort:0,
    isLoading:false,
    isError:false,
    errorCode:'',

}

export const visitsHistoryReport = createSlice({
    name: 'visitsHistoryReport',
    initialState,
    reducers: {
        setCurrentLocation: (state,action) => {
            state.currentLocation = action.payload
            localStorage.setItem('visitsHistoryReportLocation', action.payload)
        },
        changeVisitsHistoryReportDate : (state,action) => {
            state.visitsHistoryReportDate = {datStart: action.payload.datStart,datEnd:action.payload.datEnd}
        },
        sortVisitsHistorySum:(state,action) => {
            const currVisitsHistory = [...current(state.visitsHistorySorted)]
            const sortedList = currVisitsHistory.sort((x, y) => y.totalVisits - x.totalVisits);
            state.sort = 'sum'
            state.visitsHistorySorted = sortedList
        },
        sortVisitsHistoryMonth:(state,action) => {
            const currVisitsHistory = [...current(state.visitsHistorySorted)]
            const sortedList = currVisitsHistory.sort((x, y) => y.history.filter(elem => elem.date === action.payload.date)[0].history.length - x.history.filter(elem => elem.date === action.payload.date)[0].history.length)
            state.sort = action.payload.date
            state.visitsHistorySorted = sortedList
        },
        sortVisitsHistoryDay:(state,action) => {
            const currVisitsHistory = [...current(state.visitsHistorySorted)]
            const sortedList = currVisitsHistory.sort((x, y) => y.history.filter(elem => elem.date === action.payload)[0].history.length - x.history.filter(elem => elem.date === action.payload)[0].history.length)
            state.sort = action.payload
            state.visitsHistorySorted = sortedList
        },
        sortVisitsHistoryMinVisits:(state,action) => {
            const currVisitsHistory = [...current(state.visitsHistory)]
            const sortedList = currVisitsHistory.filter(elem => elem.totalVisits >= action.payload.minVisits)
            state.visitsHistorySorted = sortedList
            state.visitsHistoryCharts = sortedList
            state.minVisitsNum = action.payload.minVisits
            state.genderSort = 0
        },
        sortVisitsHistoryGender:(state,action) => {
            const currVisitsHistory = [...current(state.visitsHistory)]
            const genders = ['All','Male',"Female"]
            if(action.payload.gender === 0){
                const sortedList = currVisitsHistory.filter(elem => elem.totalVisits >= action.payload.minVisitsNum)
                state.visitsHistoryCharts = sortedList
                state.visitsHistorySorted = sortedList
            }else if(action.payload.gender !== 0){
                const sortedList = currVisitsHistory.filter(elem => elem.personGender === genders[action.payload.gender] && elem.totalVisits >= action.payload.minVisitsNum)
                state.visitsHistoryCharts = sortedList
                state.visitsHistorySorted = sortedList
            }
            state.genderSort = action.payload.gender
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVisitsHistory.pending, (state, action) => {
            state.isLoading = true

        })
        builder.addCase(fetchVisitsHistory.fulfilled, (state, action) => {
            const dateStart = new Date(action.payload.dateStart)
            const dateEnd =  new Date(action.payload.dateEnd)
            const datesDiff = calcDateDiff(dateStart, dateEnd)
            const lastDay = new Date(dateStart);
            lastDay.setMonth(lastDay.getMonth()+1)
            lastDay.setDate(0)
            if(datesDiff <= lastDay.getDate() && dateStart.getMonth() === dateEnd.getMonth()){
                const convertedList = visitsHistoryConvertDays(action.payload.history,action.payload.dateStart,action.payload.dateEnd )
                state.visitsHistory = convertedList
                state.visitsHistoryCharts = convertedList
                state.visitsHistorySorted = convertedList
            }else if(datesDiff > 14 || dateStart.getMonth() !== dateEnd.getMonth()){
                const convertedList = visitsHistoryConvert(action.payload.history,action.payload.dateStart,action.payload.dateEnd )
                state.visitsHistory = convertedList
                state.visitsHistoryCharts = convertedList
                state.visitsHistorySorted = convertedList
            }
            state.isLoading = false
            state.isError = false
        })
        builder.addCase(fetchVisitsHistory.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
        })
    },
})


export const {sortVisitsHistoryDay,setCurrentLocation,changeVisitsHistoryReportDate,sortVisitsHistorySum,sortVisitsHistoryMonth,sortVisitsHistoryMinVisits,sortVisitsHistoryGender} = visitsHistoryReport.actions

export default visitsHistoryReport.reducer
