import React from 'react';
import s from './JustInput.module.css'
const JustInput = (props) => {
    return (
        <div>
            <input {...props} className={s.input} style={{width:`${props.width}px`}} />
        </div>
    );
};

export default JustInput;