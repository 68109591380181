import React, {useEffect, useState} from 'react';
import s from './ToTopScroller.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
const ToTopScroller = () => {
    const [scroll,setScroll] = useState(0)
    useEffect(() => {
        document.addEventListener('scroll',handleScroll)
        return () => document.removeEventListener('scroll',handleScroll)
    },[])

    const handleScroll = () => {
        setScroll(document.documentElement.clientHeight + window.scrollY)
    }
    return (
        <>
            {scroll > 2500
                &&
                <div className={s.toTop} onClick={() => window.scrollTo({left:0,top:0,behavior: "smooth"})}>
                    <FontAwesomeIcon icon={faArrowUp} size={'xl'} color={'#BFBFBF'}/>
                </div>
            }

        </>
    );
};

export default ToTopScroller;