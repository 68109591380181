import React, {useState} from 'react';
import s from './AddLocation.module.scss'
import JustInput from "../../UI/JustInput/JustInput";
import GreenButton from "../../UI/GreenButton/GreenButton";
import JustInputFull from "../../UI/JustInputFull/JustInputFull";
import {useTranslation} from "react-i18next/hooks";
const AddLocation = ({addLocation}) => {
    const [name,setName] = useState('')
    const [address,setAddress] = useState({building:'',city:'',street:''})
    const [t,i18n] = useTranslation()
    return (
        <div className={s.addLocation}>
            <div className={s.addLocation__title}>
                {t('locations.addLocationModal.addLocation')}
            </div>
            <div className={s.addLocation__inputs}>
                <JustInputFull placeholder={t('default.name')} value={name} onChange={(e) => setName(e.target.value)} />
                <JustInputFull placeholder={t('locations.addLocationModal.city')} value={address.city} onChange={(e) => setAddress({...address,city:e.target.value})}/>
                <JustInputFull placeholder={t('locations.addLocationModal.street')} value={address.street} onChange={(e) => setAddress({...address,street:e.target.value})}/>
                <JustInputFull placeholder={t('locations.addLocationModal.buildingNumber')} value={address.building} onChange={(e) => setAddress({...address,building:e.target.value})}/>
            </div>
            <div className={s.addLocation__button}>
                <GreenButton onClick={() => addLocation({name:name,address:address}) }>
                    {t('default.add')}
                </GreenButton>
            </div>

        </div>
    );
};

export default AddLocation;