import React from 'react';
import s from './MainBackground.module.scss'
import logo from "../../../public/Icons/VectorLogo.svg";
import bigCamera from "../../../public/images/cctv-g74708e4c5_1280 1.webp";
const MainBackground = () => {
    return (
        <div className={s.bgbox}>
            {/*<div className={s.bg1}>*/}
            {/*</div>*/}
            {/*<div className={s.bg2}>*/}
            {/*</div>*/}
            {/*<div className={s.bg3}>*/}
            {/*    <img src={logo} alt=""/>*/}
            {/*</div>*/}

            <img className={s.bgbox__img} src={bigCamera} alt=""/>

            <div className={s.polucircle}>
            </div>
        </div>
    );
};

export default MainBackground;
