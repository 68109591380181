import React from 'react';
import s from './CamStepFrame.module.css'
import back from '../../../../public/Icons/back.png'
import GreenButton from "../../../UI/GreenButton/GreenButton";
import {faArrowLeft, faArrowRight, faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalBackCross from "../../../UI/Modal/ModalBackCross";
import {useTranslation} from "react-i18next/hooks";

const CamStepFrame = (props) => {
    const [t,i18n] = useTranslation()
    return (
        <div className={s.camstepsframe}>
            {props.children}
            <div className={s.camstepsframe__steps}>
                {
                    props.steps.map((item, index) =>
                        <div className={s.camstepsframe__stepBox} style={{borderBottom: `3px solid ${props.step > index ? '#109CF1' : 'rgba(1,1,1,0)'}`}} >
                            <div className={props.step > index ? s.camstepsframe__stepDone : s.camstepsframe__step}>
                                <div className={ index === props.step ? s.camstepsframe__number__active : s.camstepsframe__number}>
                                    {props.step > index
                                        ?
                                        <FontAwesomeIcon icon={faCheck} size={'xs'} color={'#08979C'}/>
                                        :
                                        item.id
                                    }
                                </div>
                                <div className={s.camstepsframe__title}>
                                    {t('camerasPage.addCameraModal.step')}
                                </div>

                                {index !== props.steps.length-1 &&
                                    <FontAwesomeIcon icon={faArrowRight} color={'#bfbfbf'}/>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default CamStepFrame;