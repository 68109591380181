import React, {useState} from 'react';
import Toolbar from "../../../components/toolbar/Toolbar";
import s from './Persons.module.css'
import Modal from "../../../components/UI/Modal/Modal";
import PersonsModal from "../../../components/Faces/Persons/PersonsModal/PersonsModal";
import {useDispatch, useSelector} from "react-redux";
import PersonsGroupList from "../../../components/Faces/Persons/PersonsGroupsList/PersonsGroupList";
import {logout} from "../../../Redux/Slices/authSlice";
import PersonsToolbar from "../../../components/Faces/Persons/PersonsToolbar/PersonsToolbar";
import Loader from "../../../components/UI/Loader/Loader";

const Persons = () => {
    const [modalList, setModalList] = useState(false)
    const errorCode = useSelector(state => state.persons.errorCode)
    const isLoading = useSelector(state => state.persons.isLoading)
    const dispatch = useDispatch()
    if(errorCode === 401){
        dispatch(logout())
    }
    return (
        <div className={s.personsbox}>
            <Toolbar title={'Персоны'}>
                <PersonsToolbar setModalList={setModalList} modalList={modalList} />
            </Toolbar>
            {!isLoading
                ?
                <PersonsGroupList/>
                :
                <Loader/>
            }
            {
                modalList
                    &&
                    <Modal setShowModal={setModalList}>
                        <PersonsModal closeModal={setModalList} />
                    </Modal>
            }
        </div>
    );
};

export default Persons;