import moment from "moment";
import getTimeAndDate from "./getTimeAndDate";

const visitsHistoryConvert = (history,dateStart,dateEnd) => {
    const datStart = moment(dateStart)
    const datEnd= moment(dateEnd)
    const monthDiff = datEnd.diff(datStart,'months')
    const convertedHistory = []
    for(let i in history){
            const monthHistory = []
            const dateSartCopy = new Date(dateStart)
            for(let i = 0; i < monthDiff + 1; i++){
                monthHistory.push({date:getTimeAndDate('date',dateSartCopy ).slice(3,10),month:+getTimeAndDate('date',dateSartCopy ).slice(3,5),year:+getTimeAndDate('date',dateSartCopy ).slice(6,10),history: []})
                dateSartCopy.setMonth(dateSartCopy.getMonth()+1)
            }
            const personHistory = history[i].events
            const allDays = []
            for(let j in personHistory){
                const historyElemDate = getTimeAndDate('date',personHistory[j].dt ).slice(3,10)
                allDays.push(getTimeAndDate('date',personHistory[j].dt ))
                const currentMonth = monthHistory.find(elem => elem.date === historyElemDate)
                if(currentMonth){
                    if(!currentMonth.history.find(elem => getTimeAndDate('date',elem.dt) === getTimeAndDate('date', personHistory[j].dt))){
                        currentMonth.history?.push(personHistory[j])
                    }
                }
            }
            const daysCount = allDays.reduce((acc, item) => {
                if (acc.includes(item)) {
                    return acc;
                }
                return [...acc, item];
            }, []);
            const newElem = {person_id:i,
                personGender:history[i].info.gender,
                personAge:history[i].info.age,
                personImg:history[i].info.thumbnail,
                totalVisits:daysCount.length,
                history: monthHistory}
            convertedHistory.push(newElem)


    }
    return convertedHistory
}
export default visitsHistoryConvert;
