const visitorsRefactor = async (dat, data) => {
    try{
        dat = new Date(dat)
        const datMonth = `${dat.getMonth()+1}`
        const datText = +`${dat.getFullYear()}${datMonth.length > 1 ? '' : '0'}${datMonth}${String(dat.getDate()).length === 1 ? '0'+dat.getDate() : dat.getDate()}`
        const reformVisitorsData = data.visitors[datText]
        const reformTrafficData = data.traffic[datText]
        const dataMass = []
        for(let i in (reformTrafficData || reformVisitorsData)){
            let trafficNum = 0;
            let visitorsNum = 0
            if(reformTrafficData){
                trafficNum = reformTrafficData[i] ? reformTrafficData[i]?.num : 0
            }
            if(reformVisitorsData){
                visitorsNum = reformVisitorsData[i] ? reformVisitorsData[i]?.num : 0
            }
            const newElem = {numVisitors:visitorsNum, numTraffic:trafficNum, time:`${+i}:00`}
            dataMass.push(newElem)
        }

        return dataMass

    }catch (err){
        return []
    }

}

export default visitorsRefactor;
