import React from 'react';
import s from './PersonsContent.module.scss'
import PaginationControl from "../../../UI/PaginationControl/PaginationControl";
import {useDispatch, useSelector} from "react-redux";
import {backPagination, goPagination, setPagination} from "../../../../Redux/Slices/personSlice";
import PersonsTable from "../PersonsTable/PersonsTable";
import PersonsGroupInfo from "../PersonsGroupInfo/PersonsGroupInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";

const PersonsContent = ({persons,totalPages,isLoading,totalNum,listType,totalNumFreq,setModalPerson}) => {
    const currentPage = useSelector(state => state.persons.currentPage)
    const dispatch = useDispatch()
    const changePage = (opt) => {
        if(opt === 'go'){
            dispatch(goPagination())
        }else if (opt === 'back'){
            dispatch(backPagination())
        }else if (typeof opt == "number"){
            dispatch(setPagination(opt))
        }
    }
    const so = (num) => {
        const d = []
        for(let i = 0; i< num; i++){
            d.push({id:i})
        }
        return d
    }
    return (
        <div className={s.historybox}>
            <div className={s.information}>
                <div className={s.information__controls}>
                    <div className={s.information__add} onClick={() => setModalPerson(true)}>
                        <FontAwesomeIcon icon={faUserPlus} size={'1x'}/>
                    </div>
                    <PaginationControl disabled={isLoading} list={so(totalPages)} changePage={changePage} currentPage={currentPage}/>
                </div>

                {listType === 'clients'
                    &&
                    <PersonsGroupInfo totalNum={totalNum} totalNumFreq={totalNumFreq}/>
                }
            </div>
                <div className={s.tablebox}>
                    <PersonsTable persons={persons}/>
                </div>
            <div className={s.pagination}>
                <PaginationControl disabled={isLoading} list={so(totalPages)} changePage={changePage} currentPage={currentPage}/>
            </div>
        </div>
    );
};

export default PersonsContent;
