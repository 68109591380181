import React, {useRef, useState} from 'react';
import s from './header.module.css'
import {NavLink, useNavigate, useNavigation} from "react-router-dom";
import Container from "../Layouts/Container";
import Logo from "../UI/Logo/Logo";
import avatar from '../../public/Icons/Setting.png'
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../Redux/Slices/authSlice";
import MenuBurger from "./menu-burger/menu-burger";
import {useClickOutside} from "../../Hooks/useClickOutside/useClickOutside";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket, faLanguage} from "@fortawesome/free-solid-svg-icons";
import langs from "../../utils/langs";
import {useTranslation} from "react-i18next/hooks";


const Header = () => {

    const currentUser = useSelector(state => state.auth.currentUser)
    const isAuth = useSelector(state => state.auth.isAuth)
    const dispatch = useDispatch()
    const [fullInfo,setFullInfo] = useState(false)
    const avatarRef = useRef()
    const navigate = useNavigate()
    const handleOutsideClick = () => {
        setFullInfo(false)
    }

    useClickOutside(avatarRef,handleOutsideClick)
    const [t,i18n] = useTranslation()

    const logouting = () => {
        navigate('/')
        dispatch(logout())
    }

    return (
        <div>
            <Container>
                <header className={s.header}>
                        <Logo></Logo>
                        <nav className={s.headerNav}>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                setFullInfo(!fullInfo)
                            }}
                                className={s.header__langIcon}
                            >
                                <FontAwesomeIcon icon={faLanguage} size={'xl'} />
                            </div>
                            {fullInfo === true && <div className={s.accountInfo__full} ref={avatarRef}>
                                <div className={s.accountInfo__fullLangs}>
                                    {Object.keys(langs).map((lng) =>
                                        <button style={{color: i18n.resolvedLanguage === lng ? '#ffffff' : 'black' ,backgroundColor: i18n.resolvedLanguage === lng ? '#109CF1' : 'rgba(0,0,0,0)'}}
                                                className={s.header__lang} type={'submit'}
                                                onClick={() => {
                                                    i18n.changeLanguage(lng)
                                                    setFullInfo(false)
                                                }}>
                                            {langs[lng].nativeName}
                                        </button>
                                    )}
                                </div>

                            </div>
                            }
                            {currentUser != null
                                ?
                                <div className={s.accountInfo}>
                                    <div className={s.accountInfo__img}>
                                        <img src={avatar} alt="" />
                                    </div>
                                    <div className={s.accountInfo__text}>
                                        {currentUser.name}
                                    </div>

                                    <div className={s.header__logout} onClick={logouting}>
                                        <FontAwesomeIcon icon={faArrowRightFromBracket} color={'red'}/>
                                    </div>


                                </div>
                                :
                                <div className={s.accountInfo}>
                                    <NavLink to={'/login'} className={s.header__login}>Login</NavLink>
                                </div>

                            }


                        </nav>
                    <div className={s.menuburgerbox}>
                        {isAuth
                            ?
                            <MenuBurger/>
                            :
                            <NavLink to={'/login'} className={s.header__login}>
                                login
                            </NavLink>
                        }

                    </div>

                </header>
            </Container>
        </div>
    );
};

export default Header;
