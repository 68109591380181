import React, {useEffect, useState} from 'react';
import s from './HistoryChangePerson.module.scss'
import {useForm} from "react-hook-form";
import Select from "react-select";
import MiniLoader from "../../../UI/MiniLoader/MiniLoader";
import BlueButton from "../../../UI/BlueButton/BlueButton";
import {useTranslation} from "react-i18next/hooks";
import {useGetPersonsGroupQuery, usePostPersonsMutation} from "../../../../Redux/APIs/otherAPI/personApi";

const HistoryChangePerson = ({setShowModal,image,setPersonAdded,setNewPersonData,fid}) => {
    const {data:personsList,isLoading} = useGetPersonsGroupQuery({opt:'nonslice'})
    const [sortedGroups,setSortedGroups] = useState([])
    const [selectedGroup,setSelectedGroup] = useState({id:'',name:''})
    const [sortedGroupList, setSortedGroupList] = useState([])
    const [t,i18n] = useTranslation()
    const [posttPerson,res] = usePostPersonsMutation()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({mode:'onChange'});

    const onSubmit = (data) => {
        if(data.fio && selectedGroup.id){
            posttPerson({personData:{fio:data.fio},description:'',type:2,group:selectedGroup.id})
            setNewPersonData({name:data.fio,group:selectedGroup.name, groupId:selectedGroup.id})
        }
    };
    useEffect(() => {
        if(res?.status === 'fulfilled'){
            setPersonAdded(true)
        }
    },[res])
    useEffect(() => {
        if(personsList?.length > 0){
            setSortedGroups(personsList.map((elem) => ({value:elem.id,label:elem.name})))
        }
    },[isLoading])
    return (
        <div className={s.changeperson}>
            <div className={s.changeperson__title}>
                {t('history.fullCardAdd.addNewPerson')}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={s.changeperson__form} >

                    <div className={s.changeperson__personGroups}>
                    <div>
                        {t('history.fullCardAdd.selectGroup')}
                    </div>
                        <Select
                            onChange={(value) => setSelectedGroup({id:value.value,name:value.label})}
                            isLoading={isLoading}
                            isDisabled={!sortedGroupList}
                            options={sortedGroups}
                            placeholder={t('default.group')}
                            styles={{
                                menuList: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontSize:12,
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width:250
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: state.isFocused ? '1px solid #109CF1' : '1px solid #bfbfbf',
                                    width:150,
                                    padding:0,
                                    backgroundColor:'rgba(0,0,0,0)'
                                }),
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    padding:`0 0 0 10px`,
                                    margin:0,
                                    fontSize:1
                                }),

                            }}
                        />
                </div>

                <div className={s.changeperson__inputbox}>
                    <label htmlFor="fio">
                        {t('default.personName')}
                    </label>
                    <input className={s.changeperson__input} type="text" id={'fio'} name={'fio'} {...register("fio",{
                        required:true,
                        pattern:/^\D*$/,
                        minLength:2,
                        maxLength:18,
                    })}
                    />
                    <div className={s.changeperson__error}>
                        {errors.fio && errors.fio.type === 'pattern' && (
                            <p style={{color:'red', fontSize:'12px'}}>Имя должно состоять из букв</p>
                        )}
                        {errors.fio && errors.fio.type === 'maxLength' && (
                            <p style={{color:'red', fontSize:'12px'}}>Максимальная длина - 18 символов</p>
                        )}
                        {errors.fio && errors.fio.type === 'minLength' && (
                            <p style={{color:'red', fontSize:'12px'}}>Минимальная длина - 2 символа</p>
                        )}
                    </div>
                </div>

                <div className={s.changeperson__button}>
                    <BlueButton type={'submit'}>{res.isLoading ? <MiniLoader/> : t('default.add')}</BlueButton>
                </div>
            </form>
        </div>
    );
};

export default HistoryChangePerson;
