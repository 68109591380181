import React from 'react';
import s from './EventsNotFound.module.css'
import im from '../../../public/Icons/events.svg'
import {ReactComponent as EventsIcon} from "../../../public/Icons/events.svg";
import {useTranslation} from "react-i18next/hooks";

const EventsNotFound = () => {
    const [t,i18n] = useTranslation()
    return (
        <div className={s.notFound}>
            <div>
                <EventsIcon className={s.notFound__icon} width={50} height={50} />
            </div>
            <div className={s.notFound__text}>
                {t('events.eventsNotFound')}
            </div>
        </div>
    );
};

export default EventsNotFound;