import React, {useEffect, useRef, useState} from 'react';
import s from './HistoryFullCard.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {fetchOneFace} from "../../../../Redux/Slices/HistoryOneFaceSlice";
import {PROXY} from "../../../../utils/pathss";
import {logout} from "../../../../Redux/Slices/authSlice";
import Modal from "../../../UI/Modal/Modal";
import HistoryChangePerson from "../HistoryChangePerson/HistoryChangePerson";
import EventsSlider from "../../../Events/EventsSlider/EventsSlider";
import HistoryNewPersonAdded from "../HistoryNewPersonAdded/HistoryNewPersonAdded";
import HistoryFullCardProcessedFace from "../HistoryFullCardProcessedFace/HistoryFullCardProcessedFace";
import HistoryFullCardUnprocessedFace from "../HistoryFullCardUnprocessedFace/HistoryFullCardUnprocessedFace";

const HistoryFullCard = ({elem}) => {
    const dispatch = useDispatch()
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const currentUser = useSelector(state => state.auth.currentUser)
    const currentPersonId = useSelector(state => state.historyOneFace.currentPersonId)
    const errorCode = useSelector(state => state.historyOneFace.errorCode)
    const [showModal, setShowModal] = useState(false)
    const [showSlider, setShowSlider] = useState(false)
    const [personAdded,setPersonAdded] = useState(false)
    const [newPersonData,setNewPersonData] = useState({name:'',group:''})
    useEffect(() => {
        if(elem.status !== 'processed'){

        }else if(elem.status === 'processed'){
            let cam = currentCamera !== '' ? currentCamera : currentUser.cameras[0].id
            dispatch(fetchOneFace({currentCamera:cam, rid:elem.rid,personId:currentPersonId}))
        }
    },[elem])

    if(errorCode === 401){
        dispatch(logout())
    }
    return (
        <div className={s.fullcardbox}>
            {elem.status === 'processed'
                ?
                <HistoryFullCardProcessedFace color={elem.group_color} setShowSlider={setShowSlider} setShowModal={setShowModal}/>
                :
                <HistoryFullCardUnprocessedFace elem={elem}/>
            }

            {showModal
                &&
                <Modal setShowModal={setShowModal} >
                    <HistoryChangePerson fid={elem.fid} setNewPersonData={setNewPersonData} setPersonAdded={setPersonAdded} image={`${PROXY}api/${elem.imgdata.path}`} setShowModal={setShowModal} />
                </Modal>
            }
            {showSlider
                &&
                <Modal setShowModal={setShowSlider} >
                    <EventsSlider camer={currentCamera} id={elem.rid} setShowModal={setShowSlider}/>
                </Modal>
            }
            {personAdded
                &&
                <Modal setShowModal={setPersonAdded}>
                    <HistoryNewPersonAdded setShowModal={setPersonAdded} newPersonData={newPersonData} />
                </Modal>
            }

        </div>

    );
};

export default HistoryFullCard;
