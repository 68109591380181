import React, {useState} from 'react';
import s from '../LoginForm/LoginForm.module.css'
import MyInput from "../../UI/MyInput/MyInput";
import MyButton from "../../UI/MyButton/MyButton";
import axios from "axios";
import JustInput from "../../UI/JustInput/JustInput";
import GreenButton from "../../UI/GreenButton/GreenButton";
import {useForm} from "react-hook-form";
import {fetchAuth} from "../../../Redux/Slices/authSlice";
import BlueButtonFull from "../../UI/BlueButtonFull/BlueButtonFull";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next/hooks";

const RegisterForm = () => {

    const [showPass,setShowPass] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        console.log(data)
    };
    const [t,i18n] = useTranslation()
    return (
        <div className={s.loginFormBox}>
            <div className={s.loginForm__title}>
                {t('auth.createAcc')}
            </div>
            <div className={s.loginForm__subtitle}>
                {t('auth.regDescr')}
            </div>
            <form className={s.loginForm} onSubmit={handleSubmit(onSubmit)}>
                <input placeholder={t('default.personName')} className={s.loginForm__input} name={'fio'}  {...register("fio",{
                    required:true,
                    pattern:/^\D*$/,
                    minLength:2
                })}/>
                <input placeholder="E-mail" className={s.loginForm__input} name={'email'}  {...register("email",{
                    required:true,
                    minLength:2
                })}/>
                <div className={s.loginForm__Inputs}>
                    <input placeholder='Password' type={showPass ? 'text' : 'password' } className={s.loginForm__input} name={'password'} {...register("password",{
                        required:true,
                        minLength:4
                    })}/>
                    <div className={s.loginForm__eye} onClick={() => setShowPass(!showPass)}>
                        <FontAwesomeIcon icon={faEye} color={`${showPass ? '#08979C' : 'rgba(0,0,0,.2)'}`} width={15} />
                    </div>
                </div>

                <div className={s.loginForm__button}>
                    <BlueButtonFull padding={15} type={'submit'} >
                        {t('auth.reg')}
                    </BlueButtonFull>
                </div>

            </form>
        </div>
    );
};

export default RegisterForm;