import React from 'react';
import Toolbar from "../../../components/toolbar/Toolbar";
import FacesSearchContent from "../../../components/Faces/FacesSearch/FacesSearchContent/FacesSearchContent";
import FacesSearchToolbar from "../../../components/Faces/FacesSearch/FacesSearchToolbar/FacesSearchToolbar";
import s from './FacesSearch.module.scss'
const FacesSearch = () => {
    return (
        <div className={s.facesSearch}>
            <Toolbar>
                <FacesSearchToolbar/>
            </Toolbar>
            <FacesSearchContent/>
        </div>
    );
};

export default FacesSearch;