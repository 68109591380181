import React from 'react';
import s from "../PersonBigTable.module.scss";
import createDays from "../funcs/createDays";

const TableHead = ({attendance}) => {
    return (
        <thead>
            <tr className={s.table__row}>
                <th className={s.table__cellDate}>
                </th>
                <th className={s.table__cellDate}>Приход</th>
                <th className={s.table__cellDate}>Уход</th>
                {createDays({numberOfTimes:24,dayTimes:0,attendance:attendance}).map((elem) =>
                    <th className={s.table__cellDate} id={elem.time}>
                        {elem.time}
                    </th>
                )}
            </tr>
        </thead>
    );
};

export default TableHead;