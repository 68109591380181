import React, { useEffect } from 'react';
import s from './CamView.module.scss'
import Toolbar from "../../components/toolbar/Toolbar";
import CameraPlayer from "../../components/CamView/CameraPlayer/CameraPlayer";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useParams} from "react-router-dom";
import {
    fetchCamEventsForDate,
    fetchCamSecondEvents, setProgressReverted
} from "../../Redux/Slices/camEventsSlice";
import {logout} from "../../Redux/Slices/authSlice";
import CamViewToolbar from "../../components/CamView/CamViewToolbar/CamViewToolbar";
import {faMaximize} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const CamView = () => {
    const dispatch = useDispatch()
    const pathParams = useParams()
    const currentCamera = pathParams.id
    const secondFrames = useSelector(state => state.camEvents.camSecondEvents)
    const framesIsError = useSelector(state => state.camEvents.isError)
    const neededDate = useSelector(state => state.camEvents.date)
    const reverted = useSelector(state => state.camEvents.reverted)
    const currentCameraName = useSelector(state => state.camEvents.currentCameraName)
    const currentUser = useSelector(state => state.auth.currentUser)

    useEffect(() => {
        const date = new Date(neededDate)
        const day = reverted === false ? 1 : -1
        date.setDate(date.getDate() + day)
        dispatch(fetchCamSecondEvents({currentCamera:currentCamera, neededDate:date.toISOString()}))
        dispatch(setProgressReverted([]))
    },[currentCamera])

    useEffect( () => {
        fetchSlides()
    },[neededDate,currentCamera])

    const fetchSlides = () => {
        const cc = currentUser.cameras.filter(elem => elem.id === currentCamera).length > 0 ? currentCamera : currentUser.cameras[0]
        dispatch(fetchCamEventsForDate({currentCamera:cc,neededDate:neededDate}))
    }

    if(framesIsError === true){
        dispatch(logout())
    }
    return (
        <div className={s.camviewbox}>
            <Toolbar title={'Кадры'}>
                <CamViewToolbar/>
            </Toolbar>
                <div className={s.camview}>
                    <CameraPlayer fetchSlides={fetchSlides} secondFrames={secondFrames} currentCamera={currentCamera} currentCameraName={currentCameraName} neededDate={neededDate} />
                </div>
                <div className={s.banner}>
                    <FontAwesomeIcon icon={faMaximize} color={'#C5C5C5'} size={"3x"}/>
                    Размеры экрана слишком маленькие, вы можете посмотреть записи во вкладке <NavLink className={s.bannerLink} to={'/events'}>События</NavLink>
                </div>
        </div>
    );
};

export default CamView;