import React, {useEffect, useState} from 'react';
import s from './PersonReportsSelect.module.scss'
import Select from 'react-select'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next/hooks";
import {useGetPersonsGroupQuery, useGetPersonsQuery} from "../../../Redux/APIs/otherAPI/personApi";
const PersonReportsSelect = () => {
    const {data:groupsList,isLoading:groupsIsLoading} = useGetPersonsGroupQuery({opt:'nonslice'})
    const [path,setPath] = useState(null)
    const {data,isFetching} = useGetPersonsQuery({path:path,page:0, freq:false},{refetchOnMountOrArgChange:true,skip:!path})
    const [sortedGroups,setSortedGroup] = useState([])
    const [sortedPersonsList, setSortedPersonList] = useState([])
    const navig = useNavigate()
    const dispatch = useDispatch()
    const [t,i18n] = useTranslation()
    useEffect(() => {
        if(groupsList?.length > 0){
            setSortedGroup(groupsList.map((elem) => ({value:elem.id,label:elem.name})))
        }
    },[groupsIsLoading])

    useEffect(() => {
        if(data?.persons){
            const pl = data?.persons.flat(1)
            setSortedPersonList(pl.map((elem) => ({value:elem.id,label:elem.fio})))
        }
    },[data])
    const selectPersonGroup = (value) => {
        setPath(value.value)
    }
    const selectPerson = (value) => {
        navig(`/reports/person/${value.value}_${value.label}`)
    }

    return (
        <div className={s.select}>
            <div className={s.select__select}>
                <span>
                    {t('default.group')}
                </span>
                <Select
                    isDisabled={!sortedGroups}
                    onChange={selectPersonGroup}
                    options={sortedGroups}
                    placeholder={' '}
                    styles={{
                        menuList: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize:12,

                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            width:250,

                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isFocused ? '1px solid #109CF1' : '1px solid #bfbfbf',
                            width:120,
                            padding:0,
                            maxHeight:38,
                            backgroundColor:'rgba(0,0,0,0)'
                        }),
                        valueContainer: (baseStyles, state) => ({
                            ...baseStyles,
                            padding:`0 0 0 10px`,
                            margin:0,
                            maxHeight:38,
                            fontSize:1
                        }),

                    }}
                />
            </div>
            <div className={s.select__select}>
                <span>
                    {t('default.person')}
                </span>
                <Select
                    isLoading={isFetching}
                    isDisabled={sortedPersonsList.length <= 0 || isFetching}
                    onChange={selectPerson}
                    options={sortedPersonsList}
                    placeholder={' '}
                    styles={{
                        menuList: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize:12,
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            width:220,
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isFocused ? '1px solid #109CF1' : '1px solid #bfbfbf',
                            width:120,
                            backgroundColor:'rgba(0,0,0,0)',
                        }),
                        valueContainer: (baseStyles, state) => ({
                            ...baseStyles,
                            padding:`0 0 0 10px`,
                            margin:0,
                            fontSize:1,
                        }),
                        loadingIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            display:'none'

                        }),
                    }}
                />
            </div>

        </div>
    );
};

export default PersonReportsSelect;
