import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import s from './HistoryAdminFaces.module.scss'
import {fetchLatestHistory} from "../../../../Redux/Slices/facesHistorySlice";
import HistoryAdminCard from "../HistoryAdminCard/HistoryAdminCard";
import HistoryFaceCard from "../../History/HistoryFaceCard/HistoryFaceCard";
import {useTranslation} from "react-i18next/hooks";

const HistoryAdminFaces = () => {
    const history = useSelector(state => state.facesHistory.history)
    const filteredHistory = useSelector(state => state.facesHistory.filteredHistory)
    const historyDate = useSelector(state => state.facesHistory.historyDate)
    const currentUser = useSelector(state => state.auth.currentUser)
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const dispatch = useDispatch()

    const  [t,i18n] = useTranslation()
    const handleScroll = (e) => {
        console.log(e.target.scrollTopMax, e.target.scrollTop)
        if(e.target.scrollTopMax === e.target.scrollTop){
            let lastPag = history[history.length - 1]
            let cam = currentCamera !== '' ? currentCamera : currentUser.cameras[0].id
            dispatch(fetchLatestHistory({ date:historyDate, currentCamera:cam, last_rid: lastPag.rid,lateHistory:history}))
        }


    }
    return (
        <div className={s.adminfaces} onScroll={(e) => handleScroll(e)}>
            {history?.length > 0
                ?
                filteredHistory.map((elem,id) =>
                <div key={id}>
                    <div className={s.adminfaces__time}>
                        {elem.time}
                    </div>
                    <div className={s.adminfaces__cards}>
                        {elem.history.map((hist,id) =>
                            <HistoryAdminCard id={id} elem={hist}/>
                        )}
                    </div>
                </div>
            )
                :
                <div className={s.adminfaces__notFound}>
                    {t('admin.historyNotFound')}
                </div>

            }



        </div>
    );
};

export default HistoryAdminFaces;