import React from 'react';
import s from './MainHowWork.module.scss'
import MainContentContainer from "../MainContent/MainContentContainer";
import camera from '../../../public/images/image 3.png'
import cloud from '../../../public/images/Group 110.png'
import camera2 from '../../../public/images/image 6.png'
import {useTranslation} from "react-i18next/hooks";
const MainHowWork = () => {
    const [t,i18n] = useTranslation()
    return (
            <div className={s.howwork} id={'howWorkSection'}>
                <MainContentContainer>
                    <div className={s.howwork__title}>
                        {t('main.howWork.titleFirst')} <span className={s.howwork__blueText}>{t('main.howWork.titleSecond')}</span>?
                    </div>


                        <div className={s.howwork__text}>
                            {t('main.howWork.subtitle')}
                        </div>


                </MainContentContainer>
                <div className={s.howwork__numbers}>
                    <div className={s.howwork__number1}>
                        <div className={s.howwork__numberNum}>
                            1
                        </div>
                        <div className={s.howwork__subtext}>
                            <img src={camera} alt=""/>
                            <span>
                                {t('main.howWork.step1')}
                            </span>
                        </div>
                    </div>
                    <div className={s.howwork__number2}>
                        <div className={s.howwork__numberNum}>
                            2
                        </div>
                        <div className={s.howwork__subtext}>
                            <img src={cloud} alt=""/>
                            <span>
                                {t('main.howWork.step2')}
                            </span>

                        </div>
                    </div>
                    <div className={s.howwork__number3}>
                        <div className={s.howwork__numberNum}>
                            3
                        </div>
                        <div className={s.howwork__subtext}>
                            <img src={camera2} alt=""/>
                            <span>
                                 {t('main.howWork.step3')}
                            </span>

                        </div>
                    </div>
                </div>
            </div>


    );
};

export default MainHowWork;