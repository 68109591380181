import React from 'react';
import s from "../CamControl.module.css";

const CamControlSlider = (props) => {
    const {stage,scrollDepth,reverted,neededDate,getDate, sliderPos} = {...props}
    return (
        <>
            {stage.x+sliderPos > -(props.width*scrollDepth + 50)
                ?
                <div className={s.control__fulldate} style={{left:`${reverted === true ? (props.width*Math.ceil(scrollDepth))+stage.x : -sliderPos }px`}} >{getDate(neededDate,false)}</div>
                :
                <div className={s.control__fulldate} style={{left:`${reverted !== true ? (props.width*Math.ceil(scrollDepth))+stage.x : -sliderPos }px`}} >{getDate(neededDate,false)}</div>

            }

            {stage.x+sliderPos > -(props.width*scrollDepth + (reverted === false ? 50 : -50))
                ?
                <div className={s.control__fulldate} style={{left:`${reverted !== true ? (props.width*Math.ceil(scrollDepth))+stage.x : -sliderPos}px`}}>
                    {getDate(neededDate,true)}
                </div>
                :
                <div className={s.control__fulldate} style={{left:`-${reverted !== true ? (props.width*Math.ceil(scrollDepth))+stage.x-1000 : 400}px`}}>
                    {getDate(neededDate,true)}
                </div>
            }
        </>
    );
};

export default CamControlSlider;