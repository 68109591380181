import React from 'react';
import s from './MyMap.module.css'
import Toolbar from "../../../components/toolbar/Toolbar";


const MyMap = () => {


    return (
        <div className={s.mapbox}>
            <Toolbar title={'Локации'}>
            </Toolbar>
            <div className={s.map}>

            </div>
        </div>


    );
};

export default MyMap;