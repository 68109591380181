import React from 'react';
import s from './BlueButtonFull.module.scss'
const BlueButtonFull = (props) => {
    return (
        <button style={{padding: props.padding}} className={props.white ? s.buttonWhite : s.button} {...props}>
            {props.children}
        </button>
    );
};

export default BlueButtonFull;