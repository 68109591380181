import React, {useState} from 'react';
import s from '../MainRates.module.scss'
import BlueButton from "../../../UI/BlueButton/BlueButton";
import BlueButtonFull from "../../../UI/BlueButtonFull/BlueButtonFull";
import crown from "../../../../public/Icons/Vector 4.svg";
import swipeHandler from "../../../../utils/sliderSwipeHandler";
import Modal from "../../../UI/Modal/Modal";
import MainTarifModal from "../../MainTarifModal/MainTarifModal";
import {useTranslation} from "react-i18next/hooks";
const MainRatesSlider = () => {
    const [touchStart,setTouchStart] = useState(0)
    const [sliderPos,setSliderPos] = useState(0)
    const [tarifType,setTarifType] = useState('')
    const [showModdal,setShowModal] = useState(false)
    const [t,i18n] = useTranslation()
    return (
        <div className={s.rates__window}>
            <div className={s.rates__cards}
                 onTouchStart={(e) => setTouchStart(e.touches[0].screenX)}
                 onTouchEnd={(e) => swipeHandler(e.changedTouches[0].screenX,touchStart,sliderPos,setSliderPos,2)}
                 style={{transform:`translateX(${sliderPos * -320}px)`}}
            >
                <div className={s.rates__card}>
                    <div className={s.rates__subtitle}>
                        BASIC
                    </div>
                    <div className={s.rates__listbox}>
                        <ul className={s.rates__list}>
                            <li className={s.rates__parag}> {t('main.ratesCards.cardBasic.firstLine.text')} <span className={s.rates__boldText}>1000</span> {t('main.ratesCards.cardBasic.firstLine.text2')} <span className={s.rates__boldText}>24</span> {t('main.ratesCards.cardBasic.firstLine.text3')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.secondLine.text')} <span className={s.rates__boldText}>5 {t('main.ratesCards.cardBasic.secondLine.text2')}</span></li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.thirdLine.text')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.fourthLine.text')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.fifthLine.text')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.sixthLine.text')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.seventhLine.text')}</li>
                        </ul>
                    </div>
                    <div className={s.rates__priceLine}>
                        <p>{t('main.ratesCards.from')} <span className={s.rates__spanPrice}>8000 тг</span></p>
                        <p>{t('main.ratesCards.perMonth')}</p>
                    </div>
                    <div className={s.rates__button}>
                        <BlueButton onClick={() => {
                            setTarifType('basic')
                            setShowModal(true)
                        }} >{t('main.ratesCards.buy')}</BlueButton>
                    </div>
                    <div className={s.rates__buttonMobile}>
                        <BlueButtonFull onClick={() => setShowModal(true)} >{t('main.ratesCards.buy')}</BlueButtonFull>
                    </div>
                </div>
                <div className={s.rates__card}>
                    <div className={s.rates__subtitle}>
                        SMART
                    </div>
                    <div className={s.rates__listbox}>
                        <ul className={s.rates__list}>
                            <li className={s.rates__parag}> {t('main.ratesCards.cardBasic.firstLine.text')} <span className={s.rates__boldText}>5000</span> {t('main.ratesCards.cardBasic.firstLine.text2')} <span className={s.rates__boldText}>24</span> {t('main.ratesCards.cardBasic.firstLine.text3')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.secondLine.text')} <span className={s.rates__boldText}>30 {t('main.ratesCards.cardBasic.secondLine.text2')}</span></li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.thirdLine.text')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.fourthLine.text')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.fifthLine.text')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.sixthLine.text')}</li>
                            <li className={s.rates__parag}>{t('main.ratesCards.cardBasic.seventhLine.text')}</li>
                        </ul>
                    </div>
                    <div className={s.rates__priceLine}>
                        <p>{t('main.ratesCards.from')} <span className={s.rates__spanPrice}>12000 тг</span></p>
                        <p>{t('main.ratesCards.perMonth')}</p>
                    </div>
                    <div className={s.rates__button}>
                        <BlueButton onClick={() => {
                            setTarifType('smart')
                            setShowModal(true)
                        }}>{t('main.ratesCards.buy')}</BlueButton>
                    </div>
                    <div className={s.rates__buttonMobile}>
                        <BlueButtonFull onClick={() => setShowModal(true)} >{t('main.ratesCards.buy')}</BlueButtonFull>
                    </div>
                </div>
            </div>

            {showModdal
                &&
                <Modal setShowModal={setShowModal}>
                    <MainTarifModal tarif={tarifType} />
                </Modal>
            }
        </div>

    );
};

export default MainRatesSlider;
