import React, {useState} from 'react';
import GreenButton from "../../../UI/GreenButton/GreenButton";
import s from './AddPerson.module.css'
import JustInput from "../../../UI/JustInput/JustInput";
import ModalBackCross from "../../../UI/Modal/ModalBackCross";
import {useTranslation} from "react-i18next/hooks";

const AddPerson = (props) => {
    const [personData,setPersonData] = useState({fio:'',male:'',email:'',phone:''})
    const [description,setDescription] = useState('')

    const [t,i18n] = useTranslation()
    return (
        <div className={s.addperson}>
            <ModalBackCross setShowModal={props.closeModal}/>
            <div className={s.addperson__title}>
                {t('persons.addPerson.title')}
            </div>
            <div className={s.addperson__inputs}>
                <div className={s.addperson__inputbox}>
                    <label htmlFor="dd" className={s.addperson__fio} >
                        {t('default.personName')}
                    </label>
                    <JustInput id={'dd'} value={personData.fio} onChange={(e) => {
                        setPersonData({...personData, fio:e.target.value})
                    }}/>
                </div>
                <div className={s.addperson__inputbox}>
                    <div>
                        <label htmlFor="dd" className={s.addperson__fio} >Email</label>
                        <span className={s.addperson__unimportant}>* {t('persons.addPerson.descr')}</span>
                    </div>
                    <JustInput id={'dd'} value={personData.email} onChange={(e) => {
                        setPersonData({...personData, email:e.target.value})
                    }}/>
                </div>
                <div className={s.addperson__inputbox}>
                    <div>
                        <label htmlFor="dd" className={s.addperson__fio} >
                            {t('default.phone')}
                        </label>
                        <span className={s.addperson__unimportant}>* {t('persons.addPerson.descr')}</span>
                    </div>
                    <JustInput id={'dd'} value={personData.phone} onChange={(e) => {
                        setPersonData({...personData, phone:e.target.value})
                    }}/>
                </div>
                <div className={s.addperson__inputbox}>
                    <div>
                        <label htmlFor="dd" className={s.addperson__fio} >
                            {t('default.gender')}
                        </label>
                        <span className={s.addperson__unimportant}>* {t('persons.addPerson.descr')}</span>
                    </div>
                    <div className={s.addperson__select}>
                        <div onClick={() => setPersonData({...personData, male:'male'})} className={personData.male === 'male' ? s.addperson__selectItem__Selected : s.addperson__selectItem}>
                            {t('default.male')}
                        </div>
                        <div onClick={() => setPersonData({...personData, male:'female'})} className={personData.male === 'female' ? s.addperson__selectItem__Selected : s.addperson__selectItem}>
                            {t('default.female')}
                        </div>
                    </div>
                </div>

            </div>



            <div className={s.addperson__buttonbox}>
                <GreenButton onClick={() => props.postPerson({personData:personData,description:description,type:2})}>
                    {t('persons.addPerson.button')}
                </GreenButton>
                <GreenButton bleach onClick={() => props.closeModal()} >
                    {t('default.back')}
                </GreenButton>
            </div>
        </div>
    );
};

export default AddPerson;