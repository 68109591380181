import React, {useState} from 'react';
import s from "../TimeInput/TimeInput.module.scss";
import InputMask from "react-input-mask";

const TimeRangeInput = ({setTime,time}) => {
    const validation = (value) => {
        let rangeTimes = {hourStart1:+value[0],hourStart2:+value[1], minStart1:+value[3],minStart2:+value[4], hourEnd1:+value[8],hourEnd2:+value[9],minEnd1:+value[11],minEnd2:+value[12]}
        if(rangeTimes.hourStart1 > 2){
            console.log('1')
            rangeTimes = {...rangeTimes, hourStart1: 2}
        } else if(rangeTimes.hourStart1 === 2 && rangeTimes.hourStart2 > 3){
            console.log('2')
            rangeTimes = {...rangeTimes, hourStart2: 3}
        }else if(rangeTimes.minStart1 > 5){
            console.log('4')
            rangeTimes = {...rangeTimes, minStart1: 5}
        }

        if(rangeTimes.hourEnd1 > 2){
            console.log('1')
            rangeTimes = {...rangeTimes, hourEnd1: 2}
        } else if(rangeTimes.hourEnd1 === 2 && rangeTimes.hourEnd2 > 3){
            console.log('2')
            rangeTimes = {...rangeTimes, hourEnd2: 3}
        }else if(rangeTimes.minEnd1 > 5){
            console.log('4')
            rangeTimes = {...rangeTimes, minEnd1: 5}
        }
        setTime(`${rangeTimes.hourStart1}${rangeTimes.hourStart2}:${rangeTimes.minStart1}${rangeTimes.minStart2} - ${rangeTimes.hourEnd1}${rangeTimes.hourEnd2}:${rangeTimes.minEnd1}${rangeTimes.minEnd2}`)
    }
    return (
        <div className={s.input}>
            <InputMask
                mask='99:99 - 99:99'
                maskChar={'0'}
                placeholder={'00:00 - 00:00'}
                value={time}
                onChange={(e) => validation(e.target.value)}>
                {(inputProps) => <input className={s.input__input} {...inputProps} />}
            </InputMask>
        </div>
    );
};

export default TimeRangeInput;
