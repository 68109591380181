import React, {useState} from 'react';
import s from './Toolbar.module.css'
import MyInput from "../UI/MyInput/MyInput";
import {useNavigate} from "react-router-dom";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Toolbar = (props) => {
    return (
        <div className={s.toolbar}>
            {props.children}
        </div>
    );
};

export default Toolbar;