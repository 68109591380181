import React from 'react';
import s from './MainFooter.module.scss'
import MainContentContainer from "../MainContent/MainContentContainer";
import logo from '../../../public/Icons/VectorLogo.svg'
import {useTranslation} from "react-i18next/hooks";
const MainFooter = () => {
    const [t,i18n] = useTranslation()
    return (
            <div className={s.footerbox} id={'footerSection'}>
                <MainContentContainer>
                    <div className={s.footer}>
                        <div className={s.footer__info}>
                            <div>
                                {t('main.footer.address')}
                            </div>
                            <div>
                                +7 (702) 899-94-33
                            </div>
                            <div>
                                support@rannesme
                            </div>
                        </div>

                        <div className={s.footer__logobox}>
                            <div className={s.footer__logo}>
                                <img src={logo} alt=""/>
                            </div>
                            <div className={s.footer__title}>
                                Rannesme
                            </div>
                        </div>
                    </div>
                </MainContentContainer>
            </div>
    );
};

export default MainFooter;
