import React from 'react';
import s from './MainRates.module.scss'
import ss from '../../../public/Icons/Group 73.svg'
import sss from '../../../public/Icons/Vector 3.svg'
import BlueButton from "../../UI/BlueButton/BlueButton";
import crown from '../../../public/Icons/Vector 4.svg'
import BlueButtonFull from "../../UI/BlueButtonFull/BlueButtonFull";
import MainRatesSlider from "./MainRatesSlider/MainRatesSlider";
import {useTranslation} from "react-i18next/hooks";
const MainRates = () => {
    const [t,i18n] = useTranslation()
    return (
        <div className={s.ratesbox} id={'ratesSection'}>
            <img className={s.ratesbox__vector} src={sss} alt=""/>
            <img className={s.ratesbox__vector} src={ss} alt=""/>
            <div className={s.rates}>
                <div className={s.rates__title}>
                    {t('main.rates.title')}
                </div>
                <div className={s.rates__text}>
                    {t('main.rates.subtitle')}
                </div>
                <MainRatesSlider/>
            </div>
        </div>
    );
};

export default MainRates;
