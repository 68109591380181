import React, {useState} from 'react';
import s from './MainTarifModal.module.scss'
import JustInput from "../../UI/JustInput/JustInput";
import {useForm} from "react-hook-form";
import {fetchAuth} from "../../../Redux/Slices/authSlice";
import BlueButton from "../../UI/BlueButton/BlueButton";
import {useTranslation} from "react-i18next/hooks";
const MainTarifModal = ({tarif}) => {

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        console.log(data)
    };

    const [t,i18n] = useTranslation()
    return (
        <div className={s.tarif}>
            <div className={s.tarif__title}>
                {t('main.ratesCards.modal.title')}
            </div>
            <div className={s.tarif__subtitle}>
                {t('main.ratesCards.modal.subtitle')}
            </div>
            <form className={s.tarif__form} onSubmit={handleSubmit(onSubmit)}>
                <input placeholder={t('default.personName')} className={s.tarif__input} name={'fio'}  {...register("fio",{
                    required:true,
                    pattern:/^\D*$/,
                    minLength:2
                })}/>
                <div className={s.tarif__wrongData}>
                    {errors.fio?.type === 'pattern' &&
                        <>
                            {t('main.ratesCards.modal.nameWrong')}
                        </>
                    }
                </div>
                <input placeholder={t('default.phone')} className={s.tarif__input} name={'phone'}  {...register("phone",{
                    required:true,
                    pattern:/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
                    minLength:2
                })}/>




                    <div className={s.tarif__wrongData}>
                        {errors.phone?.type === 'pattern' &&
                            <>
                                {t('main.ratesCards.modal.phoneWrong')}
                            </>
                        }
                    </div>

                <div className={s.tarif__radios}>
                    <input defaultChecked={tarif === 'basic' && true} className={s.tarif__radioDot} name={'radioBasic'} id={'radioBasic'} value={'basic'} type="radio" {...register('tarif')} />
                    <label className={s.tarif__radio} htmlFor="radioBasic">Basic</label>


                    <input defaultChecked={tarif === 'smart' && true} className={s.tarif__radioDot} name={'radioSmart'}  id={'radioSmart'} value={'smart'} type="radio" {...register('tarif')} />
                    <label className={s.tarif__radio} htmlFor='radioSmart'>Smart</label>
                </div>


                <BlueButton type={'submit'}>{t('default.send')}</BlueButton>
            </form>
        </div>
    );
};

export default MainTarifModal;
