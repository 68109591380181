import React, {useEffect, useRef, useState} from 'react';
import s from "../HistoryFullCard/HistoryFullCard.module.scss";
import {PROXY} from "../../../../utils/pathss";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faPencil, faVideo} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next/hooks";
import { useSelector} from "react-redux";
import Loader from "../../../UI/Loader/Loader";
import HistoryFaceVisitsList from "../HistoryFaceVisitsList/HistoryFaceVisitsList";
import { useGetPersonAllVisitsQuery} from "../../../../Redux/APIs/otherAPI/personAllVisitsApi";
import allPersonVisitsCounter from "../../../../utils/allPersonVisitsCounter";

const HistoryFullCardProcessedFace = ({color,setShowSlider, setShowModal}) => {
    const [show,setShow] = useState(false)
    const [showAllVisits,setShowAllVisits] = useState(false)
    const imageRef = useRef()
    const oneFace = useSelector(state => state.historyOneFace.oneFace)
    const isLoading = useSelector(state => state.historyOneFace.isLoading)
    const [t,i18n] = useTranslation()
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const currentUser = useSelector(state => state.auth.currentUser)
    const {data:personAllVisits,isLoading:personAllVisitsIsLoading} = useGetPersonAllVisitsQuery({person:oneFace?.person_id},{refetchOnMountOrArgChange:true})

    const provCameras = () => {
        if(currentUser?.cameras.find(elem => elem.id === currentCamera)?.view === 'traffic'){
            return false
        }
        return true
    }
    return (
        <>
            {!isLoading
                ?
                <div className={s.fullcardCont}>
                    <div className={s.fullcard}>
                        <div className={s.fullcard__img}>
                            <img ref={imageRef} onLoad={() => setShow(true)} src={`${PROXY}api/${oneFace?.imgdata?.path}`} alt=""/>
                            {!show
                                &&
                                <div className={s.fullcard__imgBanner}>
                                </div>
                            }
                        </div>
                        <div className={s.fullcard__time}>
                            {getTimeAndDate('time', oneFace?.dt)}
                        </div>
                        <div className={s.fullcard__info}>
                                <div style={{color:color || '#bfbfbf'}} className={s.fullcard__name}>
                                    {oneFace?.person_fio !== 'Unknown' ? oneFace?.person_fio : oneFace?.rdata?.name}
                                </div>
                                {/*<div className={s.fullcard__text}>*/}
                                {/*    {`${t('history.historyFullCard.emotion')} : ${oneFace?.rdata.emotion ? oneFace?.rdata.emotion : '-'}`}*/}
                                {/*</div>*/}
                                {provCameras()
                                    &&
                                    <>
                                    <div className={s.fullcard__text} onClick={() => setShowAllVisits(!showAllVisits)}>
                                        {`${t('history.historyFullCard.allVisits')} : ${personAllVisitsIsLoading ? '-' : allPersonVisitsCounter(personAllVisits?.hourly_sum)}`}
                                        {!personAllVisitsIsLoading &&  <FontAwesomeIcon icon={faList} color={showAllVisits ? '#109CF1' : '#bfbfbf'}/>}

                                    </div>
                                    <div className={s.fullcard__text}>
                                        {`${t('history.historyFullCard.age')} : ${oneFace?.rdata.age ? oneFace?.rdata.age : '-'}`}
                                    </div>
                                    <div className={s.fullcard__text}>
                                        {`${t('history.historyFullCard.gender')} : ${oneFace?.rdata.gender ? oneFace?.rdata.gender : '-'}`}
                                    </div>

                                    </>

                                }
                                    <div className={s.fullcard__change} onClick={() => setShowModal(true)}>
                                        <FontAwesomeIcon icon={faPencil}/>
                                        <span>
                                    {t('default.edit')}
                                </span>
                                    </div>

                                <div style={{top: 40}} className={s.fullcard__change} onClick={() => setShowSlider(true)}>
                                    <FontAwesomeIcon icon={faVideo}/>
                                    <span>
                                    {t('default.cameraRecording')}
                            </span>
                                </div>
                            </div>
                    </div>
                    {provCameras()
                        &&
                        <HistoryFaceVisitsList person={oneFace?.person_id} showAllVisits={showAllVisits}/>
                    }
                </div>
                :
                <Loader></Loader>
            }
        </>
    );
};

export default HistoryFullCardProcessedFace;
