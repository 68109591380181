import arrayOb from "./arrayOb";

const visitorsMoreDayRefactor = (data,firstDate,secondDate) => {
    const traffic = data.traffic
    const visitors = data.visitors
    const newData = []
    const mainArray = Object.entries(traffic).length !== 0 ? traffic : visitors
    const dat = new Date(firstDate)
    const fd = new Date(firstDate).getTime()
    const sd = new Date(secondDate).getTime()
    const days = ((sd - fd)/(1000*60*60*24))
    for(let i = 0; i < days; i++){
        const datMonth = `${dat.getMonth()+1}`
        newData.push({numVisitors:0,numTraffic:0,year:dat.getFullYear(), time: `${datMonth.length>1 ?'':'0'}${datMonth}.${String(dat.getDate()).length === 1 ? '0' + dat.getDate() : dat.getDate()}`})
        dat.setDate(dat.getDate()+1)
    }
    const now = []
    for(let i in mainArray){
        let dayVisitorsCount = 0
        let dayTrafficCount = 0
        for(let ii in traffic[i]){
            dayTrafficCount += traffic[i][ii].num
        }
        for(let iii in visitors[i]){
            dayVisitorsCount += visitors[i][iii].num
        }
        const year = i.slice(0,4)
        const month = i.length === 7 ? i.slice(4,5) : i.slice(4,6)
        const day = i.length === 7 ? i.slice(5,7) : i.slice(6,8)
        const newElem = {numVisitors:dayVisitorsCount,numTraffic:dayTrafficCount ,year: `${year}`,time: `${month}.${day}`}
        now.push(newElem)
    }
    // обхединение
    const result = []
    for(let i in newData){
        result.push(now.find(elem => elem.time === newData[i].time) || newData[i])
    }
    return result

}
export default visitorsMoreDayRefactor;
