const getPersonHistoryCalendar = (cell) => {
    const cal = [
        [],
        [],
        [],
        [],
        [],
        [],
    ]
    if(cell.history[0].dt){
        const dat = new Date(cell.history[0].dt)
        const month = dat.getMonth()+1
        const firstMonthDate = new Date(dat)
        firstMonthDate.setDate(1)
        for(let i = 1; i < (firstMonthDate.getDay() || 7);i++){
            cal[0].push({actions:0})

        }
        let row = 0
        while (firstMonthDate.getMonth()+1 === month){

            const actionsOnThisDay = cell.history.filter(elem => {
                const d = new Date(elem.dt)
                return d.getDate() === firstMonthDate.getDate()
            })
            cal[row].push({dat:firstMonthDate.getDate(),actions:actionsOnThisDay.length})
            if(firstMonthDate.getDay() % 7 === 0){
                row += 1
            }
            firstMonthDate.setDate(firstMonthDate.getDate()+1)
        }
        if (firstMonthDate.getDate() !== 0) {
            for (let i = firstMonthDate.getDay(); i < 7+1; i++) {
                cal[row].push({actions:0})
            }
        }
        return cal
    }
}
export default getPersonHistoryCalendar;