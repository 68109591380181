import {createSlice, current} from '@reduxjs/toolkit'
import arraySlicer from "../../utils/arraySlicer";
import sortForDate from "../../utils/sortForDate";

const initialState = {
    facePhoto:'',
    history:[],
    processedId:false,
    currentPage:0,
    currentLocation:localStorage.getItem('historySearchLocation') || '' ,
    errorCode:'',
}

export const facesSearchSlice = createSlice({
    name: 'facesSearch',
    initialState,
    reducers: {
        refactorData:(state,action) => {
            state.history = arraySlicer(sortForDate(action.payload),6)
            const id = action.payload.find(elem => elem.status === 'processing')?.id
            if(id){
                state.processedId = id
            }else{
                state.processedId = false
            }
        },
        appendData:(state,action) => {
            const history = [...current(state.history)].flat(1)
            if(action.payload.search){
                const newList = history.map(elem => elem.id === action.payload.search.id ? action.payload.search : elem);
                if(!newList.find(elem => elem.status === 'processing')){
                    state.processedId = false
                }else if(newList.find(elem => elem.status === 'processing')){
                    const id = history.find(elem => elem.status === 'processing')?.id
                    state.processedId = id
                }
                state.history = arraySlicer(newList,6)

            }
        },
        setFacePhoto:(state,action) => {
            state.facePhoto = action.payload
        },
        setCurrentLocation:(state,action) => {
            state.currentLocation = action.payload
            localStorage.setItem('historySearchLocation', action.payload)
        },
        setPagination: (state,action) => {
            state.currentPage = action.payload
        },
        goPagination: (state,action) => {
            state.currentPage = state.currentPage + 1
        },
        backPagination: (state,action) => {
            state.currentPage -= 1
        },
    },
})
export const {changeFacesSearchDate,setFacePhoto,setCurrentLocation,setPagination,goPagination,backPagination,refactorData,appendData} = facesSearchSlice.actions

export default facesSearchSlice.reducer