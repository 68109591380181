import React, {useState} from 'react';
import Modal from "../../../components/UI/Modal/Modal";
import CamsModal from "../CamsModal/CamsModal";
import {useDispatch} from "react-redux";
import AddHikcentralCamera from "../../../components/Cams/AddCamera/AddHikcentralCamera/AddHikcentralCamera";

const CamsAddCamera = (props) => {

    const [cameraType,setCameraType] = useState('')
    const [showTypeModal, setShowTypeModal] = useState(1)
    const cameraTypes = {
        'hikcentral': <AddHikcentralCamera setShowModal={props.setShowModal} />,
        // 'id': <IpCamModal setShowTypeModal={setShowTypeModal} setShowModal={props.setShowModal} />
    }

    return (
        <>

            {showTypeModal === 1 && <Modal setShowModal={props.setShowModal} >
                <div className={`wrapper`}>
                    <CamsModal setCameraType={setCameraType} setShowTypeModal={setShowTypeModal} setShowModal={props.setShowModal} />
                </div>
            </Modal>
            }
            {showTypeModal === 2 && <Modal setShowModal={props.setShowModal} >
                {
                    cameraTypes[cameraType]
                }
            </Modal>
            }


        </>
    );
};

export default CamsAddCamera;