import React from 'react';
import s from './InitialLoader.module.scss'
import Loader from "../Loader/Loader";
const InitialLoader = () => {
    return (
        <div className={s.loader}>
            <Loader/>
            <div className={s.loader__title}>
                Загрузка...
            </div>
        </div>
    );
};

export default InitialLoader;