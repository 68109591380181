import React, {useState} from 'react';
import s from './Sidebar.module.css'
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarDays, faFileCircleExclamation,
    faMapLocationDot,
    faUserGroup,
    faVideo
} from "@fortawesome/free-solid-svg-icons";
import {faCircleUser} from "@fortawesome/free-regular-svg-icons";
import {faDesktop} from "@fortawesome/free-solid-svg-icons/faDesktop";
import {useTranslation} from "react-i18next/hooks";




const SidebarItem = (props) => {
    const routePath = useLocation()
    const imagesList = {
        'cam':<FontAwesomeIcon icon={faVideo} color={routePath.pathname === props.path ? '#e5e6e7' : '#c4c8cc'} />,   //cam
        'calend':<FontAwesomeIcon icon={faCalendarDays} size={'lg'} color={routePath.pathname === props.path ? '#e5e6e7' : '#c4c8cc'} />, //calend
        'face':<FontAwesomeIcon icon={faCircleUser} size={'lg'} color={routePath.pathname === props.path ? '#e5e6e7' : '#c4c8cc'} />,  //face
        'friends':<FontAwesomeIcon icon={faUserGroup} color={routePath.pathname === props.path ? '#e5e6e7' : '#c4c8cc'} />, //friends
        'mark':<FontAwesomeIcon icon={faMapLocationDot} color={routePath.pathname === props.path ? '#e5e6e7' : '#c4c8cc'} />,  //friends
        'arrows':<FontAwesomeIcon icon={faFileCircleExclamation} size={'lg'} color={routePath.pathname === props.path ? '#e5e6e7' : '#c4c8cc'} />,  //arrows
        'admin':<FontAwesomeIcon icon={faDesktop} size={'lg'} color={routePath.pathname === props.path ? '#e5e6e7' : '#c4c8cc'}/>
    }
    const [subitemsAct, setSubitemsAct] = useState(false)
    const [t,i18n] = useTranslation()
    return (
        <div className={s.sidebar__item} style={{backgroundColor:`${props.path === routePath.pathname ? '#109CF1' : ''}`}}>
            <div className={s.sidebar__selfText}  >
                <NavLink to={props.path} className={s.sidebar__navlink} style={{color:`${props.path === routePath.pathname ? '#e5e6e7' : 'black'}`}}
                         onClick={() => {
                             setSubitemsAct(true)
                         }}
                >
                    {
                        props.imag
                        &&
                        <>
                            {imagesList[props.imag]}
                        </>

                    }
                    <span className={s.sidebar__title}>{t(`sidebar.${props.name}`)}</span>
                </NavLink>
                <div>
                    {props.subItems !== undefined
                        &&
                        <span className={subitemsAct ? s.sidebar__galkActive : s.sidebar__galk} onClick={(e) => {
                            e.stopPropagation()
                            setSubitemsAct(!subitemsAct)
                        }}> &#8249; </span>
                    }
                </div>
            </div>

            {props.subItems && props.subItems.map((elem,id) =>
                <div key={id} className={subitemsAct === false ? s.sidebar__subitem : elem.path === routePath.pathname ? s.sidebar__subitemActiveP : s.sidebar__subitemActive}  >
                    <NavLink to={elem.path} className={s.sidebar__subitem__navlink} >
                        <span style={{color:`${elem.path === routePath.pathname ? '#e5e6e7' : 'black' }`}}>
                            {t(`sidebar.${elem.name}`)}
                        </span>
                    </NavLink>
                </div>

            )}

        </div>
    );
};

export default SidebarItem;