import {
    ADMIN_MONITORING_ROUTE,
    ADMIN_ROUTE,
    FACES_SEARCH_ROUTE, RAWDATA_ROUTE,
    REPORT_PERSON_ROUTE,
    REPORT_VISITORS_ROUTE,
    REPOST_VISITS_HISTORY_ROUTE
} from "./pathss";
import getUserNameRole from "../Router/utils/getUserNameRole";

const filterRoutes = (currentUser,routes) => {
    let filteredRoutes = [...routes]
    if(!currentUser.org.func.person_search){
        filteredRoutes = filteredRoutes.filter(route => route.path !== FACES_SEARCH_ROUTE)
    }
    if(!currentUser.org.func.regular_customers_report){
        filteredRoutes = filteredRoutes.filter(route => route.path !== REPOST_VISITS_HISTORY_ROUTE)
    }
    if(!currentUser.org.func.visitors_report){
        filteredRoutes = filteredRoutes.filter(route => route.path !== REPORT_VISITORS_ROUTE)
    }
    if(!currentUser.org.func.worktime_report){
        filteredRoutes = filteredRoutes.filter(route => route.path !== REPORT_PERSON_ROUTE)
    }
    if(!getUserNameRole(currentUser?.email)){
        filteredRoutes = filteredRoutes.filter(route => route.path !== ADMIN_ROUTE && route.path !== ADMIN_MONITORING_ROUTE && route.path !== RAWDATA_ROUTE)
    }
    return filteredRoutes

}

export default filterRoutes;
