import React, {useEffect, useRef, useState} from 'react';
import s from './visitsHistoryContent.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    fetchVisitsHistory, sortVisitsHistoryDay, sortVisitsHistoryGender, sortVisitsHistoryMinVisits,
    sortVisitsHistoryMonth,
    sortVisitsHistorySum,
} from "../../../Redux/Slices/visitsHistoryReportSlice";
import VisitsHistoryMonthTable from "../VisitsHistoryMonthTable/VisitsHistoryMonthTable";
import Loader from "../../UI/Loader/Loader";
import ToTopScroller from "../../UI/ToTopScroller/toTopScroller";
import VisitsHistoryDownloadExcel from "../VisitsHistoryDownloadExcel/VisitsHistoryDownloadExcel";
import {useTranslation} from "react-i18next/hooks";
import VisitsHistoryTableMinVisits from "../VisitsHistoryTableMinVisits/VisitsHistoryTableMinVisits";
import VisitsHistoryCharts from "../VisitsHistoryCharts/VisitsHistoryCharts";
import VisitsHistoryNotFoundBanner from "../VisitsHistoryNotFoundBanner/VisitsHistoryNotFoundBanner";
import Month from "react-date-range/dist/components/Month";
import MonthTableNotFound from "../VisitsHistoryMonthTable/MonthTableNotFound/MonthTableNotFound";

const VisitsHistoryContent = () => {
    const currentLocation = useSelector(state => state.visitsHistoryReport.currentLocation)
    const currentDate = useSelector(state => state.visitsHistoryReport.visitsHistoryReportDate)
    const visitsHistory = useSelector(state => state.visitsHistoryReport.visitsHistory)
    const isLoading = useSelector(state => state.visitsHistoryReport.isLoading)
    const dispatch = useDispatch()
    const [t,i18n] = useTranslation()

    useEffect(() => {
        if(currentLocation && currentDate){
            dispatch(fetchVisitsHistory({currentLocation:currentLocation,datStart:currentDate.datStart,datEnd:currentDate.datEnd}))
        }
    },[currentDate,currentLocation])

    const boxRef = useRef(null)

    return (
        <div className={s.visitsHistoryContent}>

                    <>
                        <div className={s.visitsHistoryContent__settings}>
                            {!isLoading
                                &&
                                visitsHistory?.length > 0
                                &&
                                    <>
                                        <VisitsHistoryTableMinVisits />
                                        <VisitsHistoryDownloadExcel />
                                    </>

                            }

                        </div>
                        <VisitsHistoryCharts />

                        <div className={s.tablebox} ref={boxRef}>
                            {isLoading
                                ?
                                <Loader/>
                                :
                                visitsHistory?.length > 0
                                ?
                                    <VisitsHistoryMonthTable boxRef={boxRef}/>
                                :
                                    <MonthTableNotFound/>
                            }


                        </div>


                    </>


            <ToTopScroller/>
        </div>
    );
};

export default VisitsHistoryContent;
