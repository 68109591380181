import React from 'react';
import s from './FacesSearchPersonPopup.module.scss'
import {useGetOnePersonPhotosQuery, useGetOnePersonQuery} from "../../../../Redux/APIs/otherAPI/onePersonApi";
import {PROXY} from "../../../../utils/pathss";
import {useGetPersonsGroupQuery} from "../../../../Redux/APIs/otherAPI/personApi";
import Loader from "../../../UI/Loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSadTear} from "@fortawesome/free-solid-svg-icons";

const FacesSearchPersonPopup = ({id}) => {
    const {data:person,isLoading,isError} = useGetOnePersonQuery({id},{refetchOnMountOrArgChange:true})
    const {data:photos,isLoading:photosIsLoading,isError:photosIsError} = useGetOnePersonPhotosQuery({id},{refetchOnMountOrArgChange:true})
    const {data:groupsList,isLoading:groupsIsLoading} = useGetPersonsGroupQuery({opt:'nonslice'})

    const getGroupName = (id) => {
        return groupsList.filter(elem => elem.id === id)[0]?.name
    }
    return (
        <div className={s.popup}>
            {!isLoading
                ?
                person
                ?
                <div className={s.popup__info}>
                    <div className={s.popup__title}>
                        {person?.fio}
                    </div>
                    <div className={s.popup__photos}>
                        {photos?.length > 0
                            &&
                            photos.map((elem) =>
                                <img key={elem.person} src={`${PROXY}/api/${elem.path}`} alt="" className={s.popup__photo}/>
                            )
                        }
                    </div>
                    <div className={s.popup__text}>
                        ID: {person?.id}
                    </div>
                    {person?.group
                        &&
                        <div className={s.popup__text}>
                            Группа: {!groupsIsLoading && groupsList?.length > 0 && getGroupName(person?.group)}
                        </div>
                    }

                </div>
                    :
                    <div className={s.popup__error}>
                        <FontAwesomeIcon icon={faFaceSadTear}/>
                        <span>Персона не найдена</span>
                    </div>

                :
                <Loader/>
            }
        </div>
    );
};

export default FacesSearchPersonPopup;