import React from 'react';
import s from './AdminRawdataContent.module.scss'
import AdminRawdataList from "../AdminRawdataList/AdminRawdataList";
import AdminRawdataInfo from "../AdminRawdataInfo/AdminRawdataInfo";
import AdminRawdataSettings from "../AdminRawdataSettings/AdminRawdataSettings";
const AdminRawdataContent = () => {
    return (
        <div className={s.rawdata}>
            <div className={s.rawdata__inputbox}>
                <AdminRawdataSettings/>
            </div>
            <div className={s.rawdata__contentbox}>
                <AdminRawdataList/>
                <AdminRawdataInfo/>
            </div>
        </div>
    );
};

export default AdminRawdataContent;