import React, {useEffect, useLayoutEffect} from 'react';
import {PROXY} from "../../../utils/pathss";
import s from "../EventsSlider/EventsSlider.module.css";
import {useDispatch, useSelector} from "react-redux";
import {setCanShow} from "../../../Redux/Slices/eventsSlidesSlice";

const EventSlide = (props) => {
    const eventSlides = useSelector(state => state.eventsSlides.eventSlides)
    const dispatch = useDispatch()
    useEffect(() => {
        if(eventSlides.length !== 0 && eventSlides[props.sliderPos].ss !== 0){
            dispatch(setCanShow(false))
        }
    },[props.sliderPos])
    return (
        <>
            {eventSlides.length > 0
                &&
                <img onClick={() => props.stopSl()}
                     onLoad={() => {
                        dispatch(setCanShow(true))
                     }}
                     src={`${PROXY}api/${eventSlides[props.sliderPos].path}`}
                     alt=""
                     style={{maxHeight:`${window?.outerHeight}px`,zIndex:1}}
                     className={s.eventsSlider__slide}
                     ref={props.refTo}
                />
            }

        </>
    );
};

export default EventSlide;
