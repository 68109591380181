import React from 'react';
import MainContentContainer from "../MainContent/MainContentContainer";
import s from './MainAudience.module.scss'
import telegaSpy from '../../../public/Icons/telegaSpy.svg'
import officeSpy from '../../../public/Icons/officeSpy.svg'
import factorySpy from '../../../public/Icons/factorySpy.svg'
import stockSpy from '../../../public/Icons/stockSpy.svg'
import bankSpy from '../../../public/Icons/bankSpy.svg'
import socialSpy from '../../../public/Icons/socialSpy.svg'
import {useTranslation} from "react-i18next/hooks";


const MainAudience = () => {
    const [t,i18n] = useTranslation()
    return (
        <>
        <MainContentContainer>
            <div className={s.audience} id={'audienceSection'}>
                <div className={s.audience__title}>
                    <span className={s.audience__blueText}>{t('main.audience.titleFirst')}</span> {t('main.audience.titleSecond')}?
                </div>

                <div className={s.audience__list}>
                    <div className={s.audience__elem}>
                        <div className={s.audience__elemInfo}>
                            <div className={s.audience__subtitle}>
                                {t('main.audience.tradeCard')}
                            </div>
                        </div>
                        <div className={s.audience__image}>
                            <img src={telegaSpy} alt=""/>
                        </div>

                    </div>
                    <div className={s.audience__elem}>

                        <div className={s.audience__elemInfo}>
                            <div className={s.audience__subtitle}>
                                {t('main.audience.officeCard')}
                            </div>
                        </div>
                        <div className={s.audience__image}>
                            <img src={officeSpy} alt=""/>
                        </div>
                    </div>
                    <div className={s.audience__elem}>

                        <div className={s.audience__elemInfo}>
                            <div className={s.audience__subtitle}>
                                {t('main.audience.factoryCard')}
                            </div>
                        </div>
                        <div className={s.audience__image}>
                            <img src={factorySpy} alt=""/>
                        </div>
                    </div>
                    <div className={s.audience__elem}>

                        <div className={s.audience__elemInfo}>
                            <div className={s.audience__subtitle}>
                                {t('main.audience.stockCard')}
                            </div>
                        </div>
                        <div className={s.audience__image}>
                            <img src={stockSpy} alt=""/>
                        </div>
                    </div>
                    <div className={s.audience__elem}>

                        <div className={s.audience__elemInfo}>
                            <div className={s.audience__subtitle}>
                                {t('main.audience.bankCard')}
                            </div>
                        </div>
                        <div className={s.audience__image}>
                            <img src={bankSpy} alt=""/>
                        </div>
                    </div>
                    <div className={s.audience__elem}>

                        <div className={s.audience__elemInfo}>
                            <div className={s.audience__subtitle}>
                                {t('main.audience.socialCard')}
                            </div>
                        </div>
                        <div className={s.audience__image}>
                            <img src={socialSpy} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </MainContentContainer>
        </>


    );
};

export default MainAudience;