import React from 'react';
import s from "./Modal.module.css";
import {faX} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ModalBackCross = (props) => {
    return (
        <div className={s.modal__back} onClick={() => props.setShowModal(false)} >
            <FontAwesomeIcon icon={faX}/>
        </div>
    );
};

export default ModalBackCross;