const historyUniqueSort = (history) => {
    console.log(history)
    const uniquePersonId = ['oAiReazndv7Ny3aDnoVHN5']
    const sortedList = []
    for(let i in history){
        if(history[i].person_id !== ''){
            if(uniquePersonId.filter(elem => elem === history[i].person_id).length === 0){
                uniquePersonId.push(history[i].person_id)
                sortedList.push(history[i])
            }else if(uniquePersonId.filter(elem => elem === history[i].person_id).length !== 0){
                continue
            }
        }else if(history[i].person_id === ''){
            sortedList.push(history[i])
        }
    }
    return sortedList
}

export default historyUniqueSort;