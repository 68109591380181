import React, {useState} from 'react';
import s from './HistoryFaceCard.module.scss'
import {PROXY} from "../../../../utils/pathss";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import Modal from "../../../UI/Modal/Modal";
import HistoryFullCard from "../HistoryFullCard/HistoryFullCard";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentRid} from "../../../../Redux/Slices/HistoryOneFaceSlice";
const HistoryFaceCard = ({elem,id}) => {
    const [showModal, setShowModal] = useState(false)
    const dispatch = useDispatch()
    return (
        <>
            <div className={s.card}
                style={{borderColor:elem?.group_color || `#c4c4c4`}}
                 key={id}
                 onClick={() => {
                dispatch(setCurrentRid({rid:elem.rid,person_id:elem.person_id}))
                setShowModal(true)
            }}>
                <img src={`${PROXY}api/${elem?.imgdata?.path}`} alt="" className={s.card__img} />
                <div className={s.card__time}>
                    {getTimeAndDate('time',elem.dt)}
                </div>
                <div className={s.card__date}>
                    {getTimeAndDate('date',elem.dt)}
                </div>
            </div>

            {showModal
                &&
                <Modal setShowModal={setShowModal} showModal={showModal} >
                    <HistoryFullCard elem={elem} />
                </Modal>
            }


        </>

    );
};

export default HistoryFaceCard;
