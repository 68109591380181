import React from 'react';
import s from './MiniButton.module.css'
const MiniButton = (props) => {
    return (
        <>
            <button {...props} className={props.pos === 'left' ? s.minibuttonLeft : s.minibuttonRight}>
                {props.children}
            </button>
        </>
    );
};

export default MiniButton;