import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axios from "axios";

export const mainApi = createApi({
        reducerPath: 'locationsApi',
        baseQuery: fetchBaseQuery(
            {
                baseUrl: `/`
                }),
        endpoints: () => ({})
    })


