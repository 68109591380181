import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {publicRoutes, adminRoutes} from "../routes";
import {useDispatch, useSelector} from "react-redux";
import {fetchUser} from "../Redux/Slices/authSlice";
import InitialLoader from "../components/UI/InitialLoader/InitialLoader";
import filterRoutes from "../utils/filterRoutes";
import {refactSidebarList} from "../Redux/Slices/sidebarSlice";

const AppRouter = () => {
    const isAuth = useSelector(state => state.auth.isAuth)
    const isInitialLoading = useSelector(state => state.auth.isInitialLoading)
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.auth.currentUser)

    useEffect(() => {
        dispatch(fetchUser())
    }, [])

    const getCurrentRoutesList = () => {
        return filterRoutes(currentUser,adminRoutes)
    }
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/')
    },[isAuth])

    return (
        <>
            {!isInitialLoading
                ?
                <Routes>
                    {
                        isAuth === true
                        ?
                        currentUser !== null
                        &&
                            getCurrentRoutesList().map(({path,element}) =>
                        <Route key={path} path={path} element={element} />
                        )
                        :
                        publicRoutes.map(({path,element}) =>
                        <Route key={path} path={path} element={element} />
                        )

                    }
                </Routes>
                :
                <InitialLoader/>
            }

        </>

    );
};

export default AppRouter;
