import React from 'react';
import s from './VisitorsInformation.module.scss'
import {changeDate, setReverted} from "../../../Redux/Slices/camEventsSlice";
import {changeEventsDate} from "../../../Redux/Slices/eventsSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {accentColor} from "../../../utils/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faVideo} from "@fortawesome/free-solid-svg-icons";
import {EVENTS_ROUTE} from "../../../utils/pathss";
import {useTranslation} from "react-i18next/hooks";
import calcDateDiff from "../../../utils/calcDateDiff";
import getDayStartTime from "../../../utils/getDayStartTime";

const VisitorsInformation = ({mostActiveTime,getDate,currentCamera,camsList,isLoading,reportDateSecond,reportDateFirst}) => {
    const visitorsCount = useSelector(state => state.visitorsReport.visitorsCount)
    const reverted = useSelector(state => state.camEvents.reverted)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [t,i18n] = useTranslation()
    const goToCams = () => {
        const camera = currentCamera === '' ? camsList[0].id : currentCamera
        const date = new Date(reportDateFirst)
        const day = reverted === false ? 1 : -1
        date.setDate(date.getDate() + day)
        navigate(`/cams/${camera}`)
        dispatch(changeDate(reportDateFirst))
        dispatch(setReverted(false))
    }

    const goToEvents = () => {
        navigate(EVENTS_ROUTE)
        dispatch(changeEventsDate({datStart: getDayStartTime(reportDateFirst), datEnd:null}))
    }
    return (
        <div className={s.visitors__informationbox}>
            <div className={s.visitors__informbox}>
                    <div className={s.visitors__informTitle}>
                        {t('visitorsReport.visitors')}
                    </div>
                    <div className={s.visitors__informNum}>
                        {visitorsCount.visitors}
                    </div>
                    <div className={s.visitors__inform}>
                        <>
                            {t('visitorsReport.greatestVisitorsActivityRange')}
                            {
                                !isLoading
                                &&
                                <span style={{color:accentColor, fontWeight:'bold'}}>{mostActiveTime.visitors}</span>
                            }
                            </>
                    </div>

                </div>
            <div className={s.visitors__informbox}>
                <div className={s.visitors__informTitle}>
                    {t('visitorsReport.traffic')}
                </div>
                <div className={s.visitors__informNum}>
                    {visitorsCount.traffic}
                </div>
                <div className={s.visitors__inform}>
                    <>
                        {t('visitorsReport.greatestVisitorsActivityRange')}
                        {!isLoading
                            &&
                            <span style={{color:accentColor, fontWeight:'bold'}}>{mostActiveTime.traffic}</span>
                        }
                    </>
                </div>

            </div>
            <div className={s.visitors__informbox}>
                <div className={s.visitors__informTitle}>
                    {t('visitorsReport.percent')}
                </div>
                <div className={s.visitors__informNum}>
                        {visitorsCount.visitors && visitorsCount.traffic
                            ?
                            <div className={s.visitors__informNum}>
                               {Math.round((visitorsCount.visitors/visitorsCount.traffic)*100)}%
                            </div>
                            :
                            <div></div>
                        }

                </div>
                <div className={s.visitors__inform}>
                    {t('visitorsReport.postPercent')}
                </div>

            </div>
                    <>
                        <div className={calcDateDiff(reportDateFirst,reportDateSecond) === 1 ? s.visitors__informbox : s.visitors__informboxDisabled}>
                            <div className={s.visitors__informTitle}>
                                {t('visitorsReport.camRecords')}
                            </div>
                            <div className={calcDateDiff(reportDateFirst,reportDateSecond) === 1 ? s.visitors__goButton : s.visitors__goButtonDisabled} onClick={() => calcDateDiff(reportDateFirst,reportDateSecond) === 1 && goToCams()}>
                                <FontAwesomeIcon icon={faVideo} color={'#ffffff'}/>
                            </div>
                        </div>

                        <div className={ calcDateDiff(reportDateFirst,reportDateSecond) === 1 ? s.visitors__informbox : s.visitors__informboxDisabled}>
                            <div className={s.visitors__informTitle}>
                                {t('visitorsReport.eventRecords')}
                            </div>
                            <div className={calcDateDiff(reportDateFirst,reportDateSecond) === 1 ? s.visitors__goButton : s.visitors__goButtonDisabled} onClick={() => calcDateDiff(reportDateFirst,reportDateSecond) === 1 && goToEvents()}>
                                <FontAwesomeIcon icon={faCalendarDays} color={'#ffffff'}/>
                            </div>
                        </div>
                    </>
        </div>
    );
};

export default VisitorsInformation;
