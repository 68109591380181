import React, {useState} from 'react';
import CamStepFrame from "../CamStepsModal/CamStepFrame";
import Modal from "../../../UI/Modal/Modal";
import CamStep1 from "../CamStepsModal/camStep1/camStep1";
import CamStep2 from "../CamStepsModal/camStep2/camStep2";
import {useDispatch} from "react-redux";
import HikcentralStepView from "../HikcentralSteps/HikcentralStepView/HikcentralStepView";
import HikcentralStepTimezone from "../HikcentralSteps/HikcentralStepTimezone/HikcentralStepTimezone";
import HikcentralStepName from "../HikcentralSteps/HikcentralStepName/HikcentralStepName";
import HickcentralStepLocation from "../HikcentralSteps/HikcentralStepLocation/HickcentralStepLocation";
import {postHikcentralCamera} from "../../../../Redux/Slices/camSlice";
import {useTranslation} from "react-i18next/hooks";
import HikcentralStepLast from "../HikcentralSteps/HikcentralStepLast/HikcentralStepLast";
import {usePostCameraMutation} from "../../../../Redux/APIs/otherAPI/camerasApi";

const AddHikcentralCamera = ({setShowModal}) => {
    const [step, setStep] = useState(0)
    const [camId,setCamId] = useState('')
    const [view,setView] = useState(1)
    const [timezone,setTimezone] = useState('')
    const [location,setLocation] = useState('')
    const [name,setName] = useState('')
    const [postCamera,res] = usePostCameraMutation()
    const postNewCamera = () => {
        postCamera({name,view,timezone,location,camId})
        //dispatch(postHikcentralCamera({name,view,timezone,location,camId}))

    }
    const [t,i18n] = useTranslation()
    const steps = [
        {id:1, elem: <HikcentralStepName  setName={setName} setStep={setStep} step={step} subtext={t('camerasPage.addCameraModal.typeCameraNameDescr')} text={t('camerasPage.addCameraModal.typeCameraName')}/>},
        {id:2, elem: <CamStep1 setCamId={setCamId} setStep={setStep} step={step}  subtext={t('camerasPage.addCameraModal.typeCamCodeDescr')} text={t('camerasPage.addCameraModal.typeCamCode')}/> },
        {id:3, elem: <HikcentralStepView subtext={t('camerasPage.addCameraModal.selectVisionType')} setView={setView} setStep={setStep} step={step} text={t('camerasPage.addCameraModal.visionSettings')}/>},
        {id:4, elem: <HikcentralStepTimezone  setTimezone={setTimezone} setStep={setStep} step={step} text={t('camerasPage.addCameraModal.selectTimezone')}/>},
        {id:5, elem: <HickcentralStepLocation postNewCamera={postNewCamera} subtext={t('camerasPage.addCameraModal.selectLocationDescr')} setLocation={setLocation} setStep={setStep} step={step} text={t('camerasPage.addCameraModal.selectLocation')}/>},
        {id:6, elem: <HikcentralStepLast res={res} setShowModal={setShowModal} last={true} setStep={setStep} step={step} text={t('camerasPage.addCameraModal.success')}/>},
    ]

    return (
        <Modal setShowModal={setShowModal} >
            <div className={`wrapper`}>
                <CamStepFrame steps={steps} setStep={setStep} step={step} setShowModal={setShowModal} >
                    {
                        steps[step].elem
                    }
                </CamStepFrame>
            </div>
        </Modal>
    );
};

export default AddHikcentralCamera;