import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import s from './CamSliderImg.module.css'
import {PROXY} from "../../../utils/pathss";
import {useDispatch, useSelector} from "react-redux";
import {setCanShow} from "../../../Redux/Slices/camEventsSlidesSlide";

const CamSliderImg = (props) => {
    const sliderPos = useSelector(state => state.camSlides.sliderPos)
    const dispatch = useDispatch()
    const canShow = useSelector(state => state.camSlides.canShow)

    useEffect(() => {
        if(props.camSlides[sliderPos]?.ss !== 0){
            dispatch(setCanShow(false))
        }else if(props.camSlides[sliderPos]?.ss === 0){
        }
    },[sliderPos])

    const getTime = useMemo(() => {
        const dat = new Date(props?.camSlides[sliderPos]?.dt)
        const month = `${dat.getMonth()+1 < 10 ? '0' : ''}${dat.getMonth()+1}`
        const day = `${dat.getDate() < 10 ? '0' : ''}${dat.getDate()}`
        const hours = `${dat.getHours() < 10 ? '0' : ''}${dat.getHours()}`
        const minutes = `${dat.getMinutes() < 10 ? '0' : ''}${dat.getMinutes()}`
        const seconds = `${dat.getSeconds() < 10 ? '0' : ''}${dat.getSeconds()}`
        return `${month}-${day}-${dat.getFullYear()} ${hours}:${minutes}:${seconds}`
    },[sliderPos])

    return (
        <>
            <img src={`${PROXY}api/${props?.camSlides[sliderPos]?.path}`} alt="" className={s.camslider__image}
                 onLoad={() => {
                     dispatch(setCanShow(true))
                 }}
                 style={{zIndex:`${props.id === sliderPos ? 1 : 0}`}}
            />
            <div style={{zIndex: `${props.id === sliderPos ? 1 : 0}`}} className={props.fullscreen ? s.camslider__timeFS : s.camslider__time}>{getTime}</div>
        </>
    );
};

export default CamSliderImg;
