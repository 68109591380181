import React, {useEffect} from 'react';
import s from './Main.module.scss'
import MyButton from "../../components/UI/MyButton/MyButton";
import MainContent from "../../components/Main/MainContent/MainContent";

const Main = () => {

    return (
        <div className={s.mainbox}>
            <MainContent/>
        </div>
    );
};

export default Main;