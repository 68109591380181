import React from 'react';
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import AgeTooltip from "../ageTooltip/ageTooltip";

const AgeChart = ({data}) => {
    const re = (cList) => {
        const list = []
        for(let i in cList[0]){
            if(i !== 'date'){
                list.push({age:i})
            }
        }
        return list
    }
    return (
        <ResponsiveContainer>
            <AreaChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} data={data}>
                <CartesianGrid  strokeDasharray="3 3" />
                <>
                    <Area legendType={'square'} type="monotone" dataKey={'0-18'} stroke="#F0A58F" fill={'#F0A58F'}  />
                    <Area legendType={'square'} type="monotone" dataKey={'19-30'} stroke="#EA7369" fill={'#EA7369'}  />
                    <Area legendType={'square'} type="monotone" dataKey={'31-45'} stroke="#EB548C" fill={'#EB548C'}  />
                    <Area legendType={'square'} type="monotone" dataKey={'46-60'} stroke="#DB4CB2" fill={'#DB4CB2'}  />
                    <Area legendType={'square'} type="monotone" dataKey={'60+'} stroke="#29066B" fill={'#29066B'}  />




                </>
                <Legend />
                <Tooltip content={<AgeTooltip/>} />
                <XAxis tickSize={1} stroke={`rgba(0,0,0,0.3)`} dataKey='date' tickMargin={10} />
                <YAxis tickSize={1} allowDecimals={false} stroke={`rgba(0,0,0,0.3)`}  axisLine={true}  tickMargin={10} tickLine={false} tickCount={8}/>
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default AgeChart;
