import React, {useState} from 'react';
import s from './EventsSliderPreview.module.css'
import {useDispatch, useSelector} from "react-redux";
import EventsSliderPreviewImg from "../EventsSliderPreviewImg/EventsSliderPreviewImg";
import {addPreviewSlides, setSliderPos} from "../../../../Redux/Slices/eventsSlidesSlice";

const EventsSliderPreview = (props) => {
    const {fullScreen} = {...props}
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.eventsSlides.isLoading)
    const slides = useSelector(state => state.eventsSlides.eventSlides)
    const eventCurrentSlides = useSelector(state => state.eventsSlides.eventsCurrentPreviewSlides)
    const sliderPos = useSelector(state => state.eventsSlides.sliderPos)
    const [previewPos, setPreviewPos] = useState(0)
    const setSlider = (id) => {
        dispatch(setSliderPos(id))

    }
    const handleWheel = (e) => {
        if(e.deltaY < 0 && ((eventCurrentSlides.length * -75)+ 10*75)  < previewPos){
            setPreviewPos(previewPos-75)
        }else if(e.deltaY < 0 && ((eventCurrentSlides.length * -75)+ 10*75) === previewPos){
            dispatch(addPreviewSlides( {pos: (previewPos/(-75)+30) ,slides:slides} ))
        }
        else if(e.deltaY > 0 && previewPos < 0 ){
            setPreviewPos(previewPos+75)
        }
    }
    return (
        <div className={fullScreen === true ? s.eventsSlider__previewFull :s.eventsSlider__preview} onWheel={(e) => handleWheel(e)}>
            <div className={s.eventsSlider__previewSlider} style={{transform:`translateX(${previewPos}px)`}} >
                {isLoading === false
                    &&
                    eventCurrentSlides.length > 0
                    &&
                    eventCurrentSlides.map((elem,id) =>
                        <EventsSliderPreviewImg key={id} elem={elem} setSlider={setSlider} sliderPos={sliderPos} id={id} />
                    )
                }
            </div>
        </div>
    );
};

export default EventsSliderPreview;