import React from 'react';
import s from './TimeInput.module.scss'
import InputMask from 'react-input-mask';

const TimeInput = ({time,setTime}) => {
    const validation = (value) => {
        const hour1 = value.slice(0,1)
        const hour2 = value.slice(1,2)
        const min1 = value.slice(3,4)
        const min2 = value.slice(4,5)
        if(hour1 > 2){
            setTime(`2${hour2}:${min1}${min2}`)
        }else if(+hour1 === 2 && +hour2 > 3){
            setTime(`23:${min1}${min2}`)
        }else if(+min1 > 5){
            setTime(`${hour1}${hour2}:5${min2}`)
        }
        else{
            setTime(`${hour1}${hour2}:${min1}${min2}`)
        }
    }
    return (
        <div className={s.input}>
            <InputMask
                mask='99:99'
                maskChar={'0'}
                placeholder={'00:00'}
                value={time}
                onChange={(e) => validation(e.target.value)}>
                {(inputProps) => <input className={s.input__input} {...inputProps} />}
            </InputMask>
        </div>
    );
};

export default TimeInput;