import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    currentGroupPage:0,
    isFreq:true,
    currentPage:0,

}
export const personSlice = createSlice({
    name: 'person',
    initialState,
    reducers: {
        goPagination: (state,action) => {
            state.currentPage = state.currentPage + 1
        },
        backPagination: (state,action) => {
            state.currentPage -= 1
        },
        setPagination: (state,action) => {
            state.currentPage = action.payload
        },
        setGroupPagination: (state,action) => {
            state.currentGroupPage = action.payload
        },
        goGroupPagination: (state,action) => {
            state.currentGroupPage = state.currentGroupPage + 1
        },
        backGroupPagination: (state,action) => {
            state.currentGroupPage -= 1
        },
        setCurrPath:(state,action) => {
            state.currPath = action.payload
        },
        setIsFreq:(state, action) => {
            state.currentPage = 0
            state.isFreq = action.payload
        }
    }
})


export const {
    goPagination,
    backPagination,
    setPagination,
    setGroupPagination,
    goGroupPagination,
    setIsFreq,
    backGroupPagination} = personSlice.actions

export default personSlice.reducer
