import {useEffect, useState} from "react";


export function  useDebounce(value,delay = 300){
    const [deb, setDeb] = useState(value)

    useEffect(() =>{
        const handler = setTimeout(() => setDeb(value),delay)
        return () => clearTimeout(handler)
    },[value,delay])
    return deb
}