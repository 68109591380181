const visitsHistoryConvertDays = (history,dateStart,dateEnd) => {
    console.log(history)
    const datStart = new Date(dateStart).getDate()
    const datEnd = new Date(dateEnd).getDate()
    const convertedHistory = []
    for(let i in history){
        const dayHistorys = []
        for(let i = datStart; i < datEnd+1; i++){
            const dat = new Date(dateStart)
            dat.setDate(i)
            const day = `${i <= 9 ? '0' + i : i}.${dat.getMonth() <= 9 ? '0' + (dat.getMonth()+1) :( dat.getMonth()+1)}`
            dayHistorys.push({date:day,history: []})
        }

        const personHistory = history[i].events
        for(let j in personHistory){
            const elemDate = new Date(personHistory[j].dt).getDate()
            dayHistorys.find(elem => +elem.date.slice(0,2) === elemDate).history?.push(personHistory[j])
        }
        const total = dayHistorys.filter(elem => elem.history.length > 0).length
        const newElem = {person_id:i,
            personGender:history[i].info.gender,
            personAge:history[i].info.age,
            personImg:history[i].info.thumbnail,
            totalVisits:total,
            history: dayHistorys}
        convertedHistory.push(newElem)
    }
    return convertedHistory
}
export default visitsHistoryConvertDays;
