import React, {useEffect, useRef, useState} from 'react';
import s from './PersonReports.module.scss'
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PersonBigTable from "./PersonBigTable/PersonBigTable";
import PersonReportsPhotos from "./PersonReportsPhotos/PersonReportsPhotos";
import {fetchPersonAttendance} from "../../Redux/Slices/personReportSlice";
import PersonReportsNotFound from "./PersonReportsNotFound/PersonReportsNotFound";
const PersonReports = () => {
    const attendanceDate = useSelector(state => state.personReports.attendanceDate)
    const id = useParams().id.split('_')[0]
    const name = useParams().id.split('_')[1]
    const dispatch = useDispatch()

    useEffect(() => {
        if(id !== ':id' && attendanceDate){
            dispatch(fetchPersonAttendance({datStart:attendanceDate.datStart,datEnd:attendanceDate.datEnd,person:id}))
        }
    },[attendanceDate,id])

    const boxRef = useRef(null)

    return (
        <div className={s.personReports}>
            <PersonReportsPhotos id={id} name={name}/>
            <div className={s.personReports__tables}>
                {
                    id !== ':id'
                    ?
                        <PersonBigTable boxRef={boxRef}/>

                    :
                        <PersonReportsNotFound/>
                }
            </div>
        </div>
    );
};

export default PersonReports;
