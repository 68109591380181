import React, {useRef, useState} from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import './dtr.css';
import s from './DateRangeCalend.module.css'
import * as locales from 'react-date-range/dist/locale';
import { DateRange } from 'react-date-range';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay, faX} from "@fortawesome/free-solid-svg-icons";
import setTimeToZero from '../../.././utils/timezoning'
import {useClickOutside} from "../../../Hooks/useClickOutside/useClickOutside";
import GreenButton from "../../UI/GreenButton/GreenButton";
import {useTranslation} from "react-i18next/hooks";
import TimeRangeInput from "../../UI/TimeRangeInput/TimeRangeInput";

const DateRangeCalend = (props) => {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [open,setOpen] = useState(false)
    const [time,setTime] = useState('')
    const handleOutsideClick = () => {
        setOpen(false)
    }

    const sendDate = () => {
        const fd = new Date(state[0].startDate)
        const sd = new Date(state[0].endDate)
        const vTime = time.replace(/\D/g, '')
        if(vTime !== '' && vTime !== '00000000'){
            fd.setHours(vTime.slice(0,2))
            fd.setMinutes(vTime.slice(2,4))
            sd.setHours(vTime.slice(4,6))
            sd.setMinutes(vTime.slice(6,8))
        }else if(vTime === '' || vTime === '00000000'){
            fd.setHours(0)
            fd.setMinutes(0)
            sd.setHours(23)
            sd.setMinutes(59)
            sd.setSeconds(59)
        }
        props.chDate(fd.toISOString(),sd.toISOString())
        setOpen(false)
    }

    const calendRef = useRef()
    useClickOutside(calendRef,handleOutsideClick)
    const [t,i18n] = useTranslation()
    return (
        <div ref={calendRef}>
            <div className={s.datarange__icon} onClick={() => setOpen(!open)}
            >
                <FontAwesomeIcon icon={faCalendarDay} width={14}/>
                <span>
                    {t('UI.dateSelect.dateSelect')}
                </span>
            </div>
            {open
                &&
                <div className={s.datarange__calend}>
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => {
                            setState([item.selection])

                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        locale={locales[i18n.resolvedLanguage]}
                        onRangeFocusChange={(e) => {
                        }}
                    />
                    <div className={s.datarange__bottomPanel}>
                        {/*{t('UI.dateSelect.startTime')}:*/}
                        <TimeRangeInput time={time} setTime={setTime}/>
                        <div className={s.datarange__clear} onClick={() =>  {
                            setTime('')
                        } }>
                            <FontAwesomeIcon icon={faX}/>
                        </div>
                    </div>
                    <GreenButton onClick={() => sendDate()} light={true}>
                        {t('UI.dateSelect.apply')}
                    </GreenButton>
                </div>

            }

        </div>
    );
};

export default DateRangeCalend;
