import React, {useEffect, useState} from 'react';
import s from './FacesSearchAllVisits.module.scss'
import {useGetPersonAllVisitsQuery} from "../../../../Redux/APIs/otherAPI/personAllVisitsApi";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import Modal from "../../../UI/Modal/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSadTear} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../UI/Loader/Loader";
import AllVisitsImage from "./AllVisitsImage/AllVisitsImage";
import {useTranslation} from "react-i18next/hooks";
const FacesSearchAllVisits = ({person}) => {
    const {data,isLoading} = useGetPersonAllVisitsQuery({person:person})
    const [showFullImage,setShowFullImage] = useState(false)
    const [currentImage,setCurrentImage] = useState('')
    const [visits,setVisits] = useState([])
    const [t, i18n] = useTranslation()
    console.log(data)
    useEffect(() => {
        if(data){
            const atten = data.attendance
            const list = []
            for(let i in atten){
                list.push({first:atten[i].first,last:atten[i].last,image:atten[i].first_url})
            }
            setVisits(list)
        }
    },[isLoading])
    return (
        <div className={s.popup}>
            {!isLoading && visits?.length > 0
                &&
                <div className={s.popup__title}>
                    {t('persons.persons.table.allVisit')}
                </div>
            }
            {isLoading
                ?
                <div className={s.popup__loader}>
                    <Loader/>
                </div>
                :
                visits.length > 0
                ?
                <div className={s.popup__list}>
                    {
                        visits.map((elem,id) =>
                            <div className={s.popup__elem}>
                                <AllVisitsImage url={elem.image} setShowFullImage={setShowFullImage} setCurrentImage={setCurrentImage}/>
                                <div className={s.popup__info}>
                                    <div className={s.popup__date}>
                                        {t('persons.persons.table.firstVisit')} : <span className={s.popup__dateSpan}>{getTimeAndDate('date',elem.first)} - {getTimeAndDate('time',elem.first)}</span>
                                    </div>
                                    {elem.last
                                        &&
                                        <div className={s.popup__date}>
                                            {t('persons.persons.table.lastVisit')}: <span className={s.popup__dateSpan}>
                                                            {getTimeAndDate('date',elem.last)} - {getTimeAndDate('time',elem.last)}
                                                        </span>
                                        </div>
                                    }

                                </div>
                            </div>
                        )
                    }
                </div>
                :
                <div className={s.popup__notFound}>
                    <FontAwesomeIcon icon={faSadTear}/>
                    <div className={s.popup__notFoundTitle}>
                        Не найдено визитов персоны
                    </div>

                </div>
            }
            {showFullImage
                &&
                <Modal setShowModal={setShowFullImage} showModal={showFullImage}>
                    <img onClick={() => setShowFullImage(false)} src={currentImage} alt=""/>
                </Modal>
            }
        </div>
    );
};

export default FacesSearchAllVisits;
