import React, {useState} from 'react';
import s from './EventsSliderControls.module.scss'
import {stopSlider} from "../../../../Redux/Slices/eventsSlidesSlice";
import fullScreenImg from "../../../../public/Icons/fullScreen.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useDispatch, useSelector} from "react-redux";
import {faArrowRight, faArrowLeft, faPause, faPlay, faMaximize} from "@fortawesome/free-solid-svg-icons";

const EventsSliderControls = ({fullScreen,setFullScreen,currentId,slidesLength,setId}) => {
    const isSliderPlay = useSelector(state => state.eventsSlides.isSliderPlay)
    const events = useSelector(state => state.events.eventsPagination)
    const [isSliderAct, setIsSliderAct] = useState(false)
    const dispatch = useDispatch()
    const [showMobileNext, setShowMobileNext] = useState(false)

    const fetchNextSlides = (opt) => {
        if(opt === 'next'){
            const arr = events.flat(1)
            const nextElem = arr.findIndex((elem) => elem.id > currentId)
            if(nextElem > 0 && nextElem < arr.length){
                setId(arr[nextElem].id)
            }
        }else if(opt === 'previous'){
            const arr = events.flat(1)
            const prevElem = arr.findIndex((elem) => elem.id === currentId)
            if(prevElem > 0 && prevElem < arr.length){
                setId(arr[prevElem-1].id)
            }
        }
    }
    const startTimeOut = () => {
        var timeout = setTimeout(() => {
            setShowMobileNext(false)
        }, 2000)
        return () => clearTimeout(timeout)
    }

    return (
        <>
            <div style={{opacity:`${showMobileNext ? '1' : '0'}`}} className={s.eventsSlider__controlsNextSlide} onClick={() => {
                setShowMobileNext(true)
                startTimeOut()
                if(showMobileNext){
                    fetchNextSlides('next')
                    setShowMobileNext(false)
                }
            }}>
                <FontAwesomeIcon icon={faArrowRight}/>
            </div>

            <div className={fullScreen ? s.eventsSlider__controlsFull : s.eventsSlider__controls}>
                <div className={s.eventsSlider__nextBox}>
                    {events.flat(1).findIndex((elem) => elem.id === currentId) > 0 && events.flat(1).findIndex((elem) => elem.id === currentId) < events.flat(1).length
                        &&
                        <div className={s.eventsSlider__next} onClick={() => fetchNextSlides('previous')}>
                            <FontAwesomeIcon icon={faArrowLeft} size={'xs'}/>
                        </div>
                    }
                </div>
                <div onClick={() => {
                    isSliderAct === false
                    &&
                    dispatch(stopSlider())
                }} className={s.eventsSlider__play} >
                    {isSliderPlay === true
                        ?
                        <FontAwesomeIcon icon={faPause} size='12px'/>
                        :
                        <FontAwesomeIcon icon={faPlay} style={{marginLeft:'2px'}} />
                    }
                </div>

                <div className={s.eventsSlider__fullScreenButton} onClick={() => {
                    setFullScreen(!fullScreen)
                }} >
                    <FontAwesomeIcon icon={faMaximize}/>
                </div>
                <div className={s.eventsSlider__nextBox}>
                    {(events.flat(1).findIndex((elem) => elem.id > currentId) > 0) && (events.flat(1).findIndex((elem) => elem.id > currentId) < events.flat(1).length)
                        &&
                        <div className={s.eventsSlider__next} onClick={() => fetchNextSlides('next')}>
                            <FontAwesomeIcon icon={faArrowRight} size={'xs'}/>
                        </div>
                    }
                </div>
            </div>
            {!isSliderPlay
                &&
                <div className={s.eventsSlider__controlsMobileStop} onClick={() => dispatch(stopSlider())}>
                    <FontAwesomeIcon icon={faPause} size={'xl'}/>
                </div>
            }

            <div className={s.eventsSlider__nextBox}>
                {(events.flat(1).findIndex((elem) => elem.id > currentId) < 0) && (events.flat(1).findIndex((elem) => elem.id > currentId) < events.flat(1).length)
                    &&
                    <div style={{opacity:`${showMobileNext ? '1' : '0'}`}} className={s.eventsSlider__controlsPreviousSlide} onClick={() => {
                        setShowMobileNext(true)
                        startTimeOut()
                        if(showMobileNext){
                        fetchNextSlides('previous')
                        setShowMobileNext(false)}}}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} color={'#BFBFBF'}/>
                    </div>
                }
            </div>
        </>
    );
};

export default EventsSliderControls;
