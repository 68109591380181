import React, {useState} from 'react';
import s from "../FullPerson.module.scss";
import {PROXY} from "../../../../../utils/pathss";
import {faImage, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "../../../../UI/Modal/Modal";
import {useTranslation} from "react-i18next/hooks";
import leftHead from '../../../../../public/headIcons/leftHead.svg'
import rightHead from '../../../../../public/headIcons/rightHead.svg'
import frontalHead from '../../../../../public/headIcons/frontalHead.svg'
import fleftHead from '../../../../../public/headIcons/left45Head.svg'
import frightHead from '../../../../../public/headIcons/right45Head.svg'
const FullPersonPhoto = ({elem,type}) => {
    const [full,setFull] = useState(false)
    const [t,i18n] = useTranslation()
    const images = {
        'frontal':frontalHead,
        'right':rightHead,
        'left':leftHead,
        'fleft':fleftHead,
        'fright':frightHead
    }
    return (
        <>
            <div className={s.fullperson__photoBox}>
                {elem
                    ?
                        <>
                            <div className={s.fullperson__photo}>
                                <img src={`${PROXY}api/${elem?.path}`} alt=""/>
                                <div className={s.fullperson__photoBanner} onClick={() => setFull(true)}>
                                    <FontAwesomeIcon icon={faSearch} size={'2x'} color={'#BFBFBF'}/>
                                </div>
                            </div>
                            {full
                                &&
                                <Modal setShowModal={setFull}>
                                    <div className={s.fullperson__photoFull}>
                                        <img src={`${PROXY}api/${elem?.path}`} alt="" onClick={() => setFull(false)}/>
                                    </div>
                                </Modal>
                            }
                        </>
                    :
                    <div className={s.fullperson__noPhoto}>
                        <img src={images[type]} alt=""/>
                    </div>
                }

                <div style={{color:'#bfbfbf',fontSize:12}}>{t(`persons.changePhoto.${type}`)}</div>
            </div>

        </>

    );
};

export default FullPersonPhoto;