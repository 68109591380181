import React, {useState} from 'react';
import s from './CamsRename.module.css'
import JustInput from "../../UI/JustInput/JustInput";
import GreenButton from "../../UI/GreenButton/GreenButton";
import MyButton from "../../UI/MyButton/MyButton";
import {useTranslation} from "react-i18next/hooks";


const CamsRename = (props) => {

    const [name,setName] = useState()

    const [t,i18n] = useTranslation()

    return (
        <div className={s.camsrename}>
            <div className={s.camsrename__title}>
                {t('camerasPage.camsRename.title')}
            </div>
            <div className={s.camsrename__text}>
                {t('camerasPage.camsRename.text')}
            </div>
            <div>
                <JustInput onChange={(e) => setName(e.target.value)} value={name} />
            </div>
            <div className={s.camsrename__buttons}>
                <GreenButton>
                    {t('camerasPage.camsRename.button')}
                </GreenButton>
                <GreenButton bleach onClick={() => props.setShowModal(false)} >
                    {t('default.back')}
                </GreenButton>
            </div>
        </div>
    );
};

export default CamsRename;