import React, {useState} from 'react';
import s from './EventsSliderPreviewImg.module.scss'
import {useDispatch} from "react-redux";
const EventsSliderPreviewImg = ({elem, sliderPos, id,setSlider}) => {
    const dispatch = useDispatch()
    const [show,setShow] = useState(false)
    return (
        <div className={s.previewSlide}>
            <img src={`api/${elem.path}`}
                 alt=""
                 className={sliderPos === id ? s.previewSlide__imgActive : s.previewSlide__img}
                 onClick={() => dispatch(setSlider(id))}
                 onLoad={() => setShow(true)}
            />
            {
                !show
                    &&
                <div className={s.previewSlide__banner}></div>
            }

        </div>
    );
};

export default EventsSliderPreviewImg;