import React, {useEffect, useState} from 'react';
import s from "../../CamStepsModal/camStep.module.css";
import GreenButton from "../../../../UI/GreenButton/GreenButton";
import {useDispatch, useSelector} from "react-redux";
import {fetchLocation} from "../../../../../Redux/Slices/locationSlice";
import {NavLink} from "react-router-dom";
import {LOCATIONS_ROUTE} from "../../../../../utils/pathss";
import {useTranslation} from "react-i18next/hooks";

const HickcentralStepLocation = (props) => {
    const [location,setLocation] = useState('')
    const locations = useSelector(state => state.location.locations)
    const isLoading = useSelector(state => state.location.isLoading)
    const dispatch = useDispatch()
    useEffect(() => {
        if(locations.length === 0){
            dispatch(fetchLocation())
        }
    },[dispatch])

    const [t,i18n] = useTranslation()
    const goToNextStep = () => {
        if( !props.last && location){
            props.setStep(props.step+1)
            props.setLocation(location)
            props.postNewCamera()
        }
    }

    return (
        <div>
            <div className={s.camstepWrapper}>
                <div className={s.camstep__boldText}>
                    {props.text}
                </div>
                <div className={s.camstep__lightText}>
                    {props.subtext}
                </div>
                {!isLoading
                    ?
                    locations.length > 0
                        ?
                        <select className={s.locations} onChange={(e) => setLocation(e.target.value)}>
                            <option value="" defaultChecked={true}></option>
                            {
                                locations.map((elem,id) =>
                                    <option value={elem.id}>
                                        {elem.name}
                                    </option>
                                )
                            }
                        </select>
                        :
                        <div>
                            Локаций не найдено, вы можете их добавить в <NavLink style={{color:'#08979C'}} to={LOCATIONS_ROUTE}>Локации</NavLink>
                        </div>

                    :
                    <div>
                        {t('default.loading')}
                    </div>
                }


                <div>
                    <GreenButton onClick={() => goToNextStep()} >
                        {t('default.continue')}
                    </GreenButton>
                </div>

            </div>
        </div>
    );
};

export default HickcentralStepLocation;