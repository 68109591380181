import React, {useEffect, useState} from 'react';
import s from './MainHeader.module.scss'
import MainContentContainer from "../MainContent/MainContentContainer";
import { Link, animateScroll as scroll } from "react-scroll";
import BlueButton from "../../UI/BlueButton/BlueButton";
import {NavLink, useNavigate} from "react-router-dom";
import {LOGIN_ROUTE} from "../../../utils/pathss";
import {useTranslation} from "react-i18next/hooks";
import langs from "../../../utils/langs";
const MainHeader = () => {
    const [headerAct,setHeaderAct] = useState(false)
    const navig = useNavigate()
    const wheelHandler = (e) => {
        if(e.deltaY < 0 && e.view.pageYOffset > 250){
            setHeaderAct(true)
        }
        else if(e.deltaY > 0 || e.view.pageYOffset < 10){
            setHeaderAct(false)
        }
    }
    useEffect(() => {
        document.addEventListener('wheel',wheelHandler)
        return () => document.removeEventListener('wheel',wheelHandler)
    },[])
    const [t,i18n] = useTranslation()
    return (
        <div className={s.headerCont}>
            <div className={headerAct ? s.headerboxAct : s.headerbox}>
                <MainContentContainer>
                    <div className={s.header}>
                        <div className={s.header__list}>
                            <div className={s.header__elem}>
                                <Link
                                    className={s.header__link}
                                    to="opportunitiesSection"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={300}>
                                    {t('main.header.opportunities')}
                                </Link>
                            </div>
                            <div className={s.header__elem}>
                                <Link className={s.header__link}
                                      to="audienceSection"
                                      spy={true}
                                      smooth={true}
                                      offset={0}
                                      duration={300}>
                                    {t('main.header.audience')}
                                </Link>
                            </div>
                            <div className={s.header__elem}>
                                <Link className={s.header__link}
                                      to="howWorkSection"
                                      spy={true}
                                      smooth={true}
                                      offset={0}
                                      duration={300}>
                                    {t('main.header.howWork')}
                                </Link>
                            </div>
                            <div className={s.header__elem}>
                                <Link className={s.header__link}
                                      to="ratesSection"
                                      spy={true}
                                      smooth={true}
                                      offset={100}
                                      duration={300}>
                                    {t('main.header.rates')}
                                </Link>
                            </div>
                            <div className={s.header__elem}>
                                <Link className={s.header__link}
                                      to="footerSection"
                                      spy={true}
                                      smooth={true}
                                      offset={100}
                                      duration={300}>
                                    {t('main.header.contacts')}
                                </Link>
                            </div>
                        </div>
                        <div className={s.header__langs}>
                            {Object.keys(langs).map((lng) =>
                                <button style={{color: i18n.resolvedLanguage === lng ? '#ffffff' : 'black', backgroundColor: i18n.resolvedLanguage === lng ? '#109CF1' : 'rgba(0,0,0,0)'}} className={s.header__lang} type={'submit'} onClick={() => i18n.changeLanguage(lng)}>
                                    {langs[lng].nativeName}
                                </button>
                            )}
                        </div>
                        <div className={s.header__button}>
                            <BlueButton onClick={() => navig(LOGIN_ROUTE) }>
                                {t('auth.logIn')}
                            </BlueButton>
                        </div>

                    </div>

                </MainContentContainer>
            </div>

        </div>
    );
};

export default MainHeader;