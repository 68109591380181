import React, {useEffect, useState} from 'react';
import s from './Event.module.css'
import getTimeAndDate from "../../../utils/getTimeAndDate";
import {useSelector} from "react-redux";

const Event = (props) => {
    const [show,setShow] = useState(false)
    const currentPage = useSelector(state => state.events.currentPage)
    useEffect(() => {
        setShow(false)
    },[currentPage])
    return (
        <div className={s.event} key={props.id}>
            <div className={s.event__img}>
                {show === false
                    &&
                    <div className={s.event__imgLoader}></div>
                }
                <img src={`api/${props.elemPath}`} alt="" onClick={() => {
                    props.setId(props.elemId)
                    props.setSs(props.elemSs)
                    props.setShowModal(true)
                }}
                     onLoad={() => setShow(true)}
                />


            </div>
            <div className={s.event__info}>
                <div className={s.event__time}>
                    {getTimeAndDate('time',props.elemDt)}

                </div>
                <div className={s.event__time}>
                    {getTimeAndDate('date',props.elemDt)}
                </div>
            </div>
        </div>
    );
};

export default Event;