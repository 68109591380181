import {createSlice, current} from '@reduxjs/toolkit'
import {
    ADMIN_MONITORING_ROUTE, ADMIN_ROUTE,
    CAMS_ROUTE, EVENTS_ROUTE,
    FACES_PERSONS_ROUTE,
    FACES_ROUTE, FACES_SEARCH_ROUTE,
    LOCATIONS_ROUTE,
    REPORT_PERSON_ROUTE,
    REPORT_VISITORS_ROUTE,
    REPORTS_ROUTE, REPOST_VISITS_HISTORY_ROUTE
} from "../../utils/pathss";
import getUserNameRole from "../../Router/utils/getUserNameRole";


const initialState = {
    sidebarIsOpen:false,
    sidebarItems:[
        {
            name:'cams',
            imag:'cam',
            path: CAMS_ROUTE,
        },
        {
            name:'locations',
            imag:'mark',
            path:LOCATIONS_ROUTE,
        },
        {
            name:'faces',
            imag:'face',
            path:FACES_ROUTE,
            subItems:[
                {
                    name:'persons',
                    path:FACES_PERSONS_ROUTE,
                },
                {
                    name:'search',
                    path:FACES_SEARCH_ROUTE,
                },
            ]
        },
        {
            name:'events',
            imag:'calend',
            path:EVENTS_ROUTE,
        },
        {
            name:'reports',
            imag:'arrows',
            path:REPORTS_ROUTE,
            subItems:[
                {
                    name:'visitors',
                    path:REPORT_VISITORS_ROUTE,
                },
                {
                    name:'workTime',
                    path:REPORT_PERSON_ROUTE,
                },
                {
                    name:'visitsHistory',
                    path:REPOST_VISITS_HISTORY_ROUTE,
                },
            ]
        },
        {
            name:'admin',
            imag:'admin',
            path:ADMIN_ROUTE,
            subItems:[
                {
                    name:'monitoring',
                    path:ADMIN_MONITORING_ROUTE,
                },
            ]
        },


    ]
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        openSideBar: (state) => {
            state.sidebarIsOpen = !state.sidebarIsOpen
        },
        closeSideBar: (state) => {
            state.sidebarIsOpen = false
        },
        refactSidebarList:(state,action) => {
            let list = [...current(state.sidebarItems)]
            if(!getUserNameRole(action.payload.user.email)){
                list = list.filter(elem => elem.name !== 'admin')
            }
            if(!action.payload.user.org.func.person_search){
                const currentIndex = list.findIndex(elem => elem.name === 'faces')
                const newElem = {...list[currentIndex], subItems:list[currentIndex].subItems.filter(elem => elem.name !== 'search')}
                list[currentIndex] = newElem
            }
            if(!action.payload.user.org.func.regular_customers_report){
                const currentIndex = list.findIndex(elem => elem.name === 'reports')
                const newElem = {...list[currentIndex], subItems:list[currentIndex].subItems.filter(elem => elem.name !== 'visitsHistory')}
                list[currentIndex] = newElem
            }
            if(!action.payload.user.org.func.visitors_report){
                const currentIndex = list.findIndex(elem => elem.name === 'reports')
                const newElem = {...list[currentIndex], subItems:list[currentIndex].subItems.filter(elem => elem.name !== 'visitors')}
                list[currentIndex] = newElem
            }
            if(!action.payload.user.org.func.worktime_report){
                const currentIndex = list.findIndex(elem => elem.name === 'reports')
                const newElem = {...list[currentIndex], subItems:list[currentIndex].subItems.filter(elem => elem.name !== 'workTime')}
                list[currentIndex] = newElem
            }
            state.sidebarItems = list
        }

    },
})


export const {openSideBar,closeSideBar,refactSidebarList} = sidebarSlice.actions

export default sidebarSlice.reducer
