import React, {useState} from 'react';
import {useSelector} from "react-redux";
import Modal from "../../UI/Modal/Modal";
import EventsSlider from "../EventsSlider/EventsSlider";
import Event from "../Event/Event";
const EventsList = (props) => {
    const [showModal,setShowModal] = useState(false)
    const [id,setId] = useState()
    const [ss,setSs] = useState()
    const currentPage = useSelector(state => state.events.currentPage)

    return (
        <>
            {props.events[currentPage].map((elem, id) =>
                <Event setId={setId} setSs={setSs} setShowModal={setShowModal} elemDt={elem.dt} elemPath={elem.path} elemId={elem.id} elemSs={elem.ss} key={id} />

            )}
            {showModal === true
                &&
                <Modal setShowModal={setShowModal}>
                    <EventsSlider setId={setId} id={id} ss={ss} setShowModal={setShowModal}/>
                </Modal>
            }
        </>
    );
};

export default EventsList;
