import React, {useState} from 'react';
import s from "../VisitsHistoryMonthTable.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import MonthTableGenderPopup from "../MonthTableGenderPopup/MonthTableGenderPopup";
import getMonthName from "../../../../utils/getMonthName";
import {useDispatch, useSelector} from "react-redux";
import {
    sortVisitsHistoryDay,
    sortVisitsHistoryGender,
    sortVisitsHistoryMonth,
    sortVisitsHistorySum
} from "../../../../Redux/Slices/visitsHistoryReportSlice";
import {useTranslation} from "react-i18next/hooks";

const MonthTableHead = ({}) => {
    const visitsHistory = useSelector(state => state.visitsHistoryReport.visitsHistorySorted)
    const sort = useSelector(state => state.visitsHistoryReport.sort)
    const [genderPopup, setGenderPopup] = useState(false)
    const minVisitsNum = useSelector(state => state.visitsHistoryReport.minVisitsNum)
    const dispatch = useDispatch()
    const [t,i18n] = useTranslation()
    const setSortVisitsHistory = () => {
        dispatch(sortVisitsHistorySum())
    }
    const setSortVisitsHistoryMonth = (date) => {
        dispatch(sortVisitsHistoryMonth({date:date}))
    }
    const setSortVisitsHistoryDays = (day) => {
        dispatch(sortVisitsHistoryDay(day))
    }
    const setSortVisitsHistoryGender = (gender) => {
        dispatch(sortVisitsHistoryGender({gender:gender,minVisitsNum:minVisitsNum}))
    }
    return (
        <thead>
            {visitsHistory.length > 0 && visitsHistory[0]?.history[0]?.year
                &&
                <tr className={s.table__row}>
                    <th className={s.table__cellTitle}>
                    </th>
                    <th className={s.table__cellTitle}>
                    </th>
                    <th className={s.table__cellTitle}>
                    </th>
                    <th className={s.table__cellTitle}>
                    </th>
                    <th className={s.table__cellTitle}>
                    </th>
                    {
                        visitsHistory[0].history.map((elem,id) =>
                            <th key={id} className={s.table__cellTitle}>
                                {elem.year}
                            </th>
                        )

                    }
                </tr>
            }
            {visitsHistory.length > 0
                &&
                <tr className={s.table__row}>
                    <th className={s.table__cellTitle}>
                        {t('personReport.monthTable.photo')}
                    </th>
                    <th className={s.table__cellTitle}>
                        {t('personReport.monthTable.clientID')}
                    </th>
                    <th className={s.table__cellTitle}
                        onClick={() => setGenderPopup(!genderPopup)}>
                        {t('personReport.monthTable.gender')}
                        <FontAwesomeIcon icon={faArrowDown} style/>
                        {
                            genderPopup
                            &&
                            <MonthTableGenderPopup setSortVisitsHistoryGender={setSortVisitsHistoryGender}/>
                        }

                    </th>
                    <th className={s.table__cellTitle}>
                        {t('personReport.monthTable.age')}
                    </th>
                    <th className={sort === 'sum' ? s.table__cellSortered : s.table__cellTitle} onClick={() => setSortVisitsHistory('sum')}>
                        {t('personReport.monthTable.sum')}
                    </th>
                    {
                        visitsHistory.length > 0 && visitsHistory[0]?.history.map((elem,id) =>
                            <th key={id}
                                className={sort === (elem.date) ? s.table__cellSortered : s.table__cellTitle}
                                onClick={() => {
                                    elem.month
                                        ?
                                        setSortVisitsHistoryMonth(elem.date)
                                        :
                                        setSortVisitsHistoryDays(elem.date)
                                }}>
                                {elem.month
                                    ?
                                    getMonthName(elem.month)
                                    :
                                    elem.date
                                }
                            </th>
                        )
                    }
                </tr>
            }
        </thead>
    );
};

export default MonthTableHead;