import React from 'react';
import s from './VisitsHistoryToolbar.module.scss'
import DateRangeCalend from "../../ViewSettings/DateRangeCalend/DateRangeCalend";
import {useDispatch, useSelector} from "react-redux";
import LocationsSelect from "../../UI/LocationsSelect/LocationsSelect";
import {changeVisitsHistoryReportDate, setCurrentLocation} from "../../../Redux/Slices/visitsHistoryReportSlice";
import {useGetLocationsQuery} from "../../../Redux/APIs/testAPI";
import genderChartRefactor from "../../../utils/GenderChartRefactor";
const VisitsHistoryToolbar = () => {
    const dispatch = useDispatch()
    const locations = useSelector(state => state.location.locations)
    const currentLocation = useSelector(state => state.visitsHistoryReport.currentLocation)
    const chDate = (firstDate, secondDate) => {
        dispatch(changeVisitsHistoryReportDate({datStart: firstDate, datEnd:secondDate}))
    }
    const changeLocation = (location) => {
        dispatch(setCurrentLocation(location))
    }

    return (
        <div className={s.toolbar}>
            <LocationsSelect currentLocation={currentLocation === '' ? locations[0] : currentLocation} changeLocation={changeLocation}/>
            <DateRangeCalend chDate={chDate}/>
        </div>
    );
};

export default VisitsHistoryToolbar;
