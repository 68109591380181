import React from 'react';
import s from './HistoryNewPersonAdded.module.scss'
import GreenButton from "../../../UI/GreenButton/GreenButton";
import BlueButton from "../../../UI/BlueButton/BlueButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {faCircleUser} from "@fortawesome/free-regular-svg-icons";
import {faUserGroup} from "@fortawesome/free-solid-svg-icons";

const HistoryNewPersonAdded = ({newPersonData,setShowModal}) => {
    const navigate = useNavigate()
    return (
        <div className={s.newPesonAdded}>
            <div className={s.newPesonAdded__title}>
                Персона добавлена
            </div>
            <div>
                Персона <span style={{color:'#109CF1'}}>{newPersonData.name}</span> была добавлена в группу <span style={{color:'#109CF1'}}>{newPersonData.group}</span>
            </div>
            <div className={s.newPesonAdded__icon}>
                <FontAwesomeIcon icon={faUserGroup} size={'5x'}/>
            </div>
            <div className={s.newPesonAdded__buttons}>
                <BlueButton onClick={() => navigate(`persons/${newPersonData.groupId}`)}>Перейти к группе</BlueButton>
                <BlueButton onClick={() => setShowModal(false)}>Закрыть</BlueButton>
            </div>

        </div>
    );
};

export default HistoryNewPersonAdded;
