import {mainApi} from "../testAPI";
import axios from "axios";
import getUniqPersonPhotos from "../../funcs/getUniqPersonPhotos";

export const onePersonApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getOnePerson: builder.query({
            queryFn: async ({id}) => {
                try {
                    const res = await axios.get(`/api/person/${id}`,{
                        headers:{
                            "Authorization": localStorage.getItem('token')
                        }
                    })
                    const data = await res.data.data.person
                    return {data:data}
                }catch (e){
                    return {error:e.response.status}
                }
            },
            providesTags: () => [{type:'onePerson'}],
        }),
        getOnePersonPhotos: builder.query({
            queryFn: async ({id}) => {
                try {
                    const res = await axios.get(`/api/person_photo/${id}`,{
                        headers:{
                            "Authorization": localStorage.getItem('token')
                        }
                    })
                    const data = await res.data.data.photos
                    return {data:getUniqPersonPhotos(data)}
                }catch (e){
                    return {error:e.response.status}
                }
            },
            providesTags: () => [{type:'onePersonPhotos'}],
        }),
        postPersonPhotos: builder.mutation({
            queryFn:async ({photos,id}) => {
                for (let key in photos){
                    if(photos[key]){
                        try {
                            console.log(key, photos[key])
                            const res = await axios.post(`/api/person_photo`,
                                {
                                    id:id,
                                    type:+key,
                                    is_primary: +key === 1 ? true : false,
                                    b64file:photos[key]
                                },
                                {
                                    headers: {
                                        "Authorization": localStorage.getItem('token'),
                                        "Content-Type": 'application/json'
                                    }

                                })
                            console.log(res)
                        } catch (e) {
                            return {error:e.response.status}
                        }
                    }
                }
                return 1
            },
            invalidatesTags: () => [{type: 'onePersonPhotos'}]
        })
    }),
})

export const { useGetOnePersonQuery,useGetOnePersonPhotosQuery,usePostPersonPhotosMutation} = onePersonApi
