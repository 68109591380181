import React, {useEffect, useRef} from 'react';
import EventSlide from "../../EventSlide/EventSlide";
import Loader from "../../../UI/Loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import {autoMoveSlider, stopSlider} from "../../../../Redux/Slices/eventsSlidesSlice";

const EventsSliderSlider = ({full}) => {
    const sliderPos = useSelector(state => state.eventsSlides.sliderPos)
    const eventSlides = useSelector(state => state.eventsSlides.eventSlides)
    const isLoading = useSelector(state => state.eventsSlides.isLoading)
    const canShow = useSelector(state => state.eventsSlides.canShow)
    const dispatch = useDispatch()
    const isSliderPlay = useSelector(state => state.eventsSlides.isSliderPlay)
    const stopSl = () => {
        dispatch(stopSlider())
    }
    useEffect(() => {
        if(isSliderPlay === true && canShow){
            var slideshow = setInterval(() =>{
                dispatch(autoMoveSlider({maxValue: eventSlides.length}))

            },500)
        } else if(isSliderPlay === false){
            return () => clearInterval(slideshow)
        }
        return () => clearInterval(slideshow)
    },[eventSlides, isSliderPlay,canShow])

    const imageRef = useRef()
    return (
        <>
            {isLoading === false
                ?
                    <EventSlide refTo={imageRef} sliderPos={sliderPos} stopSl={stopSl} />
                :
                    <Loader/>
            }
        </>
    );
};

export default EventsSliderSlider;
