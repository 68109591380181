import React, {useState} from 'react';
import s from "../../CameraPlayer/CameraPlayer.module.css";
import CamControl from "../CamControl";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CamCotntrolBox = ({fullscreen,frames,secondFrames,fetchSlides,dispatchDate,saveEventss,revertEventss,width,hei}) => {
    const [cursorOnControl,setCursorOnControl] = useState(true)
    return (
        <div className={fullscreen === true ? s.cameraplayer__controlsFS : s.cameraplayer__controls}
             style={{bottom:`${cursorOnControl ? -10 : -135}px`}}
             onMouseOver={() => fullscreen && setCursorOnControl(true)}
             onMouseOut={() => fullscreen && setCursorOnControl(false)}
        >
            <div className={s.cameraplayer__FSRound}>
                <FontAwesomeIcon icon={faArrowUp} color={'#BFBFBF'}/>
            </div>
            <CamControl
                        frames={frames}
                        secondFrames={secondFrames}
                        fetchSlides={fetchSlides}
                        dispatchDate={dispatchDate}
                        saveEventss={saveEventss}
                        revertEventss={revertEventss}
                        width={width}
                        hei={hei}
                        fullscreen={fullscreen}
            />
        </div>
    );
};

export default CamCotntrolBox;