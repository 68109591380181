import React, {useRef, useState} from 'react';
import s from './CamsModal.module.css'
import ModalBackCross from "../../../components/UI/Modal/ModalBackCross";
import {useTranslation} from "react-i18next/hooks";


const CamsModal = (props) => {

    const setCurrentCameraType = (type) => {
        props.setCameraType(type)
        props.setShowTypeModal(2)
    }
    const [t,i18n] = useTranslation()
    return (
        <div className={s.camsmodalbox}>
            <div className={s.camsmodal}>
                <div className={s.camsmodal__title}>
                    {t('camerasPage.addCameraModal.selectDevice')}
                </div>

                <div className={s.camsmodal__list} onClick={() => setCurrentCameraType('id')}  >
                    <div className={s.camsmodal__elem}>
                        <div className={s.camsmodal__img}>

                        </div>
                        <div className={s.camsmodal__info}>
                            <div className={s.camsmodal__name}>
                                {t('default.camera')}
                            </div>
                            <div className={s.camsmodal__text}>
                                IP-камеры и веб-камеры
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.camsmodal__list} onClick={() => setCurrentCameraType('hikcentral')}  >
                    <div className={s.camsmodal__elem}>
                        <div className={s.camsmodal__img}>

                        </div>
                        <div className={s.camsmodal__info}>
                            <div className={s.camsmodal__name}>
                                {t('default.camera')} Hikcentral
                            </div>
                            <div className={s.camsmodal__text}>
                                Hikcentral {t('default.camera')}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default CamsModal;