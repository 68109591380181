import React, {useEffect, useState} from 'react';
import s from './LoginForm.module.css'
import {useDispatch, useSelector} from "react-redux";
import {fetchAuth} from "../../../Redux/Slices/authSlice";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";
import BlueButtonFull from "../../UI/BlueButtonFull/BlueButtonFull";
import MiniLoader from "../../UI/MiniLoader/MiniLoader";
import {Navigate, redirect, useNavigate} from "react-router-dom";
import {CAMS_ROUTE} from "../../../utils/pathss";
import {useTranslation} from "react-i18next/hooks";


const LoginForm = () => {
    const dispatch = useDispatch()
    const [showPass,setShowPass] = useState(false)
    const isPasswordWrong = useSelector(state => state.auth.isPasswordWrong)
    const isLoading = useSelector(state => state.auth.isLoading)
    const [t,i18n] = useTranslation()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        dispatch(fetchAuth({
            email:data.email,
            password:data.password,
        }))

    };

    return (
        <div className={s.loginFormBox}>
            <div className={s.loginForm__title}>
                {t('auth.signIn')}
            </div>
            <div className={s.loginForm__subtitle}>
                {t('auth.welcome')}
            </div>
            <form className={s.loginForm} onSubmit={handleSubmit(onSubmit)}>
                <div className={s.loginForm__Inputs}>
                    <input placeholder="E-mail" className={s.loginForm__input} name={'email'}  {...register("email",{
                        required:true,
                        pattern:/^\D*$/,
                        minLength:2
                    })}/>
                </div>
                <div className={s.loginForm__Inputs}>
                    <input placeholder='Password' type={showPass ? 'text' : 'password' } className={s.loginForm__input} name={'password'} {...register("password",{
                        required:true,
                        minLength:4
                    })}/>
                    <div className={s.loginForm__eye}  onClick={() => setShowPass(!showPass)}>
                        <FontAwesomeIcon icon={faEye} color={`${showPass ? '#08979C' : 'rgba(0,0,0,.2)'}`} width={15} />
                    </div>
                </div>
                {errors.email && errors.email.type === 'pattern' && (
                    <p style={{color:'red', fontSize:'12px'}}>
                        {t('auth.nameCaution')}
                    </p>
                )}
                {isPasswordWrong
                    &&
                    <p style={{color:'red', fontSize:'12px'}}>
                        {t('auth.error')}
                    </p>
                }
                <div className={s.loginForm__button}>
                    <BlueButtonFull padding={13} type={'submit'}> {isLoading ? <MiniLoader/> : t('auth.logIn')} </BlueButtonFull>
                </div>
            </form>
        </div>
    );
};

export default LoginForm;