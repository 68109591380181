import React, {useEffect, useRef, useState} from 'react';
import s from './EventsSlider.module.css'
import {useDispatch, useSelector} from "react-redux";
import {
    fetchEventsSlides,
    clearSlider,
    stopSlider
} from "../../../Redux/Slices/eventsSlidesSlice";
import fullScreenImg from '../../../public/Icons/fullScreen.svg'

import {logout} from "../../../Redux/Slices/authSlice";
import EventsSliderPreview from "./EventsSliderPreview/EventsSliderPreview";
import EventsSliderSlider from "./EventsSliderSlider/EventsSliderSlider";
import EventsSliderSlideTime from "./EventsSliderSlideTime/EventsSliderSlideTime";
import EventsSliderControls from "./EventsSliderControls/EventsSliderControls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";
import arraySlicer from "../../../utils/arraySlicer";

const EventsSlider = (props) => {
    const dispatch = useDispatch()
    const eventSlides = useSelector(state => state.eventsSlides.eventSlides)
    const errorCode = useSelector(state => state.eventsSlides.errorCode)
    const isLoading = useSelector(state => state.eventsSlides.isLoading)
    const [fullScreen, setFullScreen] = useState(false)
    const currentCamera = useSelector(state => state.events.currentCamera)
    const currentUser = useSelector(state => state.auth.currentUser)
    useEffect(()=>{
        let cam = props.camer || (currentCamera !== '' ? currentCamera : currentUser.cameras[0].id)
        dispatch(fetchEventsSlides({id:props.id, currentCamera:cam}))
        dispatch(clearSlider())
    },[props.id])
    useEffect(() => {
        const onKeypress = e => {
            if(e.code === 'Space'){
                dispatch(stopSlider())
            }
        };
        document.addEventListener('keypress', onKeypress);
        return () => {
            document.removeEventListener('keypress', onKeypress);
        };
    }, []);

    // релоад при ошибке
    if(errorCode === 401){
        dispatch(logout())
    }

    return (
        <div className={ fullScreen === true ? s.eventsSliderFull : s.eventsSlider}>
            <div className={s.eventsSlider__mobileClose} onClick={() =>
                props.setShowModal(false)
            }
            >
                <FontAwesomeIcon icon={faX} size={'xs'} color={'#BFBFBF'}/>
            </div>

            <div className={ fullScreen === true ? s.eventsSlider__windowFull : s.eventsSlider__window}>

                <EventsSliderSlider/>

                <EventsSliderPreview
                    eventsSlisedSlides={arraySlicer(eventSlides,20)}
                    fullScreen={fullScreen}
                    eventSlides={eventSlides}
                    isLoading={isLoading}
                />
                {fullScreen
                    &&
                    <div className={fullScreen ? s.eventsSlider__fullScreenButtonFull : s.eventsSlider__fullScreenButton} onClick={() => {
                        setFullScreen(!fullScreen)
                    }} >
                        <img src={fullScreenImg} alt="error"/>
                    </div>
                }
            </div>
            <EventsSliderSlideTime fullScreen={fullScreen} isLoading={isLoading} eventSlides={eventSlides}/>
            {props.setId
                &&
                <EventsSliderControls fullScreen={fullScreen} setFullScreen={setFullScreen} slidesLength={eventSlides.length} currentId={props.id} setId={props.setId}/>
            }

        </div>
    );
};

export default EventsSlider;
