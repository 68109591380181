import React from 'react';
import s from './FacesSearchNotFound.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
const FacesSearchNotFound = () => {
    return (
        <div className={s.banner}>
            <FontAwesomeIcon icon={faSearch}/>
            Прошлых поисков не найдено, вы можете начать новый
        </div>
    );
};

export default FacesSearchNotFound;