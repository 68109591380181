import React, {useState} from 'react';
import s from './VisitsHistoryTableMinVisits.module.scss'
import BlueButton from "../../UI/BlueButton/BlueButton";
import {useTranslation} from "react-i18next/hooks";
import {sortVisitsHistoryMinVisits} from "../../../Redux/Slices/visitsHistoryReportSlice";
import {useDispatch} from "react-redux";
const VisitsHistoryTableMinVisits = ({setMinVisits}) => {
    const [value,setValue] = useState(0)
    const [t,i18n] = useTranslation()
    const dispatch = useDispatch()
    const setSortVisitsHistoryMinVisits = (num) => {
        dispatch(sortVisitsHistoryMinVisits({minVisits:num}))
    }
    return (
        <div className={s.minVisits}>
            <span className={s.minVisits__span}>
                {t('personReport.filter')}
            </span>
            <input min={0} className={s.minVisits__input} onChange={(e) => setValue(e.target.value)} value={value} type="number"/>
            <button className={s.minVisits__button} onClick={() => setSortVisitsHistoryMinVisits(value)}>
                {t('default.apply')}
            </button>
        </div>
    );
};

export default VisitsHistoryTableMinVisits;
