import React from 'react';
import s from './MainFirstPlace.module.scss'
import diag from '../../../public/Icons/Group 15.svg'
import MainContentContainer from "../MainContent/MainContentContainer";
import frame from '../../../public/Icons/Rectangle 6 (Stroke).svg'
import BlueButtonFull from "../../UI/BlueButtonFull/BlueButtonFull";
import BlueButton from "../../UI/BlueButton/BlueButton";
import {NavLink} from "react-router-dom";
import {LOGIN_ROUTE} from "../../../utils/pathss";
import logo from '../../../public/Icons/VectorLogo.svg'
import {useTranslation} from "react-i18next/hooks";
const MainFirstPlace = () => {

    const [t,i18n] = useTranslation()

    return (
        <MainContentContainer>
            <div className={s.firstplace}>
                <div className={s.firstplace__rannesme}>
                    <div className={s.firstplace__title}>
                        <img src={logo} alt=""/>
                        <span>
                            Insight
                        </span>

                    </div>
                    <div className={s.firstplace__text}>
                        {t('main.firstPlace.subtitle')}
                    </div>
                    <div className={s.firstplace__button}>
                        <BlueButton>
                            {t('main.firstPlace.calcCost')}
                        </BlueButton>
                    </div>
                </div>


                <div className={s.firstplace__chartbox}>
                    <div className={s.firstplace__framebox}>
                        <div className={s.firstplace__frame}>
                            <img src={frame} alt=""/>
                            <div>
                                <div className={s.firstplace__male}>
                                    {t('main.firstPlace.female')}
                                </div>
                                <div className={s.firstplace__age}>
                                    {t('main.firstPlace.age')}: 25-30
                                </div>
                                <div className={s.firstplace__emotion}>
                                    {t('main.firstPlace.emotion')}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={s.firstplace__chart}>
                        <div className={s.firstplace__chartInfo}>
                            <div className={s.firstplace__poset}>
                                {t('main.firstPlace.visitors')}
                            </div>
                            <div className={s.firstplace__percent}>
                                +40%
                            </div>
                            <div className={s.firstplace__dateRange}>
                                15.03 - 06.04
                            </div>
                        </div>
                        <img src={diag} alt=""/>
                    </div>
                </div>
                <div className={s.firstplace__mobileButton}>
                    <BlueButtonFull>Рассчитать стоимость</BlueButtonFull>

                    <BlueButton>
                        <NavLink to={LOGIN_ROUTE} className={s.firstplace__buttonNavLink}>
                            Войти
                        </NavLink>
                    </BlueButton>

                </div>
            </div>
        </MainContentContainer>
    );
};

export default MainFirstPlace;
