import React, {useState} from 'react';
import s from '../../CamStepsModal/camStep.module.css'
import GreenButton from "../../../../UI/GreenButton/GreenButton";
import {useTranslation} from "react-i18next/hooks";
const HikcentralStepView = (props) => {
    const [selectedType, setSelectedType] = useState(1)
    const goToNextStep = () => {
        if( !props.last){
            props.setStep(props.step+1)
            props.setView(selectedType)
        }

    }
    const [t,i18n] = useTranslation()
    return (
        <div>
            <div className={s.camstepWrapper}>
                <div className={s.camstep__boldText}>
                    {props.text}
                </div>
                <div className={s.camstep__lightText}>
                    {props.subtext}
                </div>
                <div className={s.hikStep__buttons}>
                    <button onClick={() => setSelectedType(1)} className={selectedType === 1 ? s.hikStep__buttonSelect : s.hikStep__button}>{t('camerasPage.addCameraModal.in')}</button>
                    <button onClick={() => setSelectedType(2)} className={selectedType === 2 ? s.hikStep__buttonSelect : s.hikStep__button}>{t('camerasPage.addCameraModal.out')}</button>
                    <button onClick={() => setSelectedType(3)} className={selectedType === 3 ? s.hikStep__buttonSelect : s.hikStep__button}>{t('camerasPage.addCameraModal.traffic')}</button>
                    <button onClick={() => setSelectedType(4)} className={selectedType === 4 ? s.hikStep__buttonSelect : s.hikStep__button}>{t('camerasPage.addCameraModal.inOut')}</button>

                </div>
                <div>
                    <GreenButton onClick={() => goToNextStep()} >
                        {t('default.continue')}
                    </GreenButton>
                </div>

            </div>
        </div>
    );
};

export default HikcentralStepView;