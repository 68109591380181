import React from 'react';
import s from "../EventsSlider.module.css";
import {useSelector} from "react-redux";

const EventsSliderSlideTime = ({isLoading, eventSlides,fullScreen}) => {
    const sliderPos = useSelector(state => state.eventsSlides.sliderPos)
    const getDate = (date) => {
        const d = new Date(date)
        const normDate = `${d.getHours()}:${d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()}:${d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()}:${d.getMilliseconds()}`
        return normDate
    }
    return (
        <div className={s.eventsSlider__time}>
            {isLoading === false
                &&
                eventSlides.length > 0
                &&
                <div className={fullScreen ? s.eventsSlider__timeFull : s.eventsSlider__time}>{getDate(eventSlides[sliderPos]?.dt)}</div>
            }
        </div>
    );
};

export default EventsSliderSlideTime;
