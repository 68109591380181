import React from 'react';
import s from "../../../pages/Events/Events.module.css";
import PaginationControl from "../../UI/PaginationControl/PaginationControl";
import EventsList from "../EventsList/EventsList";
import Loader from "../../UI/Loader/Loader";
import EventsNotFound from "../EventsNotFound/EventsNotFound";
import {useDispatch, useSelector} from "react-redux";
import {backPagination, goPagination, setPagination} from "../../../Redux/Slices/eventsSlice";
import {useTranslation} from "react-i18next/hooks";

const EventsContent = () => {

    const events = useSelector(state => state.events.eventsPagination)
    const eventsIsLoading = useSelector(state => state.events.isLoading)
    const currentPage = useSelector(state => state.events.currentPage)
    const dispatch = useDispatch()
    const currentCameraName = useSelector(state => state.events.currentCameraName)
    const changePage = (opt) => {
        if(opt === 'go'){
            dispatch(goPagination())
        }else if (opt === 'back'){
            dispatch(backPagination())
        }else if (typeof opt === "number"){
            dispatch(setPagination(opt))
        }
    }
    const [t,i18n] = useTranslation()
    return (
        <>
            {eventsIsLoading === true
                ?
                <Loader/>
                :
                events.length > 0
                    ?
                    <>
                        <div className={s.events__control}>
                            <div><span style={{fontWeight:'bold',fontSize:'18px'}}>
                                {`${t('default.camera')} - ${currentCameraName}`}
                            </span></div>
                            <PaginationControl list={events} changePage={changePage} currentPage={currentPage} />
                        </div>
                        <div className={s.events__list}>
                            <EventsList events={events} />
                        </div>
                    </>
                    :
                    <EventsNotFound/>
            }
        </>
    );
};

export default EventsContent;
