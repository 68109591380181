import React from 'react';
import s from './MainContent.module.scss'
import MainFirstPlace from "../MainFirstPlace/MainFirstPlace";
import MainContentContainer from "./MainContentContainer";
import MainHeader from "../MainHeader/MainHeader";
import MainOpportunities from "../MainOpportunities/MainOpportunities";
import MainAudience from "../MainAudience/MainAudience";
import MainHowWork from "../MainHowWork/MainHowWork";
import MainRates from "../MainRates/MainRates";
import MainFooter from "../MainFooter/MainFooter";
import logo from '../../../public/Icons/VectorLogo.svg'
import MainBackground from "../MainBackground/MainBackground";
const MainContent = () => {
    return (
        <div className={s.maincontent}>
            <MainBackground/>
            <div>
                <MainHeader/>
                <MainFirstPlace/>
                <MainOpportunities/>
                <MainAudience/>
                <MainHowWork/>
                <MainRates/>
                <MainFooter/>
            </div>


        </div>
    );
};

export default MainContent;
