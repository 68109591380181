import React from 'react';
import cam from "../../../../public/Icons/Vector.svg";
import s from "../../../Cams/CamsNotFound/CamsNotFound.module.css";
import {faListUl} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import {useTranslation} from "react-i18next/hooks";

const HistoryNotFound = () => {
    const historyDate = useSelector(state => state.facesHistory.historyDate)
    const currentCamera = useSelector(state => state.facesHistory.currentCamera)
    const camsList = useSelector(state => state.auth.currentUser.cameras)
    const getCameraName = () => {
        const camera = camsList?.find(elem => elem.id === currentCamera)
        return camera?.name || camsList[0]?.name
    }
    const [t,i18n] = useTranslation()
    return (
        <div className={s.camsnotfound}>
            <div>
                <FontAwesomeIcon icon={faListUl} size={'4x'} color={'#BFBFBF'}/>
            </div>
            <div className={s.camsnotfound__text}>

                {t('history.historyNotFound')}
            </div>
        </div>
    );
};

export default HistoryNotFound;