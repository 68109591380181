import React, {useEffect, useMemo, useState} from 'react';
import s from './Cams.module.css'
import Toolbar from "../../components/toolbar/Toolbar";
import {useDispatch, useSelector} from "react-redux";
import CamsToolbar from "../../components/Cams/CamsToolbar/CamsToolbar";
import CameraElem from "../../components/Cams/CameraElem/CameraElem";
import CamsNotFound from "../../components/Cams/CamsNotFound/CamsNotFound";
import Loader from "../../components/UI/Loader/Loader";
import {useGetCamerasQuery} from "../../Redux/APIs/otherAPI/camerasApi";
import {redirect} from "react-router-dom";


const Cams = () => {
    const sortedCams = useSelector(state => state.camera.sortedCams)
    const {isLoading,isError,error} = useGetCamerasQuery()
    return (
        <div className={s.camsbox}>
            <Toolbar title={'Камера'}>
                <CamsToolbar/>
            </Toolbar>
            <div className={s.camerasbox}>
                <div className={s.cameras}>
                    {isLoading === true
                        ?
                        <Loader/>
                        :
                        isError === false
                            ?
                            sortedCams.length > 0
                                ?
                                sortedCams.map((elem,id) =>
                                    <CameraElem key={id} title={elem.name} status={elem.status} id={elem.id}/>
                                )
                                :
                                <CamsNotFound/>

                            :
                            <div>
                                Произошла ошибка
                            </div>

                    }
                </div>
            </div>

        </div>
    );
};

export default Cams;