import getTimeAndDate from "./getTimeAndDate";
const getMinTime = (arr) => {
    let maxTime = 24
    for(let i in arr){
        let firstTime = getTimeAndDate('time', new Date(arr[i].first)).slice(0,2)
        firstTime = firstTime[1] === ':' ? firstTime[0] : firstTime
        maxTime = +firstTime < maxTime ? +firstTime : maxTime
    }
    return maxTime
}

const objectTimesToArrayOfObjects = (obj,attendance) => {
    const att = []

    for(let i in obj){
        const firstVisitDate = new Date(attendance[i].first)
        const lastVisitDate = new Date(attendance[i].last)
        const firstVisit = attendance[i].first ? new Date(attendance[i].first).getHours() : 0
        const lastVisit = attendance[i].last ? new Date(attendance[i].last).getHours() : 24
        const date = `${i.slice(0,4)}.${i.slice(4,6)}.${i.slice(6,8)}`
        const newTimes = []
        for(let j in obj[i]){
            newTimes.push({time:j,num:obj[i][j].num,first:firstVisit,last:lastVisit})
        }
        att.push({
            date:date,
            times:newTimes,
            first: attendance[i].first ? getTimeAndDate('time',firstVisitDate) : null,
            last: attendance[i].last ? getTimeAndDate('time',lastVisitDate) : null,
            minTime:getMinTime(attendance)
        })
    }
    return att
}
export default objectTimesToArrayOfObjects;
