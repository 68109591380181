import React from 'react';
import AdminRawdataContent from "../../components/AdminRawdata/AdminRawdataContent/AdminRawdataContent";

const AdminRawdata = () => {
    return (
        <>
            <AdminRawdataContent/>
        </>
    );
};

export default AdminRawdata;