import React, {useEffect} from 'react';
import s from "../../CamStepsModal/camStep.module.css";
import Loader from "../../../../UI/Loader/Loader";
import {useSelector} from "react-redux";
import GreenButton from "../../../../UI/GreenButton/GreenButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next/hooks";
import {usePostCameraMutation} from "../../../../../Redux/APIs/otherAPI/camerasApi";
const HikcentralStepLast = ({setShowModal,res}) => {
    const [t,i18n] = useTranslation()
    return (
        <>
            <div className={s.camstepWrapper}>
                <div className={s.lastStep}>
                    {!res.isLoading
                        ?
                        res.isError
                            ?
                            <>
                                <FontAwesomeIcon icon={faCircleExclamation} color={'#de4949'} size={'3x'}/>
                                <div className={s.camstep__boldText}>
                                    {t('camerasPage.addCameraModal.error')}
                                </div>
                                <div className={s.camstep__lightText}>
                                    {t('camerasPage.addCameraModal.errorDescr')}
                                </div>
                                <div className={s.lastStep__button}>
                                    <GreenButton onClick={() => setShowModal(false)} >
                                        {t('default.continue')}
                                    </GreenButton>
                                </div>

                            </>
                            :
                            <>
                                <FontAwesomeIcon icon={faCircleCheck} color={'#109CF1'} size={'3x'}/>
                                <div className={s.camstep__boldText}>
                                    {t('camerasPage.addCameraModal.success')}
                                </div>
                                <div className={s.camstep__lightText}>
                                    {t('camerasPage.addCameraModal.successDescr')}
                                </div>
                                <div className={s.lastStep__button}>
                                    <GreenButton onClick={() => setShowModal(false)} >
                                        {t('default.continue')}
                                    </GreenButton>
                                </div>

                            </>
                        :
                        <div className={s.lastStep__loading}>
                            <Loader/>
                            <div className={s.camstep__lightText}>
                                {t('camerasPage.addCameraModal.loading')}
                            </div>
                        </div>
                    }


                </div>
            </div>
        </>
    );
};

export default HikcentralStepLast;