import React, {useRef, useState} from 'react';
import s from "../HistoryFullCard/HistoryFullCard.module.scss";
import {PROXY} from "../../../../utils/pathss";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFaceSadTear, faFaceSmile} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next/hooks";

const HistoryFullCardUnprocessedFace = ({elem}) => {
    const [show,setShow] = useState(false)
    const imageRef = useRef()
    const [t,i18n] = useTranslation()
    return (
        <div className={s.fullcardCont}>
            <div className={s.fullcard}>
                <div className={s.fullcard__img}>
                    <img ref={imageRef} onLoad={() => setShow(true)} src={`${PROXY}api/${elem?.imgdata?.path}`} alt=""/>
                    {!show
                        &&
                        <div className={s.fullcard__imgBanner}>
                        </div>
                    }


                </div>
                <div className={s.fullcard__time}>
                    {getTimeAndDate('time', elem?.dt)}
                </div>
                <div className={s.fullcard__info}>
                    <div className={s.fullcard__nofaceBanner}>
                        <div className={s.fullcard__nofaceBannerImg}>
                            {elem.status === 'unrecognizable'
                                ?
                                <FontAwesomeIcon icon={faFaceSadTear}/>
                                :
                                <FontAwesomeIcon icon={faFaceSmile}/>
                            }
                        </div>
                        <div className={s.fullcard__nofaceBannerText}>
                            {elem.status === 'unrecognizable'
                                ?
                                t('history.historyFullCard.faceUnrecognizable')
                                :
                                t('history.historyFullCard.faceRecognizing')
                            }

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default HistoryFullCardUnprocessedFace;