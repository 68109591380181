import React, {useEffect, useRef} from 'react';
import s from './CameraPlayer.module.css'
import {useDispatch, useSelector} from "react-redux";
import {
    changeDate, fetchCamSecondEvents,
    revertEvents,
    saveEvents,
    setProgressReverted,
} from "../../../Redux/Slices/camEventsSlice";
import {
    clearSlider,
    fetchCamEventsSlides,
    setActiveSequence, setInitialCanShow, setSpeed,
    stopSlider
} from "../../../Redux/Slices/camEventsSlidesSlide";
import CamSlider from "../CamSlider/CamSlider";
import SlideshowLoader from "../SlideshowLoader/SlideshowLoader";
import CamSliderSettings from "../CamSliderSettings/CamSliderSettings";
import CamCotntrolBox from "../CamControl/CamControlBox/CamCotntrolBox";
import DateCalend from "../../ViewSettings/DateCalend/DateCalend";
import setTimeToZero from "../../../utils/timezoning";
import {useTranslation} from "react-i18next/hooks";



const CameraPlayer = (props) => {
    const dispatch = useDispatch()
    const frames = useSelector(state => state.camEvents.camEvents)
    const framesSlidesIsLoading = useSelector(state => state.camSlides.isLoading)
    const framesIsLoading = useSelector(state => state.camEvents.isLoading)
    const camSecondEvents = useSelector(state => state.camEvents.camSecondEvents)
    const reverted = useSelector(state => state.camEvents.reverted)
    const fullscreen = useSelector(state => state.camSlides.fullscreen)
    useEffect(() => {
        if(framesIsLoading === false && frames.length > 0){
            dispatch(clearSlider())
            dispatch(fetchCamEventsSlides({currentCamera:props.currentCamera,id:frames.slice(-1)[0].id}))
            dispatch(setActiveSequence(frames.slice(-1)[0].id))
        }
    }, [props.currentCamera,frames])

    // тут я получаю список слайдов по картинке, если что
    const fetchSlides = (id) => {
        dispatch(clearSlider())
        dispatch(fetchCamEventsSlides({currentCamera:props.currentCamera,id:id}))
        dispatch(setActiveSequence(id))
        dispatch(stopSlider(true))
        dispatch(setInitialCanShow(false))
    }
    const setSpeedd = async () => {
        await dispatch(stopSlider(false))
        await dispatch(setSpeed())
        await dispatch(stopSlider(true))
    }
    const prevSlide = (id) => {

    }
    const nextSlide = (id) => {
        if(frames.length > 0){
            if(id <= frames[frames.length-1].id){
                dispatch(fetchCamEventsSlides({currentCamera:props.currentCamera,id:id}))
            }else if(camSecondEvents.length > 0){
                if(id <= camSecondEvents[camSecondEvents.length-1].id)
                dispatch(fetchCamEventsSlides({currentCamera:props.currentCamera,id:id}))
            }else{
                props.fetchSlides()
            }
        }
    }
    const fetchNewDate = () => {
        dispatch(changeDate(setTimeToZero(Date())))
        dispatch(setProgressReverted([]))
    }

    const fetchDate = (datt) => {
        const date = new Date(datt)
        const day = reverted === false ? 1 : -1
        date.setDate(date.getDate() + day)
        dispatch(changeDate(datt))
        dispatch(fetchCamSecondEvents({currentCamera:props.currentCamera, neededDate:date.toISOString()}))
        dispatch(setProgressReverted([]))
    }

    const dispatchDate = (newValue) => {
        const dat = new Date(newValue)
        dat.setHours(0)
        const datt = dat.toISOString()
        dispatch(changeDate(datt))
    }
    const saveEventss = (events) => {
        dispatch(saveEvents(events))
    }
    const revertEventss = (frames) => {
        dispatch(revertEvents(frames))
    }
    const boxRef = useRef()
    const [t,i18n] = useTranslation()
    return (
        <div className={fullscreen === true ? s.cameraplayerFS : s.cameraplayer}  >
            <div className={fullscreen === true ? s.cameraplayer__titleFS : s.cameraplayer__title}>
                Камера {props.currentCameraName}
            </div>
            <div className={fullscreen === true ? s.cameraplayer__mainframeFS :s.cameraplayer__mainframe}>
                <div className={s.cameraplayer__mainframeContent}>
                        {frames.length === 0 && camSecondEvents.length === 0
                            ?
                            <div className={s.framesNotFound}>
                                {t('camerasPage.seqNotFound')}
                            </div>
                            :
                            <div className={fullscreen === true ? s.cameraplayer__mainframeContentFS : s.cameraplayer__mainframeContent} ref={boxRef}>
                                {framesSlidesIsLoading === true
                                    ?
                                    <SlideshowLoader/>
                                    :
                                    <CamSlider fullscreen={fullscreen} nextSlide={nextSlide} width={fullscreen === true ? boxRef.current?.offsetWidth : 700} />
                                }

                                {fullscreen === true
                                    &&
                                    <div className={s.mainframe__viewSettingsBox}>
                                        <DateCalend changeDate={fetchDate} date={props.neededDate} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
            </div>

            <CamCotntrolBox
                            frames={frames}
                            secondFrames={camSecondEvents}
                            fetchSlides={fetchSlides}
                            dispatchDate={dispatchDate}
                            saveEventss={saveEventss}
                            revertEventss={revertEventss}
                            width={fullscreen === true ? 1200 : 600}
                            hei={fullscreen === true ? 70 : 50}
                            fullscreen={fullscreen}
                            fetchDate={fetchDate}
                            neededDate={props.neededDate}
            />
            <CamSliderSettings prevSlide={prevSlide} nextSlide={nextSlide} fullscreen={fullscreen} setSpeedd={setSpeedd} fetchNewDate={fetchNewDate}/>
        </div>
    );
};

export default CameraPlayer;
