import React from 'react';
import s from './ChartNotFound.module.scss'
const ChartNotFound = () => {
    return (
        <div className={s.banner}>
            Нет данных для построения графика
        </div>
    );
};

export default ChartNotFound;