import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";


export const fetchCamEventsSlides = createAsyncThunk(
    'users/fetchEventsSlides',
    async function(state,{rejectWithValue}){
        try{
            const res = await axios.get(`/api/sequences/${state.id}`,{
                headers:{
                    "Authorization": localStorage.getItem('token')
                },
                params:{
                    cam_code: state.currentCamera,
                }
            })
            const data = await res.data
            return data.data
        }catch (err){
            return rejectWithValue(err.response.data.errors.code)
        }

    }
)


const initialState = {
    camSlides:[],
    sliderPos:0,
    stagePos:{},
    scrollDepth:1,
    controlSliderPos:0,
    sliderMode:'next',
    fullscreen:false,
    activeSequence:0,
    prevSequence:0,
    speed:1,
    loaded:1,
    canShow:false,
    canInitialShow:false,
    isSliderPlay:true,
    isLoading:false,
    isError:false,
    errorCode:'',
}

export const camEventsSlidesSlice = createSlice({
    name: 'eventsCamSlidesSlice',
    initialState,
    reducers: {
        autoMoveSlider: (state,action) => {
            if(action.payload.maxValue > state.sliderPos+1){
                state.sliderPos += 1
            }else{
                state.sliderPos = 0
            }
        },
        clearSlider: (state,action) => {
            state.sliderPos = 0
        },
        stopSlider: (state,action) => {
            state.isSliderPlay = action.payload
        },
        setActiveSequence: (state,action) => {
            state.prevSequence = state.activeSequence
            state.activeSequence = action.payload
        },
        setSpeed: (state,action) => {
            state.speed = state.speed >= 3  ? 1 : state.speed+1
        },
        setCanShow: (state, action) => {
            state.canShow = action.payload
        },
        setInitialCanShow: (state, action) => {
            state.canInitialShow = action.payload
        },
        setFullscreen: (state,action) => {
            console.log(action.payload.sliderPos)
            state.fullscreen = action.payload.value
            if(state.fullscreen){
                state.controlSliderPos = action.payload.sliderPos * 2
            }else if(!state.fullscreen){
                state.controlSliderPos = action.payload.sliderPos / 2
            }

        },
        setControlSliderPos: (state,action) => {
            state.controlSliderPos = action.payload
        },
        changeScrollDepth:(state,action) => {
            state.scrollDepth = action.payload
        },
        setSliderMode:(state,action) => {
            state.sliderMode = action.payload
        },
        setStagePos:(state,action) => {
            console.log(action.payload)
            state.stagePos = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCamEventsSlides.pending, (state, action) => {
            state.isLoading = true
            state.sliderPos = 0
            state.isSliderPlay = false
        })
        builder.addCase(fetchCamEventsSlides.fulfilled, (state, action) => {
            state.activeSequence = action.payload[0].id
            state.camSlides = action.payload
            state.canShow = false
            state.isError = false
            state.isLoading = false
            state.isSliderPlay = true

        })
        builder.addCase(fetchCamEventsSlides.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorCode = action.payload
        })
    },
})


export const {autoMoveSlider,setStagePos,setSliderMode,clearSlider,stopSlider,setActiveSequence,setSpeed,setFullscreen,setControlSliderPos,changeScrollDepth,setCanShow,setInitialCanShow} = camEventsSlidesSlice.actions

export default camEventsSlidesSlice.reducer