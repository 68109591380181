import {useTranslation} from "react-i18next/hooks";

const useMonthName = (month) => {
    const [t,i18n] = useTranslation()
    const months = {
        1:t('months.january'),
        2:t('months.february'),
        3:t('months.march'),
        4:t('months.april'),
        5:t('months.may'),
        6:t('months.june'),
        7:t('months.july'),
        8:t('months.august'),
        9:t('months.september'),
        10:t('months.october'),
        11:t('months.november'),
        12:t('months.december')
    }
    return months[month]
}
export default useMonthName;
