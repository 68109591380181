import React, {useRef, useState} from 'react';
import s from './HistoryFaceVisitsList.module.scss'
import {CSSTransition} from "react-transition-group";
import './anim.scss'
import {PROXY} from "../../../../utils/pathss";
import getTimeAndDate from "../../../../utils/getTimeAndDate";
import {useGetPersonAllVisitsQuery} from "../../../../Redux/APIs/otherAPI/personAllVisitsApi";
import arrayOb from "../../../../utils/arrayOb";
import Modal from "../../../UI/Modal/Modal";
const HistoryFaceVisitsList = ({showAllVisits,person}) => {
    const nodeRef = useRef(null);
    const [fullImg,setFullImg] = useState('')
    const [showFullImg,setShowFullImg] = useState(false)
    const {data:personAllVisits,isLoading} = useGetPersonAllVisitsQuery({person:person},{refetchOnMountOrArgChange:true})
    const openFullImg = (src) => {
        setFullImg(src)
        setShowFullImg(true)
    }

    return (
            <CSSTransition unmountOnExit nodeRef={nodeRef} in={showAllVisits} timeout={{exit:3000,enter:100}} classNames="my-node">
                <div ref={nodeRef}>
                    <div  className={s.list}>
                        {arrayOb(personAllVisits?.attendance)?.length > 0
                            &&
                            arrayOb(personAllVisits.attendance).map((elem,id) =>
                                <div className={s.list__elem}>
                                    <img onClick={() => openFullImg(`${PROXY}/api/${elem.first_url}`)} src={`${PROXY}/api/${elem.first_url}`} alt=""/>
                                    <div>
                                        <div className={s.list__time}>
                                            {getTimeAndDate('date',elem.first)}
                                        </div>
                                        <div className={s.list__date}>
                                            {getTimeAndDate('time',elem.first)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={s.list__time}>
                                            {elem.last && getTimeAndDate('date',elem.last)}
                                        </div>
                                        <div className={s.list__date}>
                                            {elem.last && getTimeAndDate('time',elem.last)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    {showFullImg
                        &&
                        <Modal setShowModal={setShowFullImg} showModal={showFullImg}>
                            <img onClick={() => setShowFullImg(false)} src={fullImg} alt=""/>
                        </Modal>
                    }
                </div>
            </CSSTransition>

    );
};

export default HistoryFaceVisitsList;
