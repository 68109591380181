import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeEventsDate, setCurrentCamera, setPagination} from "../../../Redux/Slices/eventsSlice";
import CameraSelect from "../../../components/UI/CameraSelect/CameraSelect";
import s from './EventsToolbar.module.css'
import DateCalend from "../../../components/ViewSettings/DateCalend/DateCalend";

const EventsToolbar = () => {
    const dispatch = useDispatch()
    const eventsDate = useSelector(state => state.events.eventsDate)
    const camsList = useSelector(state => state.auth.currentUser.cameras)
    const currentCamera = useSelector(state => state.events.currentCamera)
    const currentCameraName = useSelector(state => state.events.currentCameraName)


    const changeCamera = (camera) => {
        dispatch(setCurrentCamera(camera))
        localStorage.setItem('eventsCamera', camera)
        dispatch(setPagination(0))
    }
    const changeDate = (date) => {
        dispatch(changeEventsDate(date))

    }
    return (
        <div className={s.toolbar}>
            <CameraSelect currentCameraName={currentCameraName} list={camsList} changeCamera={changeCamera} currentCamera={currentCamera}/>
            <DateCalend timeSpan={false} changeDate={changeDate} date={eventsDate}/>
        </div>
    );
};

export default EventsToolbar;