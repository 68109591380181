import React, {useState} from 'react';
import s from './MainOpportunities.module.scss'
import MainContentContainer from "../MainContent/MainContentContainer";
import hermaphrodite from '../../../public/Icons/Group 98.svg'
import operator from '../../../public/Icons/Group 99.svg'
import dnk from '../../../public/Icons/Group 100.svg'
import night from '../../../public/Icons/Group 105.svg'
import slaveAndLift from '../../../public/Icons/Group 103.svg'
import thief from '../../../public/Icons/Group 101.svg'
import slave from '../../../public/Icons/Group 102.svg'
import chart from '../../../public/Icons/Group 104.svg'
import swipeHandler from "../../../utils/sliderSwipeHandler";
import {useTranslation} from "react-i18next/hooks";

const MainOpportunities = () => {
    const [touchStart,setTouchStart] = useState(0)
    const [sliderPos,setSliderPos] = useState(0)

    const [t,i18n] = useTranslation()
    return (
        <MainContentContainer>
            <div className={s.opport} id={'opportunitiesSection'}>
                <div className={s.opport__title}>
                    {t('main.opportunities.titleFirst')} <span className={s.opport__blueText}>{t('main.opportunities.titleSecond')}</span> ?
                </div>
                <div className={s.opport__text}>
                    {t('main.opportunities.subtitle')}
                </div>
                <div className={s.opport__cardsWindow}>
                    <div className={s.opport__cards}
                         onTouchStart={(e) => setTouchStart(e.touches[0].screenX)}
                         onTouchEnd={(e) => swipeHandler(e.changedTouches[0].screenX,touchStart,sliderPos,setSliderPos,7)}
                         style={{transform:`translateX(${sliderPos * -320}px)`}}
                    >
                        <div className={s.opport__card}>
                            <div className={s.opport__cardText}>
                                {t('main.opportunities.genderCard')}
                            </div>
                            <div className={s.opport__cardContent}>
                                <img src={hermaphrodite} alt=""/>
                            </div>
                        </div>
                        <div className={s.opport__card}>
                            <div className={s.opport__cardText}>
                                {t('main.opportunities.clientCard')}
                            </div>
                            <div className={s.opport__cardContent}>
                                <img src={operator} alt=""/>
                            </div>
                        </div>
                        <div className={s.opport__card}>
                            <div className={s.opport__cardText}>
                                {t('main.opportunities.employeeTimeCard')}
                            </div>
                            <div className={s.opport__cardContent}>
                                <img src={slave} alt=""/>
                            </div>
                        </div>
                        <div className={s.opport__card}>
                            <div className={s.opport__cardText}>
                                {t('main.opportunities.reportsCard')}
                            </div>
                            <div className={s.opport__cardContent}>
                                <img src={chart} alt=""/>
                            </div>
                        </div>
                        <div className={s.opport__card}>
                            <div className={s.opport__cardText}>
                                {t('main.opportunities.visitsCard')}
                            </div>
                            <div className={s.opport__cardContent}>
                                <img src={dnk} alt=""/>
                            </div>
                        </div>
                        <div className={s.opport__card}>
                            <div className={s.opport__cardText}>
                                {t('main.opportunities.strangerCard')}
                            </div>
                            <div className={s.opport__cardContent}>
                                <img src={night} alt=""/>
                            </div>
                        </div>
                        <div className={s.opport__card}>
                            <div className={s.opport__cardText}>
                                {t('main.opportunities.blackListCard')}
                            </div>
                            <div className={s.opport__cardContent}>
                                <img src={thief} alt=""/>
                            </div>
                        </div>
                        <div className={s.opport__card}>
                            <div className={s.opport__cardText}>
                                {t('main.opportunities.trafficCard')}
                            </div>
                            <div className={s.opport__cardContent}>
                                <img src={slaveAndLift} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </MainContentContainer>
    );
};

export default MainOpportunities;