import React from 'react';
import s from './CamsNotFound.module.css'
import cam from '../../../public/Icons/Vector.svg'
import {useTranslation} from "react-i18next/hooks";
const CamsNotFound = () => {
    const [t,i18n] = useTranslation()
    return (
        <div className={s.camsnotfound}>
            <div>
                <img src={cam} alt="" className={s.camsnotfound__img}/>
            </div>
            <div className={s.camsnotfound__text}>
                {t('camerasPage.camsNotFound')}
            </div>
        </div>
    );
};

export default CamsNotFound;